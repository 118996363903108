import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import { parseQueryString } from '@utils/apiUtils';
import { Button, Result } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface Search {
  next?: string;
}

export default function InfluencerRegisterSuccessPage() {
  const location = useLocation();
  const history = useHistory();

  const parse: Search = parseQueryString(location.search);

  function goToHomePage() {
    history.push('');
  }

  function goToJobHomePage() {
    if (parse.next) {
      history.push(parse.next);
    }
  }

  return (
    <LayoutPublic hideLogin>
      <CardContent>
        <Result
          status='success'
          title='ลงทะเบียนสำเร็จ'
          // subTitle={`เลขที่ใบสมัคร ${locationState.jobApplication.id}. สามารถตรวจสอบรายชื่อผู้ผ่านการคัดเลือกได้ที่หน้าประกาศ`}
          extra={[
            <Button type='primary' key='home' onClick={goToHomePage}>
              กลับสู่หน้าหลัก
            </Button>,
            parse.next && (
              <Button key='job' onClick={goToJobHomePage}>
                ไปหน้างานรีวิว
              </Button>
            ),
          ]}
        />
      </CardContent>
    </LayoutPublic>
  );
}
