import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import MissingParams from '@components/validate/MissingParams';
import ValidateParams from '@components/validate/ValidateParams';
import { LOCAL_STORAGE_KEY } from '@constants/constants';
import { JOB_OFFER_STAGE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import { LocationStateJobEmployerWaitingPage } from '@pages/job/[id]/employer/waiting';
import { jobOfferAccessLinkAPI } from '@services/apis';
import { parseQueryString } from '@utils/apiUtils';
import { isUUID } from '@utils/dataUtils';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

export interface Search {
  token?: string;
}
interface State {
  loading: boolean;
  invalidToken: boolean;
}
export default function JobEmployerVerifyToken() {
  const { id }: { id: string } = useParams();
  const parse: Search = parseQueryString(useLocation().search);
  const history = useHistory();

  const [state, setState] = useState<State>({
    loading: false,
    invalidToken: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));
  const markInvalidToken = () =>
    setState((prev) => ({ ...prev, invalidToken: true }));

  function goToNextPage(jobStage: string) {
    let targetPath = '';
    switch (jobStage) {
      case JOB_OFFER_STAGE.NEW:
        targetPath = `${generatePath(ROUTE.JOB_EMPLOYER_WAITING, {
          id,
        })}`;
        break;
      case JOB_OFFER_STAGE.HAS_CANDIDATE:
        targetPath = `${generatePath(ROUTE.JOB_EMPLOYER_SELECT_REVIEWER, {
          id,
        })}`;
        break;
      case JOB_OFFER_STAGE.HAS_REVIEWER:
      case JOB_OFFER_STAGE.IN_REVIEW:
        targetPath = `${generatePath(ROUTE.JOB_EMPLOYER_REVIEW_UPLOAD, {
          id,
        })}`;
        break;
      default:
        targetPath = `${generatePath(ROUTE.JOB_EMPLOYER_REVIEW_UPLOAD, {
          id,
        })}`;
    }

    const targetState: LocationStateJobEmployerWaitingPage = {
      step: jobStage,
    };

    history.push({
      pathname: targetPath,
      state: targetState,
    });
  }

  async function fetchValidateToken(token: string) {
    try {
      showLoading();
      const res = await jobOfferAccessLinkAPI.validateToken(token);
      if (res.valid) {
        localStorage.setItem(LOCAL_STORAGE_KEY.EMPLOYER_TOKEN, token);
        goToNextPage(res.jobStage);
      } else {
        markInvalidToken();
      }
    } catch (error) {
      markInvalidToken();
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    if (parse.token && isUUID(parse.token)) {
      fetchValidateToken(parse.token);
    }
  }, []);

  return (
    <LayoutPublic>
      <Row justify='center'>
        <Col xs={24} md={18} lg={14} xl={12}>
          <CardContent>
            <Row justify='center'>
              <ValidateParams
                params={[
                  { name: 'token', value: parse.token, validateFn: isUUID },
                ]}
              >
                {state.loading ? (
                  <Space direction='vertical' align='center'>
                    <h1>กำลังตรวจสอบ</h1>
                    <Loading loading />
                  </Space>
                ) : (
                  <MissingParams
                    params={['token']}
                    title='ไม่พบ Token'
                    subTitle='ขออภัย, ไม่พบ Token หรือหมดอายุแล้ว.'
                  />
                )}
              </ValidateParams>
            </Row>
          </CardContent>
        </Col>
      </Row>
    </LayoutPublic>
  );
}
