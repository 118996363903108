/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';
interface LayoutBackofficeContextValues {
  isCollapse: boolean;
  setIsCollapse: (value: boolean) => void;
}

const initialState: LayoutBackofficeContextValues = {
  isCollapse: false,
  setIsCollapse: () => {},
};

export const LayoutBackofficeContext =
  createContext<LayoutBackofficeContextValues>(initialState);

export function useLayoutBackofficeContext() {
  return useContext(LayoutBackofficeContext);
}

export function LayoutBackofficeProvider({ children }: any) {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  return (
    <LayoutBackofficeContext.Provider
      value={{
        isCollapse,
        setIsCollapse,
      }}
    >
      {children}
    </LayoutBackofficeContext.Provider>
  );
}
