import { State } from '@pages/backoffice/manages/jobs/components/AddJobDrawer';
import { dataUtils } from '@utils/index';
import { Modal } from 'antd';
import React from 'react';

type Props = {
  actionState: State;
  hideModalDescriptionPreview: () => void;
};
export default function PreviewDescriptionModal(props: Props) {
  const { actionState, hideModalDescriptionPreview } = props;

  return (
    <Modal
      title='ตัวอย่างรายละเอียด'
      open={actionState.openModalDescriptionPreview}
      width='100%'
      footer={null}
      onCancel={hideModalDescriptionPreview}
    >
      {actionState.previewHTML && (
        <div
          dangerouslySetInnerHTML={dataUtils.createMarkup(
            actionState.previewHTML ?? '</>'
          )}
        />
      )}
    </Modal>
  );
}
