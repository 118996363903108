import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  UserCheckExists,
  UserCreate,
  UserGetCompCardPublicUrl,
  UserInfluencerDetail,
  UserInfluencerUpdateAddress,
  UserLogin,
  UserUploadComCard,
} from '@services/models/user';
import axios from 'axios';
import { generatePath } from 'react-router-dom';

export async function create(req: UserCreate.Request) {
  const res = await api.post<UserCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_BASE_URL}`,
    req
  );
  return res.data;
}

export async function checkExists(req: UserCheckExists.Request) {
  const res = await api.post<UserCheckExists.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_CHECK_EXISTS}`,
    req
  );
  return res.data;
}

export async function login(req: UserLogin.Request) {
  const res = await api.post<UserLogin.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_LOGIN}`,
    req
  );
  return res.data;
}

export async function uploadComCard(req: FormData) {
  const res = await api.post<UserUploadComCard.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_INFLUENCER_UPLOAD_COMP_CARD}`,
    req
  );
  return res.data;
}

export async function getComCardByUserId(id: number) {
  const res = await api.get<UserGetCompCardPublicUrl.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.USER_INFLUENCER_GET_COMP_CARD_BY_USER_ID,
      { id }
    )}`
  );
  return res.data;
}

export async function influencerUpdateAddress(
  req: UserInfluencerUpdateAddress.Request
) {
  const res = await axios.patch<UserInfluencerUpdateAddress.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_INFLUENCER_UPDATE_ADDRESS}`,
    req
  );
  return res.data;
}

export async function influencerDetailByUserId(id: number) {
  const res = await api.get<UserInfluencerDetail.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.USER_INFLUENCER_DETAIL_BY_USER_ID,
      { id }
    )}`
  );
  return res.data;
}
