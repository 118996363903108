import CardContent from '@components/card/CardContent';
import Loading from '@components/loading/Loading';
import { APIModeType } from '@components/section/SectionComment';
import { jobProgressApi } from '@services/apis';
import { JobProgressGet } from '@services/models/jobProgress';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Col, Image, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface State {
  loading: boolean;
  jobProgressGetResp?: JobProgressGet.Response;
}

type Props = {
  apiMode: APIModeType;
  id: number;
  jobId: number;
};
export default function SectionUploadHistory(props: Props) {
  const { apiMode, id, jobId } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  async function fetchUploadHistory() {
    try {
      showLoading();
      let resp: JobProgressGet.Response;
      switch (apiMode) {
        case 'admin':
          resp = await jobProgressApi.get(jobId, id, {});
          break;
        case 'employer':
          resp = await jobProgressApi.employerGet(jobId, id, {});
          break;
        case 'reviewer':
          resp = await jobProgressApi.reviewerGet(jobId, {});
          break;
      }

      setState((prev) => ({ ...prev, jobProgressGetResp: resp }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchUploadHistory();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      {state.loading && (
        <Col span={24}>
          <CardContent>
            <Loading loading />
          </CardContent>
        </Col>
      )}

      {state.jobProgressGetResp?.data.map((e) => (
        <Col xs={24} key={e.reviseCount}>
          <CardContent
            title={
              e.reviseCount === 0
                ? `รายการอัพโหลด`
                : `แก้ไขครั้งที่ ${e.reviseCount}`
            }
          >
            <Content.ImagePreviewGroup>
              <List
                grid={{
                  gutter: 16,
                  xs: 2,
                  sm: 3,
                  md: 4,
                  lg: 5,
                  xl: 6,
                  xxl: 8,
                }}
                dataSource={e.urls}
                renderItem={(url) => (
                  <Content.ListItem>
                    <Image
                      key={url}
                      src={url}
                      height='100%'
                      width='100%'
                      style={{ objectFit: 'cover', borderRadius: '16px' }}
                    />
                  </Content.ListItem>
                )}
              />
            </Content.ImagePreviewGroup>
          </CardContent>
        </Col>
      ))}
    </Row>
  );
}

const Content = {
  Wrapper: styled.div``,
  ImagePreviewGroup: styled(Image.PreviewGroup)`
    display: flex;
    flex-direction: row;
    gap: 16px;
  `,
  ListItem: styled(List.Item)`
    height: 140px;
    width: 140px;
  `,
  Image: styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  ImageContainer: styled.div`
    /* margin: 0px 12px 0px 0px; */
    display: inline;
    /* width: 140px; */
    height: 140px;
    object-fit: cover;
  `,
  ImageStyle: styled(Image)`
    object-fit: cover;
    width: 140px;
    height: 140px;
    border-radius: 4px;
  `,
};
