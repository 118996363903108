import DraggerInput from '@components/form/DraggerInput';
import { MASTER_DATA_CATEGORY } from '@constants/constants';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { masterApi, productApi } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  UploadFile,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as mime from 'mime-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface FormType {
  userId: string;
  file: UploadFile[];
  name: string;
  description: string;
  followAt: string;
  productTypeCode: string;
}

const formSchema = {
  userId: { name: 'userId', label: 'userId' },
  file: { name: 'file', label: 'รูปสินค้า' },
  name: { name: 'name', label: 'ชื่อสินค้า' },
  description: { name: 'description', label: 'รายละเอียด' },
  followAt: { name: 'followAt', label: 'ข้อมูลการรับบริการสินค้า' },
  productTypeCode: { name: 'productTypeCode', label: 'ประเภทสินค้า' },
};

type Props = {
  open: boolean;
  userId: number;
  onClose: () => void;
  onSuccess: (productId: number) => void;
};
export default function AddProductDrawer(props: Props) {
  const { open, userId, onClose, onSuccess } = props;

  const { isDesktop } = useResponsiveContext();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingProductType, setLoadingProductType] = useState(false);

  const [dropdownProductType, setDropdownProductType] = useState<LabelValue[]>(
    []
  );

  async function onFinish(values: FormType) {
    try {
      setLoading(true);

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (key !== formSchema.file.name && value !== undefined) {
          formData.append(key, value);
        }
      });

      formData.append(
        formSchema.file.name,
        values.file[0].originFileObj as File
      );

      const res = await productApi.create(formData);
      onClose();
      onSuccess(res.data.id);
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchProductTypes() {
    try {
      setLoadingProductType(true);
      const res = await masterApi.list({
        category: MASTER_DATA_CATEGORY.PRODUCT_TYPE,
        limit: {
          pageNumber: 1,
          pageSize: 999, // TODO implement select with loadMore()
        },
      });

      const data: LabelValue[] = res.data.map((e) => ({
        label: e.name,
        value: e.code,
      }));
      setDropdownProductType(data ?? []);
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoadingProductType(false);
      //
    }
  }

  useEffect(() => {
    if (open && dropdownProductType.length === 0) {
      fetchProductTypes();
    }
  }, [open]);

  useEffect(() => {
    form.setFieldValue(formSchema.userId.name, userId);
  }, [userId]);

  return (
    <Drawer
      title='แบบฟอร์มเพิ่มสินค้า'
      width={isDesktop ? '720px' : '100%'}
      onClose={onClose}
      open={open}
    >
      <Form
        scrollToFirstError
        layout='vertical'
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16} justify='space-between'>
          <Form.Item
            hidden
            {...formSchema.userId}
            rules={formRuleUtils.REQUIRE}
          >
            <Input disabled />
          </Form.Item>

          <Col span={24}>
            <Form.Item
              {...formSchema.file}
              valuePropName='fileList'
              rules={formRuleUtils.REQUIRE_UPLOAD}
            >
              <DraggerInput
                maxCount={1}
                acceptList={[mime.lookup('jpeg').toString()]}
                resizeMaxSizeMB={1}
                listType='picture'
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item {...formSchema.name} rules={formRuleUtils.REQUIRE}>
              <Input placeholder={formSchema.name.label} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              {...formSchema.productTypeCode}
              rules={formRuleUtils.REQUIRE}
            >
              <Select
                placeholder={formSchema.productTypeCode.label}
                loading={loadingProductType}
              >
                {dropdownProductType.map((e) => (
                  <Select.Option key={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item {...formSchema.followAt}>
              <Input placeholder={formSchema.followAt.label} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item {...formSchema.description}>
              <TextArea placeholder={formSchema.description.label} />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
            <Form.Item
              {...formSchema.companyName}
              rules={formRuleUtils.REQUIRE}
            >
              <Input placeholder={formSchema.companyName.label} />
            </Form.Item>
          </Col> */}
        </Row>

        <ButtonSheetStyle.Wrapper>
          <Button
            block
            type='primary'
            size='large'
            htmlType='submit'
            loading={loading}
          >
            ยืนยัน
          </Button>
        </ButtonSheetStyle.Wrapper>
      </Form>
    </Drawer>
  );
}

const ButtonSheetStyle = {
  Wrapper: styled.div`
    height: 70px;
    padding: 16px;

    position: fixed;
    bottom: 0;
    margin-left: -32px;
    width: -webkit-fill-available;
    border-radius: 24px 24px 0 0;

    button {
      border-radius: 24px;
    }
  `,
};
