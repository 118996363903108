import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { FrownOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { IMAGE } from '@constants/resource';
import { ROUTE } from '@constants/routes';
import { AuthContext } from '@contexts/AuthContext';
import { authApi } from '@services/apis';
import { formRuleUtils } from '@utils/index';
import { Button, Col, Form, Input, Layout, notification, Row } from 'antd';
import React from 'react';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

export default function LoginPage() {
  enum LoginForm {
    phoneNo = 'phoneNo',
    password = 'password',
  }

  const { setLogedInUser } = useContext(AuthContext);

  const { phoneNo }: { phoneNo: string } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const openFailedLoginNoti = () => {
    notification.open({
      message: 'เข้าสู่ระบบไม่สำเร็จ',
      description: 'ไม่พบผู้ใช้งานหรือผู้ใช้งานโดนระงับไว้',
      icon: <FrownOutlined style={{ color: 'red' }} />,
    });
  };

  async function onFinish() {
    try {
      setLoading(true);
      const response = await authApi.backoffceLogin({
        phoneNo: form.getFieldValue(LoginForm.phoneNo),
        password: form.getFieldValue(LoginForm.password),
      });
      if (response.status) {
        // TODO set accessToken
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('token', JSON.stringify(response.data.token));

        // Set auth user in context api
        setLogedInUser(response.data);

        history.push(ROUTE.BACKOFFICE_MANAGES_JOBS);
      }
    } catch (error) {
      openFailedLoginNoti();
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Row style={{ height: '100%' }}>
        <Col xs={24} md={12} lg={12} style={{ backgroundColor: '#fff' }}>
          <Row align='middle' justify='center' style={{ height: '100%' }}>
            <Col xs={18} sm={14} md={18} lg={16} xl={14} xxl={10}>
              <Row gutter={[12, 12]} align='middle'>
                <Col>
                  <img
                    src={IMAGE.LOGO_DOOQ}
                    alt='logo'
                    style={{
                      width: '108px',
                      backgroundColor: '#001529',
                      padding: '12px',
                      borderRadius: '8px',
                    }}
                  />
                </Col>
                <Col>
                  <h1 style={{ fontSize: '30px', margin: '0px' }}>Admin</h1>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={24}>
                  <Row justify='space-between' align='middle'>
                    <Col>
                      <TopicStyle>เข้าสู่ระบบ</TopicStyle>
                    </Col>
                    <Col>
                      <TextLinkStyle to={ROUTE.HOME}>
                        {'< กลับสู่หน้าแรก'}
                      </TextLinkStyle>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Form
                    name='login'
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name={LoginForm.phoneNo}
                      label='เบอร์โทรศัพท์'
                      initialValue={phoneNo}
                      rules={formRuleUtils.PHONE_NO}
                    >
                      <Input
                        placeholder='เบอร์โทรศัพท์'
                        maxLength={10}
                        prefix={
                          <UserOutlined className='site-form-item-icon' />
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name={LoginForm.password}
                      label='รหัสผ่าน'
                      rules={[{ required: true, message: 'กรุณาระบุรหัสผ่าน' }]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        allowClear
                        placeholder='รหัสผ่าน'
                        prefix={
                          <LockOutlined className='site-form-item-icon' />
                        }
                        type='password'
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        style={{ width: '100%' }}
                      >
                        เข้าสู่ระบบ
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={12} lg={12}>
          <BgImgStyle src={IMAGE.BG_LOGIN} />
        </Col>
      </Row>
    </Layout>
  );
}

const BgImgStyle = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const TopicStyle = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const TextLinkStyle = styled(Link)``;
