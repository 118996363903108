import CardJob from '@components/card/CardJob';
import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import ModalPhoneNoForm from '@pages/job/[id]/influencer/register/components/ModalPhoneNoForm';
import { jobOfferApi } from '@services/apis';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface State {
  jobDetail?: JobOfferGetDetailById.Response;
  loading: boolean;
  openModalPhoneNoForm: boolean;
}

export default function JobInfluencerRegisterPage() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    loading: false,
    openModalPhoneNoForm: false,
  });

  const showModalPhoneNoForm = () =>
    setState((prev) => ({ ...prev, openModalPhoneNoForm: true }));
  const hideModalPhoneNoForm = () =>
    setState((prev) => ({ ...prev, openModalPhoneNoForm: false }));

  async function fetchJobDetail(jobId: number) {
    try {
      const res = await jobOfferApi.getDetailById(jobId);
      setState((prev) => ({ ...prev, jobDetail: res }));
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  useEffect(() => {
    fetchJobDetail(+id);
  }, []);

  return (
    <LayoutPublic>
      <Loading loading={state.loading}>
        {state.jobDetail && (
          <ModalPhoneNoForm
            open={state.openModalPhoneNoForm}
            jobId={state.jobDetail.job.id}
            onClose={hideModalPhoneNoForm}
          />
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardJob
            buttonText='สมัคร'
            jobId={+id}
            onClickButton={showModalPhoneNoForm}
          />
        </div>
      </Loading>
    </LayoutPublic>
  );
}
