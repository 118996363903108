import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import SectioJobDetail from '@components/section/SectionJobDetail';
import { JOB_OFFER_STAGE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import ButtonSubmit from '@pages/job/[id]/employer/select-reviewer/components/ButtonSubmit';
import TabCandidates from '@pages/job/[id]/employer/select-reviewer/components/TabCandidates';
import { LocationStateJobEmployerWaitingPage } from '@pages/job/[id]/employer/waiting';
import { jobApplicationApi, jobOfferApi } from '@services/apis';
import { JobApplicationGet } from '@services/models/jobApplication';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Affix, Col, notification, Progress, Row, Tabs } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface State {
  loadingTabs: boolean;
  loadingSubmit: boolean;
  loadingJobDetail: boolean;

  jobDetailResp?: JobOfferGetDetailById.Response;
  candidateResp?: JobApplicationGet.Response;
  selectedIdList: number[];
  totalCandidates: number;
  totalApplicatants: number;
}
export default function JobEmployerSelectReviewer() {
  const { id }: { id: string } = useParams();
  const history = useHistory();

  const [state, setState] = useState<State>({
    loadingTabs: false,
    loadingSubmit: false,
    loadingJobDetail: false,

    selectedIdList: [],
    totalCandidates: 0,
    totalApplicatants: 0,
  });

  const showLoadingTabs = () =>
    setState((prev) => ({ ...prev, loadingTabs: true }));
  const hideLoadingTabs = () =>
    setState((prev) => ({ ...prev, loadingTabs: false }));
  const showLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));
  const showLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: true }));
  const hideLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: false }));

  const tabItems = [
    {
      label: <>Candidates ({state.totalCandidates})</>,
      key: 'tab-1',
      children: (
        <TabCandidates
          jobId={+id}
          selectedIdList={state.selectedIdList}
          selectable={
            state.jobDetailResp
              ? state.selectedIdList.length <
                state.jobDetailResp.info.maxApplications
              : false
          }
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
      ),
    },
    // {
    //   label: <>ผู้สมัครทั้งหมด ({state.totalApplicatants})</>,
    //   key: 'tab-2',
    //   children: (
    //     <TabAll
    //       jobId={+id}
    //       selectedIdList={state.selectedIdList}
    //       selectable={
    //         state.jobDetailResp
    //           ? state.selectedIdList.length <
    //             state.jobDetailResp.info.maxApplications
    //           : false
    //       }
    //       onSelect={onSelect}
    //       onDeselect={onDeselect}
    //     />
    //   ),
    // },
  ];

  function goToWaitingPage() {
    const targetPath = `${generatePath(ROUTE.JOB_EMPLOYER_WAITING, {
      id,
    })}`;
    const targetState: LocationStateJobEmployerWaitingPage = {
      step: JOB_OFFER_STAGE.HAS_REVIEWER,
    };

    history.push({
      pathname: targetPath,
      state: targetState,
    });
  }

  async function onSubmit() {
    try {
      showLoadingSubmit();
      await jobApplicationApi.employerSelectReviewers(+id, {
        jobApplicationIdList: state.selectedIdList,
      });
      goToWaitingPage();
      notification['success']({
        message: 'บันทึกสำเร็จ',
      });
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }

  function onSelect(id: number) {
    setState((prev) => ({
      ...prev,
      selectedIdList: [...prev.selectedIdList, id],
    }));
  }

  function onDeselect(id: number) {
    const newList = state.selectedIdList.filter((e) => e !== id);
    setState((prev) => ({
      ...prev,
      selectedIdList: newList,
    }));
  }

  async function fetchJobDetail() {
    try {
      showLoadingJobDetail();
      const res = await jobOfferApi.getDetailById(+id);
      setState((prev) => ({ ...prev, jobDetailResp: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingJobDetail();
    }
  }

  async function fetchTabs() {
    try {
      showLoadingTabs();
      const res = await jobApplicationApi.employerGetTabsSelectCandidate(+id);

      const totalCandidates =
        _.find(res?.data, (e) => e.tab === 'candidate')?.total ?? 0;
      const totalApplicatants =
        _.find(res?.data, (e) => e.tab === 'total')?.total ?? 0;

      setState((prev) => ({ ...prev, totalApplicatants, totalCandidates }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingTabs();
    }
  }

  useEffect(() => {
    fetchJobDetail();

    fetchTabs();
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Content.Wrapper>
        <SectioJobDetail jobId={+id} />

        <CardContent>
          <Content.RowSectionSelectHeader justify='space-between'>
            <Content.RowSelectionTitle>เลือกผู้สมัคร</Content.RowSelectionTitle>
            เลือกแล้ว {state.selectedIdList.length} จาก{' '}
            {state.jobDetailResp?.info.maxApplications}
          </Content.RowSectionSelectHeader>

          <Content.RowProgress>
            <Col span={24}>
              <Affix offsetTop={16}>
                <div>
                  <Progress
                    percent={
                      state.jobDetailResp?.info.maxApplications
                        ? (state.selectedIdList.length /
                            state.jobDetailResp.info.maxApplications) *
                          100
                        : 0
                    }
                  />
                </div>
              </Affix>
            </Col>
          </Content.RowProgress>

          <Tabs items={tabItems} />
        </CardContent>

        {state.selectedIdList.length ===
          state.jobDetailResp?.info.maxApplications && (
          <ButtonSubmit loading={state.loadingSubmit} onClick={onSubmit} />
        )}
      </Content.Wrapper>
    </LayoutPublic>
  );
}
const Content = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  RowSectionSelectHeader: styled(Row)`
    font-size: 16px;
    margin-bottom: 8px;
  `,
  RowSelectionTitle: styled.div`
    font-size: 1.4rem;
  `,
  RowProgress: styled(Row)`
    margin-bottom: 16px;
  `,
  SectionSelectCardContainer: styled.div`
    margin-top: 12px;
  `,
};
