import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { createMarkup } from '@utils/dataUtils';
import { Drawer } from 'antd';
import React from 'react';

type Props = {
  title: string;
  open: boolean;
  data?: string;
  onClose: () => void;
};
export default function ModalHTML(props: Props) {
  const { title, open, data = '</>', onClose } = props;

  const { isMobile } = useResponsiveContext();

  return (
    <Drawer
      title={title}
      placement='right'
      width={isMobile ? '100%' : '50%'}
      onClose={onClose}
      open={open}
    >
      <div dangerouslySetInnerHTML={createMarkup(data)} />
    </Drawer>
  );
}
