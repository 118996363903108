import { ITableProps } from '@components/helper/ITable';
import PagePanel from '@components/pages/PagePanel';
import {
  RecordType,
  Table,
} from '@pages/backoffice/manages/influencer-links/components/Table';
import { generateURL } from '@pages/backoffice/manages/influencer-links/edit';
import { ITablePagination } from '@services/models/common';
import React from 'react';

type Props = {
  loading?: boolean;
  dataSource: RecordType[];
  pagination: ITablePagination;
  onLoadMore: () => void;
  onRefresh: () => void;
};
export default function ResponsiveDataView(props: ITableProps & Props) {
  const {
    loading,
    dataSource,
    pagination,
    // onLoadMore,
    // onRefresh,
    onChangePagination,
    onChangeSorterColumn,
  } = props;

  // const { isDesktop } = useResponsiveContext();

  // const history = useHistory();

  // const goToSelectCandidate = (id: number) => {
  //   history.push(
  //     generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_SELECT_CANDIDATES, { id })
  //   );
  // };

  // const goToApproveUpload = (id: number) => {
  //   history.push(
  //     generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_APPROVE_UPLOADS, {
  //       id,
  //     })
  //   );
  // };

  // function completeJob(id: number) {
  //   IConfirmModal({
  //     type: 'confirm',
  //     title: 'ยืนยันการจบงาน ?',
  //     okText: 'ยืนยัน',
  //     cancelText: 'ยกเลิก',
  //     onOk: async () => {
  //       try {
  //         const request: JobOfferComplete.Request = {
  //           jobOfferId: id,
  //           // updateById: getLogedInUser()?.id!,
  //           //TODO remove updateById
  //         };
  //         const res = await jobOfferApi.complete(request);
  //         if (res.status) {
  //           onRefresh();
  //         }
  //       } catch (error) {
  //         failedToFetchAPI(error);
  //       }
  //     },
  //   });
  // }

  // async function handleChangeJobApplySwitch(id: number, canApply: boolean) {
  //   try {
  //     const request: JobOfferChangeCanApply.Request = {
  //       id: id,
  //       canApply: canApply,
  //     };
  //     await jobOfferApi.changeCanApply(request);
  //   } catch (error) {
  //     failedToFetchAPI(error);
  //   }
  // }

  return (
    // <div>
    //   {isDesktop ? (
    //     <PagePanel>
    //       <Table
    //         loading={loading}
    //         dataSource={dataSource}
    //         pagination={pagination}
    //         onChangeSorterColumn={onChangeSorterColumn}
    //         onChangePagination={onChangePagination}
    //       />
    //     </PagePanel>
    //   ) : (
    //     <CardList
    //       loading={loading}
    //       dataSource={dataSource}
    //       pagination={pagination}
    //       onLoadMore={onLoadMore}
    //       onRefresh={onRefresh}
    //       genINFRegisterLink={genINFRegisterLink}
    //       genINFUploadLink={genINFUploadLink}
    //       genEMPLink={genEMPLink}
    //       genAnnouncementLink={genAnnouncementLink}
    //       goToSelectCandidatePage={goToSelectCandidate}
    //       goToApproveUploadPage={goToApproveUpload}
    //       completeJob={completeJob}
    //       handleChangeJobApplySwitch={handleChangeJobApplySwitch}
    //     />
    //   )}
    // </div>

    <PagePanel>
      <Table
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        onChangeSorterColumn={onChangeSorterColumn}
        onChangePagination={onChangePagination}
        generateURL={generateURL}
      />
    </PagePanel>
  );
}
