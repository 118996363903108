import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  ProductCreate,
  ProductDetail,
  ProductList,
  ProductUploadPhoto as ProductUploadFile,
} from '@services/models/product';
import { generatePath } from 'react-router-dom';

export async function list(req: Partial<ProductList.Request>) {
  const res = await api.post<ProductList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.PRODUCT_LIST}`,
    req
  );
  return res.data;
}

export async function create(req: FormData) {
  const res = await api.post<ProductCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.PRODUCT_CREATE}`,
    req,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return res.data;
}

export async function detail(id: number) {
  const res = await api.get<ProductDetail.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.PRODUCT_DETAIL_BY_ID, { id })}`
  );
  return res.data;
}

export async function uploadFile(req: FormData) {
  const res = await api.post<ProductUploadFile.Response>(
    `${ENDPOINT.PRODUCT_UPLOAD_FILE}`,
    req,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return res.data;
}
