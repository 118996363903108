import { Button, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClear: () => void;
  loading: boolean;
};
export default function FormButtonController(props: Props) {
  const { onClear, loading } = props;

  return (
    <Row gutter={16}>
      <Col span={12}>
        <ButtonStyleClear block onClick={onClear}>
          ล้าง
        </ButtonStyleClear>
      </Col>
      <Col span={12}>
        <ButtonStyleSubmit block htmlType='submit' loading={loading}>
          ค้นหา
        </ButtonStyleSubmit>
      </Col>
    </Row>
  );
}

const ButtonStyleClear = styled(Button)`
  color: var(--gray-color-8);
`;

const ButtonStyleSubmit = styled(Button)`
  border-color: var(--primary-color);

  span {
    color: var(--primary-color);
  }
`;
