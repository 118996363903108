/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextGrayStyle6 } from '@components/text/IStyleComponent';
import { ITablePagination } from '@services/models/common';
import { scrollToTop } from '@utils/browserUtils';
import { Col, Row, Table } from 'antd';
import {
  ColumnsType,
  ExpandableConfig,
  FilterValue,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import React, { useState } from 'react';

import Br from './Br';

export type ITableProps = {
  columns?: ColumnsType<any>;
  dataSource?: any[];
  loading?: boolean;
  hideTotal?: boolean;
  pagination?: ITablePagination;
  extra?: React.ReactNode[];
  onChangePagination?: (
    pageNumber: number,
    pageSize: number,
    total: number,
    totalPages: number
  ) => void;
  onChangeSorterColumn?: (sorter: any) => void;
  expandable?: ExpandableConfig<any>;
};
export default function ITable(props: ITableProps) {
  const {
    columns,
    dataSource,
    loading,
    hideTotal,
    pagination,
    onChangePagination,
    onChangeSorterColumn,
    extra,
    expandable,
  } = props;

  const [sorter, setSorter] = useState<{ field: any; order: string }>();

  function onChange(
    p: TablePaginationConfig,
    f: Record<string, FilterValue | null>,
    s: any
  ) {
    //TODO implement filter
    if (pagination) {
      if (
        p.current !== pagination?.pageNumber ||
        p.pageSize !== pagination?.pageSize
      ) {
        const pageNumber = p.current ?? 0;
        const pageSize = p.pageSize ?? 0;
        const total = p.total ?? 0;
        const totalPages = Math.floor(
          p.total ? p.total / (p.pageSize ?? 1) : 0
        );

        onChangePagination &&
          onChangePagination(pageNumber, pageSize, total, totalPages);
      }
    }

    if (sorter?.field !== s.field || sorter?.order !== s.order) {
      setSorter(s);
      onChangeSorterColumn && onChangeSorterColumn(s);
    }

    scrollToTop();
  }

  return (
    <div>
      <Row justify='space-between' align='middle'>
        <Col>
          {!hideTotal && (
            <TextGrayStyle6>ทั้งหมด {pagination?.total}</TextGrayStyle6>
          )}
        </Col>
        <Col>{extra}</Col>
      </Row>
      <Br />
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onChange}
        expandable={expandable}
      />
    </div>
  );
}
