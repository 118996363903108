import { useResponsiveContext } from '@contexts/ResponsiveContext';
import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  transparent?: boolean | null;
};
export default function PagePanel(props: Props) {
  const { children, styles, transparent = false } = props;

  const { isMobile } = useResponsiveContext();

  // if (transparent) {
  // }
  return (
    <Wrapper
      style={{ ...styles }}
      transparent={transparent ?? false}
      isMobile={isMobile ?? false}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ transparent: boolean; isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '26px 16px' : '26px 24px')};
  background-color: ${(props) =>
    props.transparent ? 'transparent' : 'var(--gray-color-1)'};
  box-shadow: ${(props) =>
    props.transparent ? 'transparent' : ' var(--base-box-shadow-sm)'};
  border-radius: var(--base-border-radius);
  border: solid 1px var(--gray-color-4);
`;
