import { EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
  value?: EditorState; //TODO value (get from parent)
  onChange?: (value: EditorState) => void;
};
export default function EditorInput(props: Props) {
  const { onChange } = props;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // const [html, setHTML] = useState();

  //TODO implement initState()
  // function initState() {
  //   const contentBlock = htmlToDraft(initialHTML ?? '</>');

  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(
  //       contentBlock.contentBlocks
  //     );
  //     setEditorState(EditorState.createWithContent(contentState));
  //   }
  // }

  function onEditorStateChange(newState: EditorState) {
    setEditorState(newState);
    triggerChange(newState);
  }

  const triggerChange = (changedValue: EditorState) => {
    onChange?.(changedValue);
  };

  useEffect(() => {
    // initState();
  }, []);

  return (
    <Styled>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'remove',
            'history',
          ],
        }}
      />
    </Styled>
  );
}

const Styled = styled.div`
  .rdw-editor-main {
    border: 1px solid #f1f1f1;
    padding: 6px;
  }
`;
