import { JOB_OFFER_STAGE } from '@constants/enum';
import { Badge, Tag } from 'antd';
import React from 'react';

export default function JobOfferStageTag(stage: string, badge?: number) {
  let msg = '';
  let color = 'default';
  switch (stage) {
    case JOB_OFFER_STAGE.NEW: {
      msg = 'ใหม่';
      color = 'purple';
      break;
    }
    case JOB_OFFER_STAGE.HAS_CANDIDATE: {
      msg = 'Candidate';
      color = 'warning';
      break;
    }
    case JOB_OFFER_STAGE.HAS_REVIEWER: {
      msg = 'Reviewer';
      color = 'warning';
      break;
    }
    case JOB_OFFER_STAGE.IN_REVIEW: {
      return (
        <Badge count={badge}>
          <Tag color='processing'>กำลังรีวิว</Tag>
        </Badge>
      );
      break;
    }
    case JOB_OFFER_STAGE.ACCEPT: {
      msg = 'ผ่าน';
      color = 'success';
      break;
    }
    case JOB_OFFER_STAGE.FAIL: {
      msg = 'ล้มเหลว';
      color = 'error';
      break;
    }
    case JOB_OFFER_STAGE.COMPLETE: {
      msg = 'สำเร็จ';
      color = 'default';
      break;
    }
  }

  return (
    <Tag color={color} style={{ margin: '0' }}>
      {msg}
    </Tag>
  );
}
