import { CopyOutlined } from '@ant-design/icons';
import ITable, { ITableProps } from '@components/helper/ITable';
import { TextGrayStyle6 } from '@components/text/IStyleComponent';
import { DATE_FORMAT_DDMMYYY_HH_mm_ss } from '@constants/constants';
import { ROUTE } from '@constants/routes';
import { InfluencerLinkSearch } from '@services/models/influencerLink';
import { copyLinkToClipboard } from '@utils/actionUtils';
import { dateFormat } from '@utils/dateUtils';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import _ from 'lodash';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';

export interface RecordType {
  key: React.Key;
  item: InfluencerLinkSearch.Data;
}

type Props = {
  generateURL: (token: string) => string;
};
export function Table(props: ITableProps & Props) {
  const { generateURL } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ชื่อลิงค์',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      // sorter: () => 0,
      render: renderName,
    },
    {
      title: 'ลิงค์',
      dataIndex: 'urlLink',
      key: 'url_link',
      width: '40%',
      render: renderURL,
    },
    {
      title: 'จำนวนอินฟลูเอนเซอร์',
      dataIndex: 'totalMember',
      key: 'total_member',
      render: renderTotalMember,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdAt',
      key: 'created_at',
      render: renderCreatedAt,
      // sorter: () => 0,
      // render: renderName,
    },
  ];

  function renderName(value: string, record: RecordType) {
    return (
      <Link
        to={generatePath(ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS_EDIT, {
          id: record.item.influencerLink.id,
        })}
      >
        {record.item.influencerLink.name}
      </Link>
    );
  }

  function renderURL(value: string, record: RecordType) {
    const url = generateURL(record.item.influencerLink.token);
    return (
      <Space>
        <Button
          icon={<CopyOutlined />}
          onClick={() => copyLinkToClipboard(url)}
        ></Button>
        <TextGrayStyle6>
          {_.truncate(url, {
            length: 60,
            omission: '...',
          })}
        </TextGrayStyle6>
      </Space>
    );
  }

  function renderTotalMember(value: string, record: RecordType) {
    return record.item.totalMember;
  }

  function renderCreatedAt(value: string, record: RecordType) {
    return dateFormat(
      record.item.influencerLink.createdAt,
      DATE_FORMAT_DDMMYYY_HH_mm_ss
    );
  }

  // function renderName(value: string, record: RecordType) {
  //   const id = record.item.datum.id;
  //   return (
  //     <div style={{ width: '300px' }}>
  //       <a
  //         href={`${generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL, { id })}`}
  //       >
  //         <Row justify='space-between' gutter={12}>
  //           <Col span={8}>
  //             <div
  //               style={{
  //                 objectFit: 'contain',
  //                 width: '100%',
  //                 height: '80px',
  //               }}
  //             >
  //               <img
  //                 src={record.item.info.productImageURL}
  //                 style={{
  //                   objectFit: 'cover',
  //                   borderRadius: '8px',
  //                   width: '100%',
  //                   height: '100%',
  //                 }}
  //               />
  //             </div>
  //           </Col>
  //           <Col span={16}>
  //             {record.item.datum.name}
  //             <br />
  //             <TextGrayStyle7>ID: {id}</TextGrayStyle7>
  //           </Col>
  //         </Row>
  //       </a>
  //     </div>
  //   );
  // }

  // function renderCompanyName(value: string, record: RecordType) {
  //   return <div style={{ width: '200px' }}>{record.item.info.companyName}</div>;
  // }

  // async function fetchMasterReviewSocialMediaChannel() {
  //   const options: Partial<MasterList.Request> = {
  //     limit: { pageNumber: 1, pageSize: 10 },
  //     category: MASTER_DATA_CATEGORY.SOCIAL_MEDIA_CHANNEL,
  //   };

  //   try {
  //     const response = await masterApi.list(options);
  //     const selectOptions = response.data.map((item) => ({
  //       label: item.name,
  //       value: item.code,
  //     }));
  //     setSocialMediaChannelOptions(selectOptions);
  //   } catch (error) {
  //     failedToFetchAPI(error);
  //   }
  // }

  return <ITable {...props} columns={columns} />;
}

// const ActionBtnStyle = styled(Button)`
//   width: 100%;
// `;
