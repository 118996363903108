/* eslint-disable no-prototype-builtins */
import ButttonViewUpload from '@components/button/ButtonViewUpload';
import CardApplication from '@components/card/CardApplication';
import { APIModeType } from '@components/section/SectionComment';
import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { jobApplicationApi } from '@services/apis';
import { JobApplicationGet } from '@services/models/jobApplication';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Skeleton,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export type STAGE_TYPE =
  | JOB_APPLICATION_STAGE.PRE_PASS
  | JOB_APPLICATION_STAGE.UPLOAD;

interface State {
  loading: boolean;
  resp?: JobApplicationGet.Response;
}

type Props = {
  apiMode: APIModeType;
  stage: STAGE_TYPE;
  jobId: number;
  passIdList: number[];
  failIdList: number[];
  adminLayout: boolean;
  onPass: (id: number) => void;
  onDepass: (id: number) => void;
  onFail: (id: number, comment: string) => void;
  onDefail: (id: number) => void;
};
export default function TabApprove(props: Props) {
  const {
    apiMode,
    stage,
    jobId,
    passIdList,
    failIdList,
    adminLayout,
    onPass,
    onDepass,
    onFail,
    onDefail,
  } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(
    req: JobApplicationGet.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoading();
      let resp: JobApplicationGet.Response;
      req = {
        ...req,
        reviewer: true,
        stage,
      };
      if (apiMode === 'admin') {
        resp = await jobApplicationApi.get({
          ...req,
          jobId,
        });
      } else {
        resp = await jobApplicationApi.employerGet(jobId, req);
      }

      if (loadMoreMode) {
        setState((prev) => {
          resp.data = [...(prev.resp?.data ?? []), ...resp.data];
          return { ...prev, resp: resp };
        });
      } else {
        setState((prev) => ({ ...prev, resp: resp }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <InfiniteScroll
      dataLength={state.resp?.data?.length ?? 0}
      next={onLoadMore}
      hasMore={
        state.resp?.page
          ? state.resp.page.pageNumber < state.resp.page.totalPages
          : false
      }
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        loading={state.loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={state.resp?.data}
        renderItem={(item) => (
          <List.Item>
            <CardApplication
              key={item.jobApplication.id}
              jobApplication={item.jobApplication}
              data={item.info}
              selected={
                passIdList.includes(item.jobApplication.id) ||
                failIdList.includes(item.jobApplication.id)
              }
              selectedBgColor={
                failIdList.includes(item.jobApplication.id)
                  ? '#c14545'
                  : passIdList.includes(item.jobApplication.id)
                  ? 'green'
                  : 'orange'
              }
              adminLayout={adminLayout}
            >
              <CardBody
                id={item.jobApplication.id}
                jobId={item.jobApplication.jobOfferId}
                onPass={onPass}
                onDepass={onDepass}
                onFail={onFail}
                onDefail={onDefail}
                apiMode={apiMode}
              />
            </CardApplication>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
}

interface CardBodyState {
  openDrawerComment: boolean;
  status?: 'pass' | 'fail';
}
type CardBodyProps = {
  apiMode: APIModeType;
  id: number;
  jobId: number;
  onPass: (id: number) => void;
  onDepass: (id: number) => void;
  onFail: (id: number, comment: string) => void;
  onDefail: (id: number) => void;
};
function CardBody(props: CardBodyProps) {
  const { apiMode, id, jobId, onPass, onDepass, onFail, onDefail } = props;

  const { isMobile } = useResponsiveContext();

  const [state, setState] = useState<CardBodyState>({
    openDrawerComment: false,
  });

  const showDrawerComment = () =>
    setState((prev) => ({ ...prev, openDrawerComment: true }));
  const hideDrawerComment = () =>
    setState((prev) => ({ ...prev, openDrawerComment: false }));

  function handleOnFail(values: { comment: string }) {
    onFail(id, values.comment);
    hideDrawerComment();
    setState((prev) => ({ ...prev, status: 'fail' }));
  }

  function handleOnDefail() {
    setState((prev) => ({ ...prev, status: undefined }));
    hideDrawerComment();
    onDefail(id);
  }

  function handleOnPass() {
    if (state.status === 'pass') {
      setState((prev) => ({ ...prev, status: undefined }));
      onDepass(id);
    } else {
      setState((prev) => ({ ...prev, status: 'pass' }));
      onPass(id);
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Drawer
        title='Comment งานที่ไม่ผ่าน'
        placement='right'
        width={isMobile ? '100%' : 500}
        onClose={hideDrawerComment}
        open={state.openDrawerComment}
      >
        <Form layout='vertical' onFinish={handleOnFail}>
          <Form.Item
            label='Comment'
            name='comment'
            rules={formRuleUtils.REQUIRE}
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              {state.status === 'fail' && (
                <Button danger onClick={handleOnDefail}>
                  ลบ
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

      <Row gutter={[12, 12]}>
        <Col
          span={state.status === 'fail' ? 16 : state.status === 'pass' ? 0 : 12}
        >
          <Button
            block
            shape='round'
            danger
            type={state.status === 'fail' ? 'primary' : undefined}
            onClick={showDrawerComment}
          >
            ไม่ผ่าน
          </Button>
        </Col>

        <Col
          span={state.status === 'fail' ? 0 : state.status === 'pass' ? 16 : 12}
        >
          <Button
            ghost
            block
            shape='round'
            type='primary'
            style={{
              backgroundColor:
                state.status === 'pass' ? 'var(--success-color)' : undefined,
              color: state.status === 'pass' ? 'white' : undefined,
              borderColor:
                state.status === 'pass' ? 'var(--success-color)' : undefined,
            }}
            onClick={handleOnPass}
          >
            ผ่าน
          </Button>
        </Col>
        <Col span={state.status ? 8 : 24}>
          <ButttonViewUpload apiMode={apiMode} id={id} jobId={jobId} />
        </Col>
      </Row>
    </div>
  );
}
