import { RcFile } from 'antd/lib/upload/interface';
import DOMPurify from 'dompurify';
import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import * as mime from 'mime-types';
import { v4 as uuidv4 } from 'uuid';
// export function arrayEquals(a: any, b: any) {
//   return (
//     Array.isArray(a) &&
//     Array.isArray(b) &&
//     a.length === b.length &&
//     a.every((val, index) => val === b[index])
//   );
// }

export function getBase64(file: RcFile) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function kFormatter(value: number) {
  return new Intl.NumberFormat('en-GB', {
    notation: 'compact',
  }).format(value);
}

export function numberFormat(value: number) {
  return new Intl.NumberFormat('en-US').format(value);
}

export function createMarkup(html: string | Node) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}
/**
 * ```
 * Get SortBy and SortDirection from tableSorter
 * ```
 *
 * ```
 * getTableSorter(sorter: any)
 * => return {sortBy: string | undefined, sortDirection: string | undefined}
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTableSorter(sorter: any) {
  let sortBy;
  let sortDirection;

  if (sorter.order) {
    sortBy = sorter.field;
    sortDirection = sorter.order.slice(0, -3); // remove 'end' [ascend, descend]
  }

  return { sortBy: sortBy, sortDirection: sortDirection };
}

export function isImage(filename: string) {
  const fileMIMETypeExt = mime.contentType(filename); // image/jpeg
  const fileMIMEType = fileMIMETypeExt
    .toString()
    .substring(0, fileMIMETypeExt.toString().indexOf('/'));
  return 'image' === fileMIMEType;
}

export function isVideo(filename: string) {
  const fileMIMETypeExt = mime.contentType(filename); // image/jpeg
  const fileMIMEType = fileMIMETypeExt
    .toString()
    .substring(0, fileMIMETypeExt.toString().indexOf('/'));
  return 'video' === fileMIMEType;
}

export function isUUID(value: string) {
  const pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(value);
}

export function blobToFile(theBlob: Blob, fileName: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as RcFile;
}

export function editorStateToHTML(editorState: EditorState) {
  return editorState
    ? convertToHTML(editorState.getCurrentContent())
    : undefined;
}

export function niceBytes(x: number) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0,
    n = x || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

export function uuid() {
  return uuidv4();
}

export function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}
