import { api } from '@core/api/api-client';
import {
  AgreementAccept,
  AgreementGetByUserId,
  AgreementLatest,
} from '@services/models/agreement';
import { generatePath } from 'react-router-dom';

import { ENDPOINT } from '.';

export async function latest() {
  const res = await api.get<AgreementLatest.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AGREEMENT_GET_LATEST}`
  );
  return res.data;
}

export async function accept(req: AgreementAccept.Request) {
  const res = await api.post<AgreementAccept.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AGREEMENT_ACCEPT}`,
    req
  );
  return res.data;
}

export async function getByUserId(id: number) {
  const res = await api.get<AgreementGetByUserId.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.AGREEMENT_GET_BY_USER_ID, {
      id,
    })}`
  );
  return res.data;
}
