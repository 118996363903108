import { ROUTE } from '@constants/routes';
import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

export default function PermissionDeniedPage() {
  const history = useHistory();

  return (
    <Content.Container>
      <Result
        status='403'
        title='403'
        subTitle='ขออภัย, สิทธิในการเข้าถึงไม่เพียงพอ.'
        extra={
          <Button
            type='primary'
            key='home'
            onClick={() => history.push(ROUTE.HOME)}
          >
            กลับสู่หน้าหลัก
          </Button>
        }
      />
    </Content.Container>
  );
}

const Content = {
  Container: styled.div`
    min-height: 70vh;
  `,
};
