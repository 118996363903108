import CardContent from '@components/card/CardContent';
import ModalHTML from '@components/drawer/DrawerHTML';
import ISocialIcon from '@components/icon/ISocialIcon';
import { jobOfferApi } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Col, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface State {
  loadingJobDetail: boolean;
  openDescripton: boolean;
  jobDetail?: JobOfferGetDetailById.Response;
}
type Props = {
  jobId: number;
  hideTitle?: boolean;
};
export default function SectioJobDetail(props: Props) {
  const { jobId, hideTitle = false } = props;

  const [state, setState] = useState<State>({
    loadingJobDetail: false,
    openDescripton: false,
  });

  const showLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: true }));
  const hideLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: false }));
  const showModalDescription = () =>
    setState((prev) => ({ ...prev, openDescripton: true }));
  const hideModalDescription = () =>
    setState((prev) => ({ ...prev, openDescripton: false }));

  const items: LabelValue[] = [
    {
      label: 'บริษัท',
      value: state.jobDetail?.info.companyName,
    },
    {
      label: 'ขอบเขตงาน',
      value: state.jobDetail?.info.reviewPackageName,
    },
    {
      label: 'คีย์เวิร์ด',
      value: state.jobDetail?.job.reviewKeyword,
    },
    {
      label: 'กิจกรรม/รีวิว',
      value: (
        <Space>
          {state?.jobDetail?.job.reviewSmcCodes.split(',').map((e) => {
            return <ISocialIcon key={e} network={e} width={24} height={24} />;
          })}
        </Space>
      ),
    },
    {
      label: 'เพศ',
      value: state.jobDetail?.info.genderName,
    },
    {
      label: 'อายุ',
      value: state.jobDetail?.info.ageRangeName,
    },
    {
      label: 'รายละเอียดงาน',
      value: <a onClick={showModalDescription}>แสดง</a>,
    },
  ];

  const titleItem: LabelValue = {
    label: 'งาน',
    value: state.jobDetail?.job.name,
  };

  async function fetchJobDetail() {
    try {
      showLoadingJobDetail();
      const res = await jobOfferApi.getDetailById(jobId);
      setState((prev) => ({ ...prev, jobDetail: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingJobDetail();
    }
  }

  useEffect(() => {
    fetchJobDetail();
  }, []);

  return (
    <div>
      <ModalHTML
        title='รายละเอียดงาน'
        open={state.openDescripton}
        onClose={hideModalDescription}
        data={state.jobDetail?.job.description}
      />
      <Content.Wrapper>
        <CardContent>
          <Content.SectionTitle hidden={hideTitle}>
            รายละเอียดงาน
          </Content.SectionTitle>
          <Spin spinning={state.loadingJobDetail}>
            <Row gutter={24} justify='space-between'>
              <Col flex='auto'>
                <Content.TitleJobWrapper>
                  <Content.Label>{titleItem.label}</Content.Label>
                  <Content.Value>{titleItem.value}</Content.Value>
                </Content.TitleJobWrapper>

                <Row justify='space-between'>
                  {items.map((e) => (
                    <Col key={e.label} xs={24} md={12} xl={4}>
                      <Content.Label>{e.label}</Content.Label>
                      <Content.Value
                        ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                      >
                        {e.value}
                      </Content.Value>
                    </Col>
                  ))}
                </Row>
              </Col>

              <Content.ColImageWrapper flex='200px'>
                <Content.ProductImage
                  src={state.jobDetail?.info.productImageURL}
                />
              </Content.ColImageWrapper>
            </Row>
          </Spin>
        </CardContent>
      </Content.Wrapper>
    </div>
  );
}
const Content = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  SectionTitle: styled.div<{ hidden: boolean }>`
    font-size: 1.4rem;
    margin-bottom: 12px;
  `,
  Label: styled.div`
    color: var(--gray-color-label);
    font-size: 12px;
  `,
  Value: styled(Typography.Paragraph)``,
  TitleJobWrapper: styled.div`
    margin-bottom: 24px;
    font-size: 16px;
  `,
  ColImageWrapper: styled(Col)`
    height: 10rem;
  `,
  ProductImage: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  `,
};
