import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import { JobOfferAccessLinkValidateToken } from '@services/models/jobOfferAccessLink';
import { generateQueryString } from '@utils/apiUtils';

export async function validateToken(token: string) {
  const res = await api.get<JobOfferAccessLinkValidateToken.Response>(
    `${ENDPOINT.BASE_URL}${
      ENDPOINT.JOB_OFFER_ACCESS_LINKS_VALIDATE_TOKEN
    }?${generateQueryString({ token })}`
  );
  return res.data;
}
