import { State } from '@pages/backoffice/manages/jobs/components/AddJobDrawer';
import { Button, Col, Row } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  actionState: State;
  loading: boolean;
  handleOnBackFromSummary: () => void;
  handleOnSubmit: () => void;
  handleOnSummary: () => void;
};
export default function ButtonSheet(props: Props) {
  const {
    actionState,
    loading,
    handleOnBackFromSummary,
    handleOnSubmit,
    handleOnSummary,
  } = props;
  return (
    <ButtonSheetStyle.Wrapper
      visible={actionState.selectedProduct !== undefined}
    >
      {actionState.isSummaryState ? (
        <Row gutter={12}>
          <Col span={8}>
            <Button
              block
              disabled={loading}
              size='large'
              onClick={handleOnBackFromSummary}
            >
              แก้ไข
            </Button>
          </Col>
          <Col span={16}>
            <Button
              block
              type='primary'
              size='large'
              loading={loading}
              onClick={handleOnSubmit}
            >
              ยืนยัน
            </Button>
          </Col>
        </Row>
      ) : (
        <Button block type='primary' size='large' onClick={handleOnSummary}>
          ตรวจสอบข้อมูล
        </Button>
      )}
    </ButtonSheetStyle.Wrapper>
  );
}

const ButtonSheetStyle = {
  Wrapper: styled.div<{ visible?: boolean }>`
    height: 70px;
    padding: 16px;
    background-color: white;

    position: fixed;
    bottom: 0;
    margin-left: -16px;
    width: -webkit-fill-available;
    border-radius: 24px 24px 0 0;

    button {
      border-radius: 24px;
    }

    ${(props) =>
      props.visible
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  `,
};
