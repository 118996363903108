import LayoutPublic from '@components/layout/LayoutPublic';
import SectionComment from '@components/section/SectionComment';
import SectionJobDetail from '@components/section/SectionJobDetail';
import SectionUploadHistory from '@components/section/SectionUploadHistory';
import SectionUpload from '@pages/job/[id]/influencer/upload/verified-reviewer/components/SectionUpload';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface State {
  flagUploadHistory: number;
}
export default function JobInfluencerUploadVerifiedReviewerPage() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    flagUploadHistory: 0,
  });

  function onSuccessUpload() {
    setState((prev) => ({
      ...prev,
      flagUploadHistory: prev.flagUploadHistory + 1,
    }));
  }

  return (
    <LayoutPublic hideLogin>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SectionJobDetail jobId={+id} />
        </Col>
        <Col xs={24} lg={12}>
          <SectionUpload jobId={+id} onSuccess={onSuccessUpload} />
        </Col>
        <Col xs={24} lg={12}>
          <SectionComment apiMode='reviewer' jobId={+id} id={0} />
        </Col>

        <Col xs={24}>
          <SectionUploadHistory
            key={state.flagUploadHistory}
            jobId={+id}
            apiMode='reviewer'
            id={0}
          />
        </Col>
      </Row>
    </LayoutPublic>
  );
}
