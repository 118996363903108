import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { ReactNode, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  children?: ReactNode;
};
export default function LayoutResponsive(props: Props) {
  const {
    setIsDesktop,
    setIsTablet,
    setIsMobile,
    setIsNotMobile,
    setIsDesktopOrTablet,
  } = useResponsiveContext();

  const isDesktopMq = useMediaQuery({ minWidth: 992 });
  const isTabletMq = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobileMq = useMediaQuery({ maxWidth: 767 });
  const isNotMobileMq = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    setIsDesktop(isDesktopMq);
    setIsTablet(isTabletMq);
    setIsMobile(isMobileMq);
    setIsNotMobile(isNotMobileMq);
    setIsDesktopOrTablet(isTabletMq || isDesktopMq);
  }, [isDesktopMq, isTabletMq, isMobileMq, isNotMobileMq]);

  return <>{props.children}</>;
}
