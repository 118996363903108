import CardContent from '@components/card/CardContent';
import { JOB_OFFER_STAGE } from '@constants/enum';
import { Steps } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  stage: string;
};
export default function SectionProgress(props: Props) {
  const { stage } = props;

  const jobOfferStageSequence = Object.values(JOB_OFFER_STAGE) as string[];
  const currentStep = jobOfferStageSequence.indexOf(stage);

  return (
    <CardContent>
      <Content.Title>อัพเดทความคืบหน้า</Content.Title>
      <Steps direction='vertical' current={currentStep}>
        <Steps.Step
          title='เปิดรับสมัคร'
          description='เปิดให้ influencer สมัครได้'
        />
        <Steps.Step
          title='เลือกเคนดิเดท'
          description='แอดมินคัดเลือกผู้สมัครแล้ว'
        />
        <Steps.Step
          title='เลือกรีวิวเวอร์'
          description='ผู้ประกอบการเลือกผู้สมัครแล้ว'
        />
        <Steps.Step
          title='กำลังรีวิวสินค้า'
          description='Influencer กำลังทำการรีวิวสินค้า'
        />
        <Steps.Step title='ผ่าน' description='Employer ให้ผ่าน' />
        <Steps.Step title='ล้มเหลว' description='ล้มเหลว' />
        <Steps.Step title='เสร็จสิ้น' description='เสร็จสิ้น' />
      </Steps>
    </CardContent>
  );
}

const Content = {
  Title: styled.h2`
    margin-bottom: 16px;
  `,
};
