import { VerticalAlignTopOutlined } from '@ant-design/icons';
import SkeletonCard from '@components/loading/SkeletonCard';
import CardApplication from '@pages/backoffice/manages/jobs/components/CardApplication';
import { RecordType } from '@pages/backoffice/manages/jobs/components/Table';
import { ITablePagination } from '@services/models/common';
import { scrollToTop } from '@utils/browserUtils';
import { Divider, List } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

type Props = {
  loading?: boolean;
  dataSource: RecordType[];
  pagination: ITablePagination;
  onLoadMore: () => void;
  onRefresh: () => void;

  genINFRegisterLink: (id: number) => void;
  genINFUploadLink: (id: number) => void;
  genEMPLink: (id: number) => void;
  genAnnouncementLink: (id: number) => void;
  goToSelectCandidatePage: (id: number) => void;
  goToApproveUploadPage: (id: number) => void;
  completeJob: (id: number) => void;
  handleChangeJobApplySwitch: (id: number, canApply: boolean) => void;
};
export default function CardList(props: Props) {
  const { loading, dataSource, pagination, onLoadMore, onRefresh } = props;

  const { ref, inView } = useInView();

  return (
    <div>
      {!inView && (
        <FloatBottomRight>
          <VerticalAlignTopOutlined
            style={{ fontSize: '28px', color: 'var(--secondary-color)' }}
            onClick={scrollToTop}
          />
        </FloatBottomRight>
      )}

      <CardListTotal ref={ref}>
        แสดง {dataSource?.length} จาก {pagination.total} รายการ
      </CardListTotal>

      <InfiniteScroll
        dataLength={dataSource?.length ?? 0}
        next={onLoadMore}
        hasMore={pagination.pageNumber < pagination.totalPages}
        loader={<SkeletonCard />}
        endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
        refreshFunction={onRefresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center', color: 'var(--gray-color-7)' }}>
            &#8595; Pull down to refresh
          </h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center', color: 'var(--gray-color-8)' }}>
            &#8593; Release to refresh
          </h3>
        }
      >
        <List
          grid={{
            gutter: 12,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 6,
            xxl: 3,
          }}
          loading={loading}
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item>
              <CardApplication item={item} {...props} />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
}

const CardListTotal = styled.div`
  margin-bottom: 8px;
  color: var(--gray-color-7);
`;

const FloatBottomRight = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 9999;
  padding: 8px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  color: var(--gray-color-8);
`;
