import React from 'react';

type Props = {
  size?: 'default' | 'smallest' | 'small' | 'large' | 'largest' | 'largest2';
};
export default function Br(props: Props) {
  const { size = 'default' } = props;
  let height: number;

  switch (size) {
    case 'smallest':
      height = 2;
      break;
    case 'small':
      height = 4;
      break;
    case 'default':
      height = 6;
      break;
    case 'large':
      height = 8;
      break;
    case 'largest':
      height = 10;
      break;
    case 'largest2':
      height = 26;
      break;
  }

  return <div style={{ display: 'block', margin: `${height}px 0` }}></div>;
}
