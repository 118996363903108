import { LOCAL_STORAGE_KEY } from '@constants/constants';
import { ROUTE } from '@constants/routes';
import { jobApplicationApi } from '@services/apis';
import { JobOfferDetail } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import { notifyFailed } from '@utils/notifyUtils';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

export interface FormType {
  phoneNo: string;
}
const FORM_SCHEMA = {
  phoneNo: {
    name: 'phoneNo',
    label: 'เบอร์โทรศัพท์',
    rules: formRuleUtils.PHONE_NO,
  },
};

interface State {
  agreementId?: number;
  userId: number;
  jobOfferDetail?: JobOfferDetail.Data;
  loadingBtn: boolean;
  openModalPhoneNoForm: boolean;
}

type Props = {
  open: boolean;
  jobId: number;
  onClose: () => void;
};
export default function ModalPhoneNoForm(props: Props) {
  const { open, jobId, onClose } = props;

  const history = useHistory();

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loadingBtn: false,
    userId: 0,
    openModalPhoneNoForm: false,
  });

  const showLoadingBtn = () =>
    setState((prev) => ({ ...prev, loadingBtn: true }));
  const hideLoadingBtn = () =>
    setState((prev) => ({ ...prev, loadingBtn: false }));

  function goToVerifiedReviewerPage() {
    history.push({
      pathname: generatePath(ROUTE.JOB_INFLUENCER_UPLOAD_VERIFIED_REVIEWER, {
        id: jobId,
      }),
    });
  }

  async function onFinish(values: FormType) {
    try {
      showLoadingBtn();
      const resp = await jobApplicationApi.isReviewer(jobId, {
        phoneNo: values.phoneNo,
      });

      if (resp.isReviewer) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.REVIEWER_TOKEN,
          `${resp.userId}`
        );
        goToVerifiedReviewerPage();
      } else {
        notifyFailed('ไม่พบรีวิวเวอร์', 'ตรวจสอบเบอร์โทรอีกครั้ง');
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingBtn();
    }
  }

  return (
    <ModalStyle
      title='ตรวจสอบสมาชิก'
      open={open}
      onCancel={onClose}
      style={{ borderRadius: '24px' }}
      bodyStyle={{ borderRadius: '24px' }}
      footer={null}
    >
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Form.Item {...FORM_SCHEMA.phoneNo}>
          <Input maxLength={10} placeholder={FORM_SCHEMA.phoneNo.label} />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            loading={state.loadingBtn}
            shape='round'
            size='large'
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </ModalStyle>
  );
}

const ModalStyle = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 24px;
  }
`;
