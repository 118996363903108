/* eslint-disable @typescript-eslint/no-explicit-any */
import { LayoutBackofficeProvider } from '@contexts/LayoutContext';
import { ResponsiveProvider } from '@contexts/ResponsiveContext';
import React, { cloneElement } from 'react';

import { AuthProvider } from './AuthContext';

function ProviderComposer({ contexts, children }: any) {
  return contexts.reduce(
    (kids: any, parent: any) =>
      cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

export default function ContextProvider({ children, data }: any) {
  return (
    <ProviderComposer
      // add providers to array of contexts
      contexts={[
        <AuthProvider key='authProvider' data={data} />,
        <ResponsiveProvider key='responsiveProvider' data={data} />,
        <LayoutBackofficeProvider key='layoutBackofficeProvider' data={data} />,
      ]}
    >
      {children}
    </ProviderComposer>
  );
}
