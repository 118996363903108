import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  InfluencerLinkCreate,
  InfluencerLinkGetById,
  InfluencerLinkGetInfluencersByToken,
  InfluencerLinkSearch,
  InfluencerLinkUpdate,
  InfluencerRefreshLink,
} from '@services/models/influencerLink';
import { generateQueryString } from '@utils/apiUtils';
import { generatePath } from 'react-router-dom';

export async function getById(id: number) {
  const res = await api.get<InfluencerLinkGetById.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.INFLUENCER_LINK_GET_BY_ID, {
      id,
    })}`
  );
  return res.data;
}

export async function search(req: InfluencerLinkSearch.Request) {
  const res = await api.get<InfluencerLinkSearch.Response>(
    `${ENDPOINT.BASE_URL}${
      ENDPOINT.INFLUENCER_LINK_SEARCH
    }?${generateQueryString(req)}`
  );
  return res.data;
}

export async function create(req: InfluencerLinkCreate.Request) {
  const res = await api.post<InfluencerLinkCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCER_LINK_BASE_URL}`,
    req
  );
  return res.data;
}

export async function update(req: InfluencerLinkUpdate.Request) {
  const res = await api.post<InfluencerLinkUpdate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCER_LINK_UPDATE}`,
    req
  );
  return res.data;
}

export async function refreshLink(req: InfluencerRefreshLink.Request) {
  const res = await api.post<InfluencerRefreshLink.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCER_LINK_REFRESH_LINK}`,
    req
  );
  return res.data;
}

export async function getInfluencersByToken(
  token: string,
  req: InfluencerLinkGetInfluencersByToken.Request
) {
  const res = await api.get<InfluencerLinkGetInfluencersByToken.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.INFLUENCER_LINK_GET_INFLUENCERS_BY_TOKEN,
      {
        token,
      }
    )}?${generateQueryString(req)}`
  );
  return res.data;
}
