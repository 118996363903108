import { LoginOutlined } from '@ant-design/icons';
import LayoutResponsive from '@components/layout/LayoutResponsive';
import { IMAGE } from '@constants/resource';
import { ROUTE } from '@constants/routes';
import { Button, Layout } from 'antd';
import React, { ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';

const { Header } = Layout;

type Props = {
  children?: ReactNode;
  contentStyle?: CSSProperties;
  hideLogin?: boolean;
};

export default function LayoutPublic(props: Props) {
  const {
    children,
    contentStyle = {
      padding: '16px',
      backgroundColor: 'var(--bg-gray-color)',
    },
    hideLogin,
  } = props;

  const history = useHistory();

  function goToLoginPage() {
    history.push(ROUTE.LOGIN);
  }

  return (
    <LayoutResponsive>
      <Layout className='site-layout'>
        <HeaderStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Link to='/'>
              <img src={IMAGE.LOGO_DOOQ} alt='Dooq' style={{ width: '80px' }} />
            </Link>

            {!hideLogin && (
              <Button
                type='link'
                icon={<LoginOutlined />}
                style={{ padding: '0px', color: 'white' }}
                onClick={goToLoginPage}
              >
                เข้าสู่ระบบ
              </Button>
            )}
          </div>
        </HeaderStyle>

        <ContentStyle className='site-layout-background' style={contentStyle}>
          {children}
        </ContentStyle>
      </Layout>
    </LayoutResponsive>
  );
}

const HeaderStyle = styled(Header)`
  height: 45px;
`;

const ContentStyle = styled.div`
  min-height: calc(100vh - 64px);
`;
