import ISocialIcon from '@components/icon/ISocialIcon';
import { ROUTE } from '@constants/routes';
import { JobInfluencerRegisterSuccessPageLocationState } from '@pages/job/[id]/influencer/register/success';
import { influencerAPI, jobApplicationApi } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { JobApplicationCreate } from '@services/models/jobApplication';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { createMarkup } from '@utils/dataUtils';
import { notifyFailed } from '@utils/notifyUtils';
import { Button, Col, Divider, Row, Space } from 'antd';
import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

export interface INFConfirmData {
  firstName: string;
  lastName: string;
  phoneNo: string;
  address: string;
  userId: number;
}

interface State {
  loading: boolean;
}

type Props = {
  jobDetail: JobOfferGetDetailById.Response;
  infConfirmData: INFConfirmData;
  onBack: () => void;
};
export default function CardConfirmation(props: Props) {
  const { jobDetail, infConfirmData, onBack } = props;

  const history = useHistory();

  const [state, setState] = useState<State>({
    loading: false,
  });

  const jobItems: LabelValue[] = [
    // { label: 'บริษัท', value: jobDetail.info.companyName },
    { label: 'ขอบเขตงาน', value: jobDetail.info.reviewPackageName },
    {
      label: 'กิจกรรม/รีวิว',
      value: (
        <Space>
          {jobDetail?.job.reviewSmcCodes.split(',').map((e) => {
            return <ISocialIcon key={e} network={e} width={24} height={24} />;
          })}
        </Space>
      ),
    },
    { label: 'เพศ', value: jobDetail.info.genderName },
    { label: 'อายุ', value: jobDetail.info.ageRangeName },
  ];
  const userItems: LabelValue[] = [
    {
      label: 'ชื่อ-สกุล',
      value: `${infConfirmData.firstName} ${infConfirmData.lastName}`,
    },
    { label: 'เบอร์โทร', value: infConfirmData.phoneNo },
    {
      label:
        'ที่อยู่ผู้สมัครร่วมกิจกรรม/ ที่อยู่สำหรับจัดส่งสินค้า (สำหรับงานรีวิว)',
      value: infConfirmData.address,
    },
  ];

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function goToSuccessPage(res: JobApplicationCreate.Response) {
    const locationState: JobInfluencerRegisterSuccessPageLocationState = {
      jobApplication: res.data,
    };

    history.push({
      pathname: generatePath(ROUTE.JOB_INFLUENCER_REGISTER_SUCCESS, {
        id: jobDetail.job.id,
      }),
      state: locationState,
    });
  }

  async function onFinish() {
    try {
      showLoading();
      await influencerAPI.updateAddress({
        userId: infConfirmData.userId,
        address: infConfirmData.address,
      });

      const res = await jobApplicationApi.create({
        jobOfferId: jobDetail.job.id,
        userId: infConfirmData.userId,
      });

      if (res.data) {
        goToSuccessPage(res);
      } else {
        notifyFailed();
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  return (
    <Content.Wrapper>
      <Content.ImageWrapper>
        <img
          src={jobDetail.info.productImageURL}
          alt='product'
          style={{ objectFit: 'contain' }}
        />
      </Content.ImageWrapper>

      <Content.Section>
        <Content.Title>{jobDetail.job.name}</Content.Title>
        <Content.Description>
          <div
            dangerouslySetInnerHTML={createMarkup(
              jobDetail.job.description ?? '</>'
            )}
          />
        </Content.Description>
      </Content.Section>

      <Content.SectionLabelValue>
        {jobItems.map((e) => (
          <div key={e.label}>
            <Content.Label>{e.label}</Content.Label>
            <Content.Value>{e.value}</Content.Value>
          </div>
        ))}
      </Content.SectionLabelValue>

      <Divider style={{ margin: '8px' }} />

      <Content.SectionLabelValue>
        {userItems.map((e) => (
          <div key={e.label}>
            <Content.Label>{e.label}</Content.Label>
            <Content.Value>{e.value}</Content.Value>
          </div>
        ))}
      </Content.SectionLabelValue>

      <Content.ButtonGroup>
        <Row gutter={12}>
          <Col span={8}>
            <Button
              block
              size='large'
              shape='round'
              onClick={onBack}
              disabled={state.loading}
            >
              ย้อนกลับ
            </Button>
          </Col>
          <Col span={16}>
            <Button
              block
              type='primary'
              size='large'
              shape='round'
              onClick={onFinish}
              loading={state.loading}
            >
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </Content.ButtonGroup>
    </Content.Wrapper>
  );
}

const Content = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 100%;

    background: white;
    border-radius: 24px;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    height: 12rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  `,
  Section: styled.div``,
  Title: styled.div`
    font-weight: 700;
    font-size: 16px;
  `,
  Description: styled.div`
    font-weight: 400;
    font-size: 12px;

    color: black;
  `,
  SectionLabelValue: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Label: styled.div`
    color: var(--gray-color-label);
    font-size: 12px;
  `,
  Value: styled.div``,
  ButtonGroup: styled.div`
    width: 100%;
    margin-top: 12px;
  `,
};
