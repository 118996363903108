import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { authApi } from '@services/apis';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils, notifyUtils } from '@utils/index';
import { Button, Col, Drawer, Form, Input, Result, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface FormType {
  phoneNo: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

const formSchema = {
  phoneNo: { name: 'phoneNo', label: 'เบอร์โทรศัพท์' },
  email: { name: 'email', label: 'อีเมล', type: 'email' },
  firstName: { name: 'firstName', label: 'ชื่อ' },
  lastName: { name: 'lastName', label: 'นามสกุล' },
  companyName: { name: 'companyName', label: 'ชื่อบริษัท' },
};

type Props = {
  phoneNo: string;
  onRegisterEMPSuccess: (phoneNo: string) => void;
};
export default function NotFoundEMPCardAndAddDrawer(props: Props) {
  const { phoneNo, onRegisterEMPSuccess } = props;

  const { isDesktop } = useResponsiveContext();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const showDrawer = () => setOpenDrawer(true);
  const hideDrawer = () => setOpenDrawer(false);

  async function onFinish(values: FormType) {
    try {
      setLoading(true);
      const res = await authApi.registerEmployer({ ...values });
      if (res.status) {
        onRegisterEMPSuccess(values.phoneNo);
        hideDrawer();
      } else {
        notifyUtils.notifyFailed();
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    form.setFieldValue(formSchema.phoneNo.name, phoneNo);
  }, [phoneNo]);

  return (
    <Wrapper>
      <Drawer
        title='แบบฟอร์มลงทะเบียนผู้ประกอบการ'
        width={isDesktop ? '720px' : '100%'}
        onClose={hideDrawer}
        open={openDrawer}
      >
        <Form
          scrollToFirstError
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={16} justify='space-between'>
            <Col span={24}>
              <Form.Item {...formSchema.phoneNo} rules={formRuleUtils.PHONE_NO}>
                <Input disabled placeholder={formSchema.phoneNo.label} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item {...formSchema.email} rules={formRuleUtils.EMAIL}>
                <Input placeholder={formSchema.email.label} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                {...formSchema.firstName}
                rules={formRuleUtils.REQUIRE}
              >
                <Input placeholder={formSchema.email.label} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item {...formSchema.lastName} rules={formRuleUtils.REQUIRE}>
                <Input placeholder={formSchema.lastName.label} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                {...formSchema.companyName}
                rules={formRuleUtils.REQUIRE}
              >
                <Input placeholder={formSchema.companyName.label} />
              </Form.Item>
            </Col>
          </Row>

          <ButtonSheetStyle.Wrapper>
            <Button
              block
              type='primary'
              size='large'
              htmlType='submit'
              loading={loading}
            >
              ยืนยัน
            </Button>
          </ButtonSheetStyle.Wrapper>
        </Form>
      </Drawer>

      <Result
        status='error'
        title='ไม่พบข้อมูล'
        subTitle='โปรดตรวจสอบเบอร์โทรศัพท์อีกครั้ง!'
        extra={[
          <Button type='primary' key='add-employer' onClick={showDrawer}>
            เพิ่มผู้ประกอบการด้วยหมายเลขนี้
          </Button>,
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 24px;
  margin-top: 16px;
`;

const ButtonSheetStyle = {
  Wrapper: styled.div`
    height: 70px;
    padding: 16px;

    position: fixed;
    bottom: 0;
    margin-left: -32px;
    width: -webkit-fill-available;
    border-radius: 24px 24px 0 0;

    button {
      border-radius: 24px;
    }
  `,
};
