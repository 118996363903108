import ISocialIcon from '@components/icon/ISocialIcon';
import { newTab } from '@utils/browserUtils';
import { kFormatter } from '@utils/dataUtils';
import { Col, Space } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

type ItemType = {
  network: string;
  value: number;
  url: string;
};

const enum NETWORK {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

type Props = {
  facebook: string;
  facebookFollower: number;
  instagram: string;
  instagramFollower: number;
  tiktok: string;
  tiktokFollower: number;
  twitter: string;
  twitterFollower: number;

  selected?: boolean;
};
export default function SectionSocial(props: Props) {
  const {
    facebook,
    facebookFollower,
    instagram,
    instagramFollower,
    tiktok,
    tiktokFollower,
    twitter,
    twitterFollower,
    selected = false,
  } = props;

  const items: ItemType[] = [
    {
      network: NETWORK.FACEBOOK,
      value: facebookFollower,
      url: facebook,
    },
    {
      network: NETWORK.INSTAGRAM,
      value: instagramFollower,
      url: instagram,
    },
    {
      network: NETWORK.TIKTOK,
      value: tiktokFollower,
      url: tiktok,
    },
    {
      network: NETWORK.TWITTER,
      value: twitterFollower,
      url: twitter,
    },
    {
      network: NETWORK.YOUTUBE,
      value: twitterFollower,
      url: twitter,
    },
  ];

  function goToSocialWebsite(item: ItemType) {
    newTab(item.url);
  }

  return (
    // <Row gutter={[12, 16]} style={{ marginTop: '12px' }}>
    //   {items.map((e) => (
    //     <Col span={4.8} key={e.network} style={{ textAlign: 'center' }}>
    //       <Space direction='vertical' onClick={() => goToSocialWebsite(e)}>
    //         <ISocialIcon
    //           network={e.network}
    //           disabled={e.value === 0 || e.value === null}
    //           selected={selected}
    //         />
    //         <ValueStyle
    //           disabled={e.value === 0 || e.value === null}
    //           selected={selected}
    //         >
    //           {kFormatter(e.value)}
    //         </ValueStyle>
    //       </Space>
    //     </Col>
    //   ))}
    // </Row>

    <Wrapper>
      {items.map((e) => (
        <Col span={4.8} key={e.network} style={{ textAlign: 'center' }}>
          <Space direction='vertical' onClick={() => goToSocialWebsite(e)}>
            <ISocialIcon
              network={e.network}
              disabled={e.url === null}
              selected={selected}
            />
            <ValueStyle
              disabled={e.value === 0 || e.value === null}
              selected={selected}
            >
              {kFormatter(e.value)}
            </ValueStyle>
          </Space>
        </Col>
      ))}
    </Wrapper>
  );
}

const ValueStyle = styled.div<{ disabled: boolean; selected: boolean }>`
  ${(props) =>
    props.disabled
      ? css`
          color: var(--gray-color-label);
        `
      : props.selected &&
        css`
          color: white;
        `}
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;
