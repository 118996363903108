import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import DataView from '@pages/job/[id]/employer/review-upload/components/DataView';
import { jobApplicationApi } from '@services/apis';
import { JobApplicationGet } from '@services/models/jobApplication';
import { failedToFetchAPI } from '@utils/apiUtils';
import React, { useEffect, useState } from 'react';

interface State {
  loading: boolean;
  resp?: JobApplicationGet.Response;
}

type Props = {
  jobId: number;
};
export default function TabAccept(props: Props) {
  const { jobId } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(
    req: JobApplicationGet.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoading();
      const res = await jobApplicationApi.employerGet(jobId, {
        ...req,
        reviewer: true,
        stage: JOB_APPLICATION_STAGE.ACCEPT,
      });
      if (loadMoreMode) {
        setState((prev) => {
          res.data = [...(prev.resp?.data ?? []), ...res.data];
          return { ...prev, resp: res };
        });
      } else {
        setState((prev) => ({ ...prev, resp: res }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <DataView
      showViewUploadButton
      loading={state.loading}
      datasource={state.resp?.data}
      pagination={state.resp?.page}
      onLoadMore={onLoadMore}
    />
  );
}
