import CardContent from '@components/card/CardContent';
import { ROUTE } from '@constants/routes';
import { jobOfferApi } from '@services/apis';
import {
  genAnnouncementLink,
  genEMPLink,
  genINFRegisterLink,
  genINFUploadLink,
} from '@utils/actionUtils';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Button, Descriptions, Space, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

interface State {
  loadingCanApply: boolean;
  canApply: boolean;
}

type Props = {
  loading: boolean;
  jobId: number;
  canApply: boolean;
};
export default function SectionControl(props: Props) {
  const { loading, jobId, canApply } = props;
  const history = useHistory();

  const [state, setState] = useState<State>({
    loadingCanApply: false,
    canApply: canApply,
  });

  const showLoadingCanApply = () =>
    setState((prev) => ({ ...prev, loadingCanApply: true }));
  const hideLoadingCanApply = () =>
    setState((prev) => ({ ...prev, loadingCanApply: false }));

  const goToApproveUpload = () => {
    history.push(
      generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_APPROVE_UPLOADS, {
        id: jobId,
      })
    );
  };

  const goToSelectCandidate = () => {
    history.push(
      generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_SELECT_CANDIDATES, {
        id: jobId,
      })
    );
  };

  async function onChangeCanApply(value: boolean) {
    try {
      showLoadingCanApply();
      await jobOfferApi.changeCanApply({
        id: jobId,
        canApply: value,
      });
      setState((prev) => ({ ...prev, canApply: value }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingCanApply();
    }
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, canApply: canApply }));
  }, [canApply]);

  return (
    <CardContent>
      <Spin spinning={loading}>
        <h2 style={{ marginBottom: '16px' }}>การควบคุม</h2>
        <Descriptions bordered column={1}>
          <Descriptions.Item label='ดำเนินการ'>
            <Space wrap size={[8, 16]}>
              <Button onClick={goToSelectCandidate}>เลือกผู้สมัคร</Button>
              <Button onClick={goToApproveUpload}>ตรวจงานรีวิว</Button>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label='สร้างลิงค์'>
            <Space wrap size={[8, 16]}>
              <Button onClick={() => genINFRegisterLink(jobId)}>สมัคร</Button>
              <Button onClick={() => genINFUploadLink(jobId)}>อัพโหลด</Button>
              <Button onClick={() => genAnnouncementLink(jobId)}>ประกาศ</Button>
              <Button onClick={() => genEMPLink(jobId)}>
                สำหรับผู้ประกอบการ
              </Button>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label='รับสมัคร'>
            <Switch
              checked={state.canApply}
              onChange={onChangeCanApply}
              loading={state.loadingCanApply}
            />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </CardContent>
  );
}
