import { REVIEW_SOCIAL_MEDIA_CHANNEL } from '@constants/enum';
import { SVG } from '@constants/resource';
import React, { CSSProperties } from 'react';
import { SocialIcon } from 'react-social-icons';

type Props = {
  network: 'facebook' | 'instagram' | 'tiktok' | 'twitter' | 'line' | string;
  url?: string;
  style?: CSSProperties;
  width?: number;
  height?: number;
  disabled?: boolean;
  selected?: boolean;
};
export default function ISocialIcon(props: Props) {
  const {
    network,
    url,
    width = 28,
    height = 28,
    style,
    disabled,
    selected = false,
  } = props;

  let compatNetwork = network;

  switch (network) {
    case REVIEW_SOCIAL_MEDIA_CHANNEL.FACEBOOK:
      compatNetwork = 'facebook';
      break;
    case REVIEW_SOCIAL_MEDIA_CHANNEL.INSTAGRAM:
      compatNetwork = 'instagram';
      break;
    case REVIEW_SOCIAL_MEDIA_CHANNEL.TWITTER:
      compatNetwork = 'twitter';
      break;
    case REVIEW_SOCIAL_MEDIA_CHANNEL.TIKTOK:
      compatNetwork = 'tiktok';
      break;
    case REVIEW_SOCIAL_MEDIA_CHANNEL.YOUTUBE:
      compatNetwork = 'youtube';
      break;
  }

  if (network === 'line') {
    return <img src={SVG.ICON_LINE} alt='line-messenger' width={width} />;
  }

  return (
    <SocialIcon
      network={compatNetwork}
      url={url}
      style={{ width, height, ...style }}
      bgColor={disabled ? 'var(--gray-color-label)' : undefined}
      fgColor={selected ? 'white' : undefined}
    />
  );
}
