import LayoutPublic from '@components/layout/LayoutPublic';
import JobApplicationStageTag from '@components/stage/TabApplicationState';
import JobOfferStageTag from '@components/stage/TagJobState';
import { jobOfferApi } from '@services/apis';
import {
  JobOfferAnnouncement,
  JobOfferDetail,
} from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { createMarkup } from '@utils/dataUtils';
import { Col, Row, Skeleton, Table } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface TableDataSchema {
  item: JobOfferAnnouncement.ReviewerInfoList;
  order: number;
  fullName: string;
  trackingNo: string;
  stage: string;
}

export default function AnnouncementPage() {
  const { id }: { id: string } = useParams(); // jobOfferId

  const [jobOfferDetail, setJobOfferDetail] = useState<JobOfferDetail.Data>();
  const [dataSource, setDatasource] = useState<TableDataSchema[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'ขื่อ-นามสกุล',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'เลขพัสดุ',
      dataIndex: 'trackingNo',
      key: 'trackingNo',
    },
    {
      title: 'สถานะ',
      dataIndex: 'stage',
      key: 'stage',
      render: (_value: string, record: TableDataSchema) =>
        renderJobApplicationStage(record),
    },
  ];

  function renderJobApplicationStage(record: TableDataSchema) {
    const stage = record.item.jobApplication.stage;
    const reviseCount = record.item.jobApplication.reviseCount;
    return JobApplicationStageTag(stage, reviseCount);
  }

  async function fetchJobOfferDetail(jobOfferId: number) {
    try {
      const response = await jobOfferApi.detail(jobOfferId);
      setJobOfferDetail(response.data);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchAnnouncement() {
    try {
      setLoading(true);
      let count = 0;
      const response = await jobOfferApi.announcement(+id);
      const rows = response.info.reviewerInfoList.map((item) => {
        const row: TableDataSchema = {
          item: item,
          order: ++count,
          fullName: item.influencer.firstName + ' ' + item.influencer.lastName,
          trackingNo: item.jobApplication.trackingNo,
          stage: item.jobApplication.stage,
        };
        return row;
      });
      setDatasource(rows);
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchJobOfferDetail(+id);
    fetchAnnouncement();
  }, []);

  return (
    <LayoutPublic>
      <Row justify='center'>
        <Col xs={24} md={16} lg={14}>
          <CardStyle>
            <h1 style={{ fontSize: '28px', height: 44 }}>
              {jobOfferDetail?.datum.name}
            </h1>
            <hr />
            <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={24} md={16} lg={16}>
                {jobOfferDetail?.info.productPublicUrl && (
                  <img
                    src={jobOfferDetail?.info.productPublicUrl}
                    style={{ borderRadius: '10px', objectFit: 'contain' }}
                    alt='JobImage'
                    width='100%'
                  />
                )}
              </Col>
              <Col xs={24} md={16} lg={8}>
                {loading ? (
                  <Skeleton active={loading} />
                ) : (
                  <div>
                    <p style={{ fontSize: '16px' }}>
                      <span style={{ fontWeight: 'bold' }}>เพศ: </span>
                      {jobOfferDetail?.info.genderName}
                    </p>
                    <p style={{ fontSize: '16px' }}>
                      <span style={{ fontWeight: 'bold' }}>ช่วงอายุ: </span>
                      {jobOfferDetail?.info.ageRangeName}
                    </p>
                    <p style={{ fontSize: '16px' }}>
                      <span style={{ fontWeight: 'bold' }}>คีย์เวิร์ด: </span>
                      {jobOfferDetail?.datum.reviewKeyword}
                    </p>
                    <p style={{ fontSize: '16px' }}>
                      <span style={{ fontWeight: 'bold' }}>สถานะ:</span>{' '}
                      {jobOfferDetail &&
                        JobOfferStageTag(jobOfferDetail.datum.stage)}
                    </p>
                  </div>
                )}
              </Col>
            </Row>

            <div style={{ margin: '16px 0px' }}>
              <h3>รายละเอียดงาน</h3>
              {jobOfferDetail?.datum.description ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    jobOfferDetail?.datum.description ?? '</>'
                  )}
                />
              ) : (
                '-'
              )}
            </div>

            {/* <Row>
              <Col span={24}>
                <Collapse defaultActiveKey={['0']}>
                  <Panel header='รายละเอียดเพิ่มเติม' key='1'>
                    {loading ? (
                      <Skeleton active={loading} />
                    ) : (
                      <p style={{ fontSize: '16px' }}>
                        <span style={{ fontWeight: 'bold' }}>
                          รายละเอียดงาน :
                        </span>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            jobOfferDetail?.datum.description ?? '</>'
                          )}
                        />
                      </p>
                    )}
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}
          </CardStyle>

          <CardStyle>
            <h1>ประกาศผู้ที่ผ่านการคัดเลือก</h1>
            <span>(เลื่อนสไลด์ด้านขวา)</span>
            <Table
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              style={{ overflow: 'auto' }}
              pagination={{ pageSize: 10000 }}
            />
          </CardStyle>
        </Col>
      </Row>
    </LayoutPublic>
  );
}

const CardStyle = styled.div`
  margin: 1rem auto;
  padding: 2rem;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: white;
`;
