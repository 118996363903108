import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  InfluencerCreate,
  InfluencerGet,
  InfluencerGetBasicInfo,
  InfluencerGetCompCards,
  InfluencerGetProfileImage,
  InfluencerGetVideoLinks,
  InfluencerUpdateAddress,
} from '@services/models/influencer';
import { generateQueryString } from '@utils/apiUtils';
import { generatePath } from 'react-router-dom';

export async function create(req: FormData) {
  const res = await api.post<InfluencerCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCERS_BASE_URL}`,
    req,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return res.data;
}

export async function getBasicInfoByUserId(userId: number) {
  const res = await api.get<InfluencerGetBasicInfo.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.INFLUENCERS_GET_BASIC_INFO_BY_USER_ID,
      {
        userId,
      }
    )}`
  );
  return res.data;
}

export async function getCompCardsByUserId(userId: number) {
  const res = await api.get<InfluencerGetCompCards.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.INFLUENCERS_GET_COMP_CARDS_BY_USER_ID,
      {
        userId,
      }
    )}`
  );
  return res.data;
}

export async function getVideoLinksByUserId(userId: number) {
  const res = await api.get<InfluencerGetVideoLinks.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.INFLUENCERS_GET_VIDEO_LINKS_BY_USER_ID,
      {
        userId,
      }
    )}`
  );
  return res.data;
}

export async function getProfileImageByUserId(userId: number) {
  const res = await api.get<InfluencerGetProfileImage.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.INFLUENCERS_GET_PROFILE_IMAGE_USER_ID,
      {
        userId,
      }
    )}`
  );
  return res.data;
}

export async function updateAddress(req: InfluencerUpdateAddress.Request) {
  const res = await api.post<InfluencerUpdateAddress.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCERS_UPDATE_ADDRESS}`,
    req
  );
  return res.data;
}

export async function get(req: InfluencerGet.Request) {
  const res = await api.get<InfluencerGet.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.INFLUENCERS_BASE_URL}?${generateQueryString(
      req
    )}`
  );
  return res.data;
}
