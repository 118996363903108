import { LeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  BreadcrumbProps,
  PageHeader as AntdPageHeader,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title?: React.ReactNode;
  onBack?: () => void;
  extra?: JSX.Element[];
  breadcrumb?: BreadcrumbProps | React.ReactElement<typeof Breadcrumb>;
};

export default function PageHeader(props: Props) {
  const { title, onBack, breadcrumb, extra } = props;

  return (
    <AntdPageHeaderStyle
      className='site-page-header'
      onBack={onBack}
      backIcon={<LeftOutlined color='var(--primary-color)' />}
      title={title}
      breadcrumb={breadcrumb}
      style={{ backgroundColor: 'transparent' }}
      extra={extra}
    />
  );
}

// const Wrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: baseline;
// `;

// const Title = styled.h1`
//   font-size: 32px;
// `;

// const Action = styled.div<{ spacing: number }>`
//   *:not(:last-child) {
//     margin-right: ${(props) => props.spacing + 'px'};
//   }
// `;

const AntdPageHeaderStyle = styled(AntdPageHeader)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  .ant-page-header-heading-title {
    font-size: 32px;
    font-weight: 500;
    height: 50px;
  }
`;
