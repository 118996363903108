import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  extra?: ReactNode[];
};
export default function PagePanelTitle(props: Props) {
  const { title, extra } = props;

  return (
    <Row
      align='middle'
      justify='space-between'
      style={{ marginBottom: '24px' }}
    >
      <Col>
        <Title>{title}</Title>
      </Col>
      <Col>{extra}</Col>
    </Row>
  );
}

const Title = styled.span`
  font-size: 21px;
`;
