import { LOCAL_STORAGE_KEY } from '@constants/constants';
import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

export function failedToFetchAPI(error: unknown) {
  console.error(error);
  if (axios.isAxiosError(error)) {
    const status = error.response?.status;
    message.error(`Error(${status}): ${error.response?.data.message}`);
  } else {
    return message.error(`unexpected error occurred`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dummyRequest(options: any) {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
}

export async function downloadBlob(fileUrl: string) {
  const response = await axios.get<Blob>(fileUrl, {
    responseType: 'blob',
  });
  return response.data;
}

export function getHTTPErroStatus(error: unknown) {
  const err = error as AxiosError;

  // if (err.response) {
  //   console.log(err.response.data);
  //   console.log(err.response.status);
  //   console.log(err.response.headers);
  // }

  return err.response?.status;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateQueryString(obj: any) {
  return queryString.stringify(obj);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseQueryString(search: any) {
  return queryString.parse(search);
}

export function getEmployerTokenHeader() {
  const config: AxiosRequestConfig = {
    headers: {
      'employer-token': localStorage.getItem(LOCAL_STORAGE_KEY.EMPLOYER_TOKEN),
    },
  };
  return config;
}

export function getReviewerTokenHeader() {
  const config: AxiosRequestConfig = {
    headers: {
      'reviewer-token': localStorage.getItem(LOCAL_STORAGE_KEY.REVIEWER_TOKEN),
    },
  };
  return config;
}

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export function serializeFormQuery(obj: any) {
//   const str = [];
//   for (const p in obj) {
//     if (obj[p]) {
//       // eslint-disable-next-line no-prototype-builtins
//       if (obj.hasOwnProperty(p)) {
//         str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
//       }
//     }
//   }
//   return str.join('&');
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export function serializeFormValue(obj: any) {
//   let values = {};
//   for (const p in obj) {
//     if (obj[p]) {
//       // eslint-disable-next-line no-prototype-builtins
//       if (obj.hasOwnProperty(p)) {
//         values = { ...values, [p]: obj[p] };
//       }
//     }
//   }
//   return values;
// }
