import { DATE_FORMAT_DDMMYYY } from '@constants/constants';
import moment from 'moment';

export function utcToDateFormat(milStr: number) {
  return moment.utc(milStr).format('MM/DD/YYYY');
}

export function dateFormat(date: Date, format?: string) {
  format = format ?? DATE_FORMAT_DDMMYYY;
  return moment(date).format(format);
}
