import { Result } from 'antd';
import React from 'react';

// type Item = {
//   name: string;
//   title?: string;
//   subTitle?: string;
// };
type Props = {
  params: string[];
  title?: string;
  subTitle?: string;
};
export default function MissingParams(props: Props) {
  const { params, title, subTitle } = props;

  return (
    <Result
      status='404'
      title={title ?? 'Missing Parameters'}
      subTitle={
        subTitle ?? `Sorry, ${params.toString()} is missing, please check`
      }
    />
  );
}
