import { Token, User } from '@services/models/user';
// import Cookies from 'universal-cookie'

// const cookies = new Cookies()

// export function setCookie(userSerial: string) {
//     return cookies.set('userSerial', userSerial, { path: '/' })
// }

// export function getLoginUser()?.serial {
//     return cookies.get('userSerial')
// }

// export function removeCookie() {
//     return cookies.remove('userSerial')
// }

export function logint() {
  // TODO
}

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

export function isLogedIn() {
  return getLogedInUser() != null;
}

export function getUserToken() {
  const token = localStorage.getItem('token');
  if (token) {
    return JSON.parse(token) as Token;
  } else {
    return null;
  }
}

export function getLogedInUser() {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user) as User;
  } else {
    return null;
  }
}
