import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import SectioJobDetail from '@components/section/SectionJobDetail';
import TabApprove from '@components/tabs/TabApprove';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import TabPassed from '@pages/job/[id]/employer/review-upload/components/TabAccept';
import TabAll from '@pages/job/[id]/employer/review-upload/components/TabAll';
import TabInReview from '@pages/job/[id]/employer/review-upload/components/TabInReview';
import TabRevise from '@pages/job/[id]/employer/review-upload/components/TabRevise';
import TabUpload from '@pages/job/[id]/employer/review-upload/components/TabUpload';
import { jobApplicationApi } from '@services/apis';
import { failedToFetchAPI } from '@utils/apiUtils';
import { BackTop, Button, Col, notification, Row, Tabs } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
export type CommentType = { id: number; comment: string };

interface State {
  loadingSubmit: boolean;
  totalPrePass?: number;
  totalInReview?: number;
  totalUpload?: number;
  totalRevise?: number;
  totalAccept?: number;
  total?: number;
  commentList: CommentType[];
  passIdList: number[];
  failIdList: number[];
  flagSubmitCount: number;
}
export default function JobEmployerReviewUpload() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    loadingSubmit: false,
    flagSubmitCount: 0,
    commentList: [],
    passIdList: [],
    failIdList: [],
  });

  const showLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));
  const increaseFalgSubmitCount = () =>
    setState((prev) => ({
      ...prev,
      flagSubmitCount: prev.flagSubmitCount + 1,
    }));

  const items = [
    {
      label: <>รอตรวจ ({state.totalPrePass ?? 0})</>,
      key: 'item-1',
      children: (
        <TabApprove
          adminLayout={false}
          apiMode='employer'
          stage={JOB_APPLICATION_STAGE.PRE_PASS}
          jobId={+id}
          passIdList={state.passIdList}
          failIdList={state.failIdList}
          onPass={onPass}
          onDepass={onDepass}
          onFail={onFail}
          onDefail={onDefail}
        />
      ),
    },
    {
      label: <>รออัพโหลด ({state.totalInReview ?? 0})</>,
      key: 'item-2',
      children: <TabInReview jobId={+id} />,
    },
    {
      label: <>รอตรวจสอบ ({state.totalUpload ?? 0})</>,
      key: 'item-3',
      children: <TabUpload jobId={+id} />,
    },
    {
      label: <>กำลังแก้ไข ({state.totalRevise ?? 0})</>,
      key: 'item-4',
      children: <TabRevise jobId={+id} />,
    },
    {
      label: <>ผ่าน ({state.totalAccept ?? 0})</>,
      key: 'item-5',
      children: <TabPassed jobId={+id} />,
    },
    {
      label: <>ทั้งหมด ({state.total ?? 0})</>,
      key: 'item-6',
      children: <TabAll jobId={+id} />,
    },
  ];

  function onPass(id: number) {
    setState((prev) => ({
      ...prev,
      passIdList: [...prev.passIdList, id],
    }));
  }

  function onDepass(id: number) {
    const newList = state.passIdList.filter((e) => e !== id);
    setState((prev) => ({
      ...prev,
      passIdList: newList,
    }));
  }

  function onDefail(id: number) {
    const failIdList = state.failIdList.filter((e) => e !== id);
    const commentList = _.filter(state.commentList, (e) => e.id !== id);
    setState((prev) => ({
      ...prev,
      failIdList,
      commentList,
    }));
  }

  function onFail(id: number, comment: string) {
    setState((prev) => {
      const tmpFailList = _.filter(prev.commentList, (e) => e.id !== id);
      tmpFailList.push({ id, comment });
      return {
        ...prev,
        commentList: tmpFailList,
        failIdList: [...prev.failIdList, id],
      };
    });
  }

  async function fetchTabs() {
    try {
      showLoadingSubmit();
      const res = await jobApplicationApi.employerGetTabsReviewUpload(+id);

      let [
        totalPrePass,
        totalInReview,
        totalUpload,
        totalRevise,
        totalAccept,
        total,
      ] = [0, 0, 0, 0, 0, 0];

      res.data.forEach((e) => {
        switch (e.tab) {
          case JOB_APPLICATION_STAGE.PRE_PASS:
            totalPrePass = e.total;
            total += e.total;
            break;
          case JOB_APPLICATION_STAGE.IN_REVIEW:
            totalInReview = e.total;
            total += e.total;
            break;
          case JOB_APPLICATION_STAGE.UPLOAD:
            totalUpload = e.total;
            total += e.total;
            break;
          case JOB_APPLICATION_STAGE.REVISE:
            totalRevise = e.total;
            total += e.total;
            break;
          case JOB_APPLICATION_STAGE.ACCEPT:
            totalAccept = e.total;
            break;
          case 'total':
            total = e.total;
            break;
        }
      });

      setState((prev) => ({
        ...prev,
        totalPrePass,
        totalInReview,
        totalUpload,
        totalRevise,
        totalAccept,
        total,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }

  async function onSubmit() {
    try {
      showLoadingSubmit();
      await jobApplicationApi.employerSubmit(+id, {
        passIdList: state.passIdList,
        failList: state.commentList,
      });
      increaseFalgSubmitCount();
      fetchTabs();
      notification['success']({
        message: 'บันทึกสำเร็จ',
      });
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }
  useEffect(() => {
    fetchTabs();
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Content.Wrapper>
        <SectioJobDetail jobId={+id} />

        <CardContent>
          <Row justify='space-between'>
            <Col>
              <Content.SectionTitle>ผลการรีวิว</Content.SectionTitle>
            </Col>
            {state.commentList.length + state.passIdList.length > 0 && (
              <Col>
                <Button
                  type='primary'
                  shape='round'
                  size='large'
                  loading={state.loadingSubmit}
                  onClick={onSubmit}
                >
                  บันทึก
                </Button>
              </Col>
            )}
          </Row>

          <Tabs key={state.flagSubmitCount} items={items} />
          <BackTop />
        </CardContent>
      </Content.Wrapper>
    </LayoutPublic>
  );
}

const Content = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  SectionTitle: styled.div`
    font-size: 1.4rem;
    margin-bottom: 12px;
  `,
};
