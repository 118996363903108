export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_FOR_INFINITE_SCROLL = 20;
export const DEFAULT_PAGE_SIZE_FOR_ALL = 9999;

export const DATE_FORMAT_DDMMYYY = 'MM/DD/YYYY';
export const DATE_FORMAT_DDMMYYY_HH_mm_ss = 'MM/DD/YYYY  HH:mm:ss';

// REACT_APP_ENV (support values)
export const enum ENV {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export const enum LOCAL_STORAGE_KEY {
  EMPLOYER_TOKEN = 'employer-token',
  REVIEWER_TOKEN = 'reviewer-token',
}

export enum PAGE_CODE {
  HOME = 'HOME',
  DASHBOARD = 'DASHBOARD',
  EXAMPLE = 'EXAMPLE',

  INSTALLMENT = 'INSTALLMENT',

  LOAN = 'LOAN',
  LOAN_LIST = 'LOAN|LOAN-LIST',
  LOAN_HISTORY = 'LOAN|LOAN-HISTORY',

  BACKOFFICE_MANAGE_JOBS = 'BACKOFFICE_MANAGE_JOBS',
  BACKOFFICE_MANAGE_JOBS_SELECT_CANDIDATE = 'BACKOFFICE_MANAGE_JOB_SELECT_CANDIDATE',
  BACKOFFICE_MANAGE_JOBS_APPROVE_UPLOAD = 'BACKOFFICE_MANAGE_JOB_APPROVE_UPLOAD',

  BACKOFFICE_MANAGE_INFLUENCER_LINK = 'BACKOFFICE_MANAGE_INFLUENCER_LINK',
}

export const enum STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const enum MASTER_DATA_CATEGORY {
  GENDER = 'gender',
  AGE_RANGE = 'age_range',
  SOCIAL_MEDIA_CHANNEL = 'social_media_channel',
  PRODUCT_TYPE = 'product_type',
}
