import ISocialIcon from '@components/icon/ISocialIcon';
import LayoutPublic from '@components/layout/LayoutPublic';
import { IMAGE } from '@constants/resource';
import { ROUTE } from '@constants/routes';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { Button, Card, Col, Row, Space } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function HomePage() {
  const history = useHistory();

  const { isMobile } = useResponsiveContext();

  const goToRegisterFormPage = () => {
    history.push(ROUTE.INFLUENCER_REGISTER);
  };

  const items = [
    {
      network: 'facebook',
      url: 'https://web.facebook.com/groups/774379169695984',
      name: 'กลุ่ม Infleucner / ถ่ายแบบ',
    },
    {
      network: 'facebook',
      url: 'https://www.facebook.com/groups/1752390695036303',
      name: 'Influencer Thailand รับรีวิวสินค้ามืออาชีพ ( KOL )',
    },
  ];

  return (
    <LayoutPublic>
      <Row gutter={[16, 16]} justify='center' align='middle'>
        <Col xs={24} sm={16} md={16} lg={16} xl={14}>
          <CardStyle hoverable title='Influencer' bodyStyle={{ padding: '0' }}>
            <ContentStyle.Wrapper>
              <ImgStyle
                isMobile={isMobile}
                effect='blur'
                width='100%'
                height='100%'
                src={IMAGE.COVER_INFLUENCER_CARD}
                style={{ objectFit: 'contain' }}
              />

              <ContentStyle.Section>
                <h3>พบกันได้ที่</h3>

                {items.map((e) => (
                  <Space key={e.name}>
                    <ISocialIcon {...e} />
                    <a href={e.url}>{e.name}</a>
                  </Space>
                ))}

                <Button
                  block
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={goToRegisterFormPage}
                  style={{ marginTop: '8px' }}
                >
                  สมัครสมาชิก
                </Button>
              </ContentStyle.Section>
            </ContentStyle.Wrapper>
          </CardStyle>
        </Col>
      </Row>
    </LayoutPublic>
  );
}

const ImgStyle = styled(LazyLoadImage)<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100vh - 64px - 32px - 280px);
  z-index: 9999;
`;

const ContentStyle = {
  Wrapper: styled.div``,
  Section: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
};

const CardStyle = styled(Card)`
  border-radius: 24px;

  .ant-card-head-title {
    text-align: center;
    font-size: 1.6rem;
  }
`;
// const BackgroundBluryImage = styled.div`
//   /* The image used */
//   background-image: url('/images/cover_inf_card.jpg');

//   /* Add the blur effect */
//   filter: blur(8px);
//   -webkit-filter: blur(8px);

//   /* Full height */
//   height: 100%;

//   /* Center and scale the image nicely */
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   z-index: 1;
// `;
