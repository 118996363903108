import DrawerViewUpload from '@components/drawer/DrawerViewUpload';
import { APIModeType } from '@components/section/SectionComment';
import { Button } from 'antd';
import React, { useState } from 'react';

interface State {
  openDrawerViewUpload: boolean;
}

type Props = {
  apiMode: APIModeType;
  id: number;
  jobId: number;
};
export default function ButttonViewUpload(props: Props) {
  const { apiMode, id, jobId } = props;

  const [state, setState] = useState<State>({
    openDrawerViewUpload: false,
  });

  //TODO impl
  // async function handleOnDownloadFile(jobApplicationId: number) {
  //   try {
  //     setLoadingBtn(true);
  //     const response = await jobProgressApi.downloadFileInfoByJobApplicationId(
  //       jobApplicationId
  //     );
  //     const promiseList = response.data.map((item) =>
  //       downloadBlob(item.publicUrl)
  //     );
  //     const blobList = await Promise.all(promiseList);

  //     const zip = new JSZip();
  //     const imageDir = zip.folder('images');
  //     const videoDir = zip.folder('videos');
  //     blobList.forEach((blob, idx) => {
  //       const filename = response.data[idx].data.filename;
  //       if (isImage(filename)) {
  //         imageDir?.file(response.data[idx].data.filename, blob, {
  //           base64: true,
  //         });
  //       } else if (isVideo(filename)) {
  //         videoDir?.file(response.data[idx].data.filename, blob, {
  //           base64: true,
  //         });
  //       }
  //     });

  //     zip.generateAsync({ type: 'blob' }).then(function (content) {
  //       FileSaver.saveAs(content, `review-of-${jobApplicationId}.zip`);
  //     });
  //   } catch (error) {
  //     failedToFetchAPI(error);
  //   } finally {
  //     setLoadingBtn(false);
  //   }
  // }

  const showDrawerViewUpload = () =>
    setState((prev) => ({ ...prev, openDrawerViewUpload: true }));
  const hideDrawerViewUpload = () =>
    setState((prev) => ({ ...prev, openDrawerViewUpload: false }));

  function onClickViewUpload() {
    showDrawerViewUpload();
  }

  return (
    <>
      <DrawerViewUpload
        title='ผลงาน'
        apiMode={apiMode}
        id={id}
        jobId={jobId}
        open={state.openDrawerViewUpload}
        onClose={hideDrawerViewUpload}
      />
      <Button block type='primary' shape='round' onClick={onClickViewUpload}>
        ผลงาน
      </Button>
    </>
  );
}
