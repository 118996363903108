import { UserOutlined } from '@ant-design/icons';
import CardContent from '@components/card/CardContent';
import { jobApplicationApi } from '@services/apis';
import { JobApplicationGetComments } from '@services/models/jobApplication';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Avatar, Col, Comment, List, Row } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

export type APIModeType = 'admin' | 'employer' | 'reviewer';

interface Data {
  author: string;
  avatar: ReactNode;
  content: JSX.Element;
  datetime: string;
}
interface State {
  loading: boolean;
  data: Data[];
}

type Props = {
  apiMode: APIModeType;
  id: number;
  jobId: number;
};
export default function SectionComment(props: Props) {
  const { apiMode, id, jobId } = props;

  const [state, setState] = useState<State>({
    loading: false,
    data: [],
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  async function fetchComments() {
    try {
      showLoading();
      let resp: JobApplicationGetComments.Response;
      switch (apiMode) {
        case 'admin':
          resp = await jobApplicationApi.getComments(jobId, id);
          break;
        case 'employer':
          resp = await jobApplicationApi.employerGetComments(jobId, id);
          break;
        case 'reviewer':
          resp = await jobApplicationApi.reviewerGetComments(jobId);
          break;
      }

      const data: Data[] = [];

      if (resp.backoffice) {
        data.push({
          author: 'แอดมิน',
          avatar: (
            <Avatar
              size='small'
              icon={<UserOutlined />}
              style={{
                backgroundColor: 'var(--primary-color)',
                verticalAlign: 'middle',
              }}
            />
          ),
          content: <p>{resp.backoffice}</p>,
          datetime: 'ไม่นานมานี้',
        });
      }

      if (resp.employer) {
        data.push({
          author: 'ผู้ประกอบการ',
          avatar: (
            <Avatar
              size='small'
              icon={<UserOutlined />}
              style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
            />
          ),
          content: <p>{resp.employer}</p>,
          datetime: 'ไม่นานมานี้',
        });
      }

      setState((prev) => ({ ...prev, data }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <CardContent
      title={
        <Row justify='space-between' align='bottom'>
          <Col style={{ fontSize: '1.2rem' }}>Comments</Col>
          <Col>{`${state.data.length} replies`}</Col>
        </Row>
      }
      titleStyle={{}}
    >
      <List
        loading={state.loading}
        itemLayout='horizontal'
        dataSource={state.data}
        renderItem={(item) => (
          <li>
            <Comment
              author={item.author}
              avatar={item.avatar}
              content={item.content}
              datetime={item.datetime}
            />
          </li>
        )}
      />
    </CardContent>
  );
}
