import { Button, Result } from 'antd';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
  onReset: () => void;
};
export default function SuccessCard(props: Props) {
  const { onClose, onReset } = props;

  return (
    <Wrapper>
      <Result
        status='success'
        title='สร้างงานรีวิวสำเร็จ'
        // subTitle='Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait.'
        extra={[
          <Button type='primary' key='console' onClick={onClose}>
            ปิดหน้าต่าง
          </Button>,
          <Button key='buy' onClick={onReset}>
            สร้างอีกครั้ง
          </Button>,
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 24px;
`;
