import CardInfluencer from '@components/card/CardInfluencer';
import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import { influencerAPI } from '@services/apis';
import { InfluencerGet } from '@services/models/influencer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Button, Col, Divider, List, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface State {
  loading: boolean;
  resp?: InfluencerGet.Response;
}

type Props = {
  selectedUserIds: number[];
  selectable?: boolean;
  onSelect: (id: number) => void;
  onDeselect: (id: number) => void;
};
export default function TabSelectInfluencer(props: Props) {
  const { selectedUserIds, selectable = true, onSelect, onDeselect } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(req: InfluencerGet.Request, loadMoreMode?: boolean) {
    try {
      showLoading();
      const resp = await influencerAPI.get(req);
      if (loadMoreMode) {
        setState((prev) => {
          resp.data = [...(prev.resp?.data ?? []), ...resp.data];
          return { ...prev, resp: resp };
        });
      } else {
        setState((prev) => ({ ...prev, resp: resp }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <InfiniteScroll
      dataLength={state.resp?.data.length ?? 0}
      next={onLoadMore}
      hasMore={
        state.resp?.page
          ? state.resp.page.pageNumber < state.resp.page.totalPages
          : false
      }
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        loading={state.loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={state.resp?.data}
        renderItem={(item) => (
          <List.Item>
            <CardInfluencer
              key={item.influencer.userId}
              data={{
                ...item.influencer,
                profileImageURL: item.info.profileImageURL,
              }}
              selected={selectedUserIds.includes(item.influencer.userId)}
            >
              <Row gutter={8}>
                <Col span={24}>
                  {selectedUserIds.includes(item.influencer.userId) ? (
                    <Button
                      block
                      shape='round'
                      type='primary'
                      danger
                      onClick={() => onDeselect(item.influencer.userId)}
                    >
                      เลือกใหม่
                    </Button>
                  ) : (
                    <Button
                      block
                      type='primary'
                      shape='round'
                      disabled={!selectable}
                      onClick={() => onSelect(item.influencer.userId)}
                    >
                      เลือก
                    </Button>
                  )}
                </Col>
              </Row>
            </CardInfluencer>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
}
