import FormButtonController from '@components/form/FormButtonController';
import PagePanelTitle from '@components/pages/PagePanelTitle';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';

export interface FormType {
  name: string;
}
const FORM_SCHEMA = {
  name: { name: 'name', label: 'ชื่อลิงค์' },
};

type Props = {
  onSearch: (values: FormType) => void;
  loading: boolean;
};
export default function SearchFilter(props: Props) {
  const { onSearch, loading } = props;

  const [form] = Form.useForm();

  function onFinish(values: FormType) {
    onSearch(values);
  }

  function onClear() {
    form.resetFields();
    onSearch(form.getFieldsValue());
  }

  return (
    <div>
      <PagePanelTitle title='ค้นหา' />

      <Form
        scrollToFirstError
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item {...FORM_SCHEMA.name}>
              <Input placeholder={FORM_SCHEMA.name.label} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label=' '>
              <FormButtonController onClear={onClear} loading={loading} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
