export const enum IMAGE {
  BG_LOGIN = '/images/bg_login.jpg',
  COVER_INFLUENCER_REGISTER = '/images/cover_influencer_register.jpg',
  COVER_INFLUENCER_CARD = '/images/cover_inf_card.jpg',
  COVER_INFLUENCER_UPLOAD = '/images/cover_influencer_upload.jpg',
  LOGO_DOOQ = '/images/dooq.png',
}

export const enum SVG {
  ICON_LINE = '/images/line-messenger.svg',
  LOGO_NEW_DOOQ = '/images/dooq.svg',
}
