import CardApplication from '@components/card/CardApplication';
import { JobApplicationGet } from '@services/models/jobApplication';
import { Page } from '@services/models/page';
import { Button, Col, Divider, List, Row, Skeleton } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  loading?: boolean;
  datasource?: JobApplicationGet.Data[];
  pagination?: Page;
  selectedIdList: number[];
  selectable: boolean;
  adminLayout: boolean;
  onLoadMore: () => void;
  onSelect: (id: number) => void;
  onDeselect: (id: number) => void;
};
export default function DataView(props: Props) {
  const {
    loading = false,
    datasource = [],
    pagination = { pageNumber: 0, pageSize: 0, total: 0, totalPages: 0 },
    selectedIdList,
    selectable,
    adminLayout,
    onLoadMore,
    onSelect,
    onDeselect,
  } = props;

  return (
    <InfiniteScroll
      dataLength={datasource.length}
      next={onLoadMore}
      hasMore={
        pagination ? pagination.pageNumber < pagination.totalPages : false
      }
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        loading={loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={datasource}
        renderItem={(item) => (
          <List.Item>
            <CardApplication
              key={item.jobApplication.id}
              jobApplication={item.jobApplication}
              data={item.info}
              selected={selectedIdList.includes(item.jobApplication.id)}
              adminLayout={adminLayout}
            >
              <Row gutter={8}>
                <Col span={24}>
                  {selectedIdList.includes(item.jobApplication.id) ? (
                    <Button
                      block
                      shape='round'
                      type='primary'
                      danger
                      onClick={() => onDeselect(item.jobApplication.id)}
                    >
                      เลือกใหม่
                    </Button>
                  ) : (
                    <Button
                      block
                      type='primary'
                      shape='round'
                      disabled={!selectable}
                      onClick={() => onSelect(item.jobApplication.id)}
                    >
                      เลือก
                    </Button>
                  )}
                </Col>
              </Row>
            </CardApplication>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
}
