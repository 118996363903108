import LayoutResponsive from '@components/layout/LayoutResponsive';
import SiderMain from '@components/layout/Sider';
import { useLayoutBackofficeContext } from '@contexts/LayoutContext';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { Affix, Drawer, Layout } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import HeaderMain from './Header';

const { Content, Sider } = Layout;

type Props = {
  children?: ReactNode;
  currentTab?: string;
};
function LayoutBackoffice(props: Props) {
  const { children, currentTab } = props;

  const { isDesktop } = useResponsiveContext();

  const { isCollapse, setIsCollapse } = useLayoutBackofficeContext();

  const handleOnMenuChange = () => {
    if (isDesktop) {
      setIsCollapse(false);
    }
  };

  function handleToggleCollapse() {
    setIsCollapse(!isCollapse);
  }

  useEffect(() => {
    if (isDesktop) {
      setIsCollapse(false);
    }
  }, []);

  return (
    <LayoutResponsive>
      <Layout className='layout' style={{ overflow: 'hidden' }}>
        <Layout>
          {isDesktop ? (
            <Affix style={{ zIndex: 100 }}>
              <SiderStyle
                collapsed={isCollapse}
                trigger={null}
                width={220}
                style={{ height: '100vh' }}
              >
                <SiderMain
                  currentTab={currentTab}
                  onMenuChange={handleOnMenuChange}
                />
              </SiderStyle>
            </Affix>
          ) : (
            <Drawer
              closable
              destroyOnClose
              title='Menu'
              placement='left'
              height='100%'
              width='100%'
              open={isCollapse}
              bodyStyle={{ padding: '0', overflow: 'hidden' }}
              onClose={handleToggleCollapse}
            >
              <SiderMain
                currentTab={currentTab}
                onMenuChange={handleOnMenuChange}
              />
            </Drawer>
          )}

          <Layout>
            <Affix>
              <HeaderMain onCollapse={handleToggleCollapse} />
            </Affix>
            <ContentStyle isMobile={isDesktop}>{children}</ContentStyle>
          </Layout>
        </Layout>
      </Layout>
    </LayoutResponsive>
  );
}

const SiderStyle = styled(Sider)`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentStyle = styled(Content)<{ isMobile: boolean }>`
  /* padding: 24px; */
  padding: ${(props) => (props.isMobile ? '16px' : '24px')};
`;
export default LayoutBackoffice;
