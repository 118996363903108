import { PlusOutlined } from '@ant-design/icons';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import PageHeader from '@components/pages/PageHeader';
import PagePanel from '@components/pages/PagePanel';
import { DEFAULT_PAGE_SIZE, PAGE_CODE, STATUS } from '@constants/constants';
import AddJobDrawer from '@pages/backoffice/manages/jobs/components/AddJobDrawer';
import ResponsiveDataView from '@pages/backoffice/manages/jobs/components/ResponsiveDataView';
import SearchFilter, {
  FormType,
} from '@pages/backoffice/manages/jobs/components/SearchFilter';
import { RecordType } from '@pages/backoffice/manages/jobs/components/Table';
import { jobOfferApi } from '@services/apis';
import { ITablePagination } from '@services/models/common';
import { JobOfferList } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { scrollToTop } from '@utils/browserUtils';
import { getTableSorter } from '@utils/dataUtils';
import { Button } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';

export default function BackofficeManagesJobsPage() {
  const PAGE_SIZE = DEFAULT_PAGE_SIZE;

  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [tablePagination, setTablePagination] = useState<ITablePagination>({
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const [loadingData, setLoadingTable] = useState<boolean>(false);

  const [searchReq, setSearchReq] = useState<Partial<JobOfferList.Request>>({
    limit: { pageNumber: 1, pageSize: PAGE_SIZE },
  });

  const [openDrawerAdd, setOpenDrawerAdd] = useState(false);

  const showDrawerAdd = () => setOpenDrawerAdd(true);
  const hideDrawerAdd = () => setOpenDrawerAdd(false);

  const onAddJobDrawerSuccess = () => {
    fetchSearch({
      limit: {
        pageNumber: 1,
        pageSize: tablePagination.pageSize,
      },
    });
    scrollToTop();
  };

  function onSearch(values: FormType) {
    fetchSearch({
      ...values,
      limit: {
        pageNumber: 1,
        pageSize: tablePagination.pageSize,
      },
    });
  }

  function onChangePagination(
    pageNumber: number,
    pageSize: number,
    total: number,
    totalPages: number
  ) {
    setTablePagination({ pageNumber, pageSize, total, totalPages });
    fetchSearch({ ...searchReq, limit: { pageNumber, pageSize } });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChangeSorterColumn(sorter: any) {
    fetchSearch({ ...searchReq, sort: getTableSorter(sorter) });
  }

  function onRefresh() {
    fetchSearch({
      ...searchReq,
      limit: {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    });
  }

  function onLoadMore() {
    fetchSearch(
      {
        ...searchReq,
        limit: {
          pageNumber: tablePagination.pageNumber + 1,
          pageSize: tablePagination.pageSize,
        },
      },
      true
    );
  }

  async function fetchSearch(
    req: Partial<JobOfferList.Request>,
    loadMoreMode?: boolean
  ) {
    try {
      setLoadingTable(true);
      req = {
        ...req,
        status: STATUS.ACTIVE,
      };
      setSearchReq(req);
      const res = await jobOfferApi.listSummary({
        ...req,
        status: STATUS.ACTIVE,
      });

      const rows = res.data?.map((item) => {
        const row: RecordType = {
          key: item.datum.id,
          item: item,
        };
        return row;
      });
      setTablePagination(res.page);

      if (loadMoreMode) {
        setTableData((prev) => [...prev, ...rows]);
      } else {
        setTableData(rows);
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect(() => {
    fetchSearch({ ...searchReq });
  }, []);

  return (
    <LayoutBackoffice currentTab={PAGE_CODE.BACKOFFICE_MANAGE_JOBS}>
      <AddJobDrawer
        open={openDrawerAdd}
        onClose={hideDrawerAdd}
        onSuccess={onAddJobDrawerSuccess}
      />

      <PageHeader
        title='งานรีวิว'
        extra={[
          <Button
            key='add'
            type='primary'
            icon={<PlusOutlined />}
            onClick={showDrawerAdd}
          >
            เพิ่มงานรีวิว
          </Button>,
        ]}
      />

      <PagePanel>
        <SearchFilter onSearch={onSearch} loading={loadingData} />
      </PagePanel>

      <br />
      <ResponsiveDataView
        loading={loadingData}
        dataSource={tableData}
        pagination={tablePagination}
        onLoadMore={onLoadMore}
        onRefresh={onRefresh}
        onChangePagination={onChangePagination}
        onChangeSorterColumn={onChangeSorterColumn}
      />
    </LayoutBackoffice>
  );
}
