import { USER_TYPE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import { InfluencerRegisterPageLocationState } from '@pages/influencer/register';
import { InfluencerRegisterAgreementPageLocationState } from '@pages/influencer/register/agreement';
import { authApi, jobOfferApi, templateAgreementAPI } from '@services/apis';
import { JobOfferDetail } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import { notifyFailed } from '@utils/notifyUtils';
import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export interface FormType {
  phoneNo: string;
}
const FORM_SCHEMA = {
  phoneNo: {
    name: 'phoneNo',
    label: 'เบอร์โทรศัพท์',
    rules: formRuleUtils.PHONE_NO,
  },
};

interface State {
  agreementId?: number;
  userId: number;
  jobOfferDetail?: JobOfferDetail.Data;
  loadingBtn: boolean;
  openModalPhoneNoForm: boolean;
}

type Props = {
  open: boolean;
  jobId: number;
  onClose: () => void;
};
export default function ModalPhoneNoForm(props: Props) {
  const { open, jobId, onClose } = props;

  const history = useHistory();

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loadingBtn: false,
    userId: 0,
    openModalPhoneNoForm: false,
  });

  const showLoadingBtn = () =>
    setState((prev) => ({ ...prev, loadingBtn: true }));
  const hideLoadingBtn = () =>
    setState((prev) => ({ ...prev, loadingBtn: false }));

  async function onFinish(values: FormType) {
    try {
      showLoadingBtn();

      const res = await authApi.checkExists({
        phoneNo: values.phoneNo,
      });

      if (res.status) {
        if (res.data.userType === USER_TYPE.INFLUENCER) {
          const resIsApplicationExist = await jobOfferApi.isApplicationExist({
            jobId: jobId,
            userId: res.data.id,
          });

          if (resIsApplicationExist.exist) {
            notification.error({
              message: 'ท่านได้ลงทะเบียนแล้ว',
              description:
                'เบอร์โทรนี้ได้ทำการลงทะเบียนไว้แล้ว. โปรดตรวจสอบเบอร์โทรอีกครั้ง',
            });
          } else {
            const resAgreement = await templateAgreementAPI.getByUserId(
              res.data.id
            );

            if (resAgreement.data) {
              const targetState: InfluencerRegisterAgreementPageLocationState =
                {
                  userId: res.data.id,
                  jobId: jobId,
                };

              history.push({
                pathname: ROUTE.INFLUENCER_REGISTER_AGREEMENT,
                state: targetState,
              });
            } else {
              setState((prev) => ({
                ...prev,
                userId: res.data.id,
                openModalConfirmAddress: true,
              }));
            }
          }
        } else {
          notifyFailed(
            'Error',
            'Employer ไม่สามารถลงทะเบียนได้. ตรวจสอบเบอร์โทรอีกครั้ง'
          );
        }
      } else {
        const targetState: InfluencerRegisterPageLocationState = {
          jobId: jobId,
          initialPhoneNo: values.phoneNo,
        };
        history.push({
          pathname: ROUTE.INFLUENCER_REGISTER,
          state: targetState,
        });
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingBtn();
    }
  }

  return (
    <ModalStyle
      title='แบบฟอร์มลงทะเบียน'
      open={open}
      onCancel={onClose}
      style={{ borderRadius: '24px' }}
      bodyStyle={{ borderRadius: '24px' }}
      footer={null}
    >
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Form.Item {...FORM_SCHEMA.phoneNo}>
          <Input maxLength={10} placeholder={FORM_SCHEMA.phoneNo.label} />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            loading={state.loadingBtn}
            shape='round'
            size='large'
          >
            ยืนยัน
          </Button>
        </Form.Item>
      </Form>
    </ModalStyle>
  );
}

const ModalStyle = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 24px;
  }
`;
