import { api } from '@core/api/api-client';
import { TemplateAgreementGet } from '@services/models/templateAgreement';
import { generatePath } from 'react-router-dom';

import { ENDPOINT } from '.';

export async function get() {
  const res = await api.get<TemplateAgreementGet.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.TEMPLATE_AGREEMENT_BASE_URL}`
  );
  return res.data;
}

export async function getByUserId(userId: number) {
  const res = await api.get<TemplateAgreementGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.TEMPLATE_AGREEMENT_GET_BY_USER_ID,
      { userId }
    )}`
  );
  return res.data;
}
