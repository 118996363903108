import CardContent from '@components/card/CardContent';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import Loading from '@components/loading/Loading';
import SectioJobDetail from '@components/section/SectionJobDetail';
import TabSelectApplication from '@components/tabs/TabSelectApplication';
import { PAGE_CODE } from '@constants/constants';
import { ROUTE } from '@constants/routes';
import { jobApplicationApi, jobOfferApi } from '@services/apis';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import {
  Affix,
  Breadcrumb,
  Button,
  Col,
  notification,
  Progress,
  Row,
  Space,
} from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface State {
  loadingJobDetail: boolean;
  loadingCandidates: boolean;
  loadingSubmit: boolean;
  loadingSubmitAll: boolean;
  jobDetailResp?: JobOfferGetDetailById.Response;
  selectedIdList: number[];
}

export default function BackofficeManageJobsSelectCandidatesPage() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    loadingJobDetail: false,
    loadingCandidates: true,
    loadingSubmit: false,
    loadingSubmitAll: false,
    selectedIdList: [],
  });

  const showLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: true }));
  const hideLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: false }));
  const showLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));
  const showLoadingSubmitAll = () =>
    setState((prev) => ({ ...prev, loadingSubmitAll: true }));
  const hideLoadingSubmitAll = () =>
    setState((prev) => ({ ...prev, loadingSubmitAll: false }));
  const showLoadingCandidates = () =>
    setState((prev) => ({ ...prev, loadingCandidates: true }));
  const hideLoadingCandidates = () =>
    setState((prev) => ({ ...prev, loadingCandidates: false }));

  function onSelect(id: number) {
    setState((prev) => ({
      ...prev,
      selectedIdList: [...prev.selectedIdList, id],
    }));
  }

  function onDeselect(id: number) {
    const newList = state.selectedIdList.filter((e) => e !== id);
    setState((prev) => ({
      ...prev,
      selectedIdList: newList,
    }));
  }

  async function onSubmit() {
    try {
      showLoadingSubmit();
      await jobApplicationApi.selectCandidates(+id, {
        jobApplicationIdList: state.selectedIdList,
      });
      notification['success']({
        message: 'บันทึกสำเร็จ',
      });
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }

  async function onSubmitAll() {
    try {
      showLoadingSubmitAll();
      await jobApplicationApi.selectAllToBeCandidate(+id);
      fetchCandidates();
      notification['success']({
        message: 'บันทึกทั้งหมดแล้ว',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmitAll();
    }
  }

  async function fetchJobDetail() {
    try {
      showLoadingJobDetail();
      const res = await jobOfferApi.getDetailById(+id);
      setState((prev) => ({ ...prev, jobDetailResp: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingJobDetail();
    }
  }

  async function fetchCandidates() {
    try {
      showLoadingCandidates();
      const res = await jobApplicationApi.getCandidates(+id);
      setState((prev) => ({ ...prev, selectedIdList: res.ids }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingCandidates();
    }
  }

  useEffect(() => {
    fetchJobDetail();
    fetchCandidates();
  }, []);

  return (
    <LayoutBackoffice
      currentTab={PAGE_CODE.BACKOFFICE_MANAGE_JOBS_SELECT_CANDIDATE}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTE.BACKOFFICE_MANAGES_JOBS}>งานรีวิว</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {state.jobDetailResp?.job.name ? (
            <Link
              to={generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL, {
                id: state.jobDetailResp.job.id,
              })}
            >
              {state.jobDetailResp?.job.name}
            </Link>
          ) : (
            <Loading loading />
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>เลือกผู้สมัคร</Breadcrumb.Item>
      </Breadcrumb>

      <Content.Wrapper>
        <SectioJobDetail jobId={+id} hideTitle />

        <CardContent>
          <Content.RowHeader justify='space-between'>
            <Content.RowTitleLeft>เลือกผู้สมัคร</Content.RowTitleLeft>
            <div>
              <Content.RowTitleRight>
                เลือกแล้ว{' '}
                {state.loadingCandidates ? (
                  <Loading loading />
                ) : (
                  state.selectedIdList.length
                )}{' '}
                จาก{' '}
                {state.jobDetailResp?.info.maxApplications ?? (
                  <Loading loading />
                )}
              </Content.RowTitleRight>

              <Space>
                <Button
                  type='primary'
                  danger
                  disabled={state.loadingSubmit}
                  onClick={onSubmitAll}
                  loading={state.loadingSubmitAll}
                >
                  เลือกทั้งหมด
                </Button>
                <Button
                  type='primary'
                  onClick={onSubmit}
                  loading={state.loadingSubmit}
                  disabled={
                    state.selectedIdList.length === 0 || state.loadingSubmitAll
                  }
                >
                  Submit
                </Button>
              </Space>
            </div>
          </Content.RowHeader>

          <Content.RowProgress>
            <Col span={24}>
              <Affix offsetTop={16}>
                <div>
                  <Progress
                    percent={
                      state.jobDetailResp?.info.maxApplications
                        ? (state.selectedIdList.length /
                            state.jobDetailResp.info.maxApplications) *
                          100
                        : 0
                    }
                  />
                </div>
              </Affix>
            </Col>
          </Content.RowProgress>

          <TabSelectApplication
            adminLayout
            apiMode='admin'
            jobId={+id}
            selectedIdList={state.selectedIdList}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        </CardContent>
      </Content.Wrapper>
    </LayoutBackoffice>
  );
}

// const ContentAreaStyle = styled.div`
//   padding: 12px 0;
// `;

const Content = {
  Wrapper: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  RowHeader: styled(Row)`
    font-size: 16px;
    margin-bottom: 8px;
  `,
  RowTitleLeft: styled.div`
    font-size: 1.4rem;
  `,
  RowTitleRight: styled.div`
    text-align: end;
    margin-bottom: 12px;
  `,
  RowProgress: styled(Row)`
    margin-bottom: 16px;
  `,
};
