import * as ENDPOINT from '@services/endpoint';
import {
  AuthBackofficeLogin,
  AuthGetMe as AuthMe,
} from '@services/models/auth';
import {
  UserCheckExists,
  UserRegisterEmployer,
  UserRegisterInfluencer,
} from '@services/models/user';
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';

export async function backoffceLogin(req: AuthBackofficeLogin.Request) {
  const res = await axios.post<AuthBackofficeLogin.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AUTH_BACKOFFICE_LOGIN}`,
    req
  );
  return res.data;
}

export async function checkExists(req: UserCheckExists.Request) {
  const res = await axios.post<UserCheckExists.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AUTH_CHECK_EXISTS}`,
    req
  );
  return res.data;
}

export async function registerEmployer(req: UserRegisterEmployer.Request) {
  const res = await axios.post<UserRegisterEmployer.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AUTH_REGISTER_EMPLOYER}`,
    req
  );
  return res.data;
}

export async function registerInfluencer(req: FormData) {
  const res = await axios.post<UserRegisterInfluencer.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AUTH_REGISTER_INFLUENCER}`,
    req,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return res.data;
}

export async function getMe(refreshToken: string) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  };
  const res = await axios.get<AuthMe.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.AUTH_ME}`,
    config
  );
  return res.data;
}
