/* eslint-disable no-prototype-builtins */
import ButttonViewUpload from '@components/button/ButtonViewUpload';
import CardApplication from '@components/card/CardApplication';
import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import { jobApplicationApi } from '@services/apis';
import { JobApplicationGet } from '@services/models/jobApplication';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Divider, List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface State {
  loading: boolean;
  resp?: JobApplicationGet.Response;
}

type Props = {
  jobId: number;
};
export default function TabAccept(props: Props) {
  const { jobId } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(
    req: JobApplicationGet.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoading();
      const res = await jobApplicationApi.get({
        ...req,
        reviewer: true,
        stage: JOB_APPLICATION_STAGE.ACCEPT,
        jobId,
      });
      if (loadMoreMode) {
        setState((prev) => {
          res.data = [...(prev.resp?.data ?? []), ...res.data];
          return { ...prev, resp: res };
        });
      } else {
        setState((prev) => ({ ...prev, resp: res }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <InfiniteScroll
      dataLength={state.resp?.data?.length ?? 0}
      next={onLoadMore}
      hasMore={
        state.resp?.page
          ? state.resp.page.pageNumber < state.resp.page.totalPages
          : false
      }
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        loading={state.loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={state.resp?.data}
        renderItem={(item) => (
          <List.Item>
            <CardApplication
              adminLayout
              key={item.jobApplication.id}
              jobApplication={item.jobApplication}
              data={item.info}
            >
              <ButttonViewUpload
                apiMode='admin'
                id={item.jobApplication.id}
                jobId={item.jobApplication.jobOfferId}
              />
            </CardApplication>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
}
