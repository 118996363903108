/* eslint-disable @typescript-eslint/no-explicit-any */
import Loading from '@components/loading/Loading';
import InvalidParams from '@components/validate/InvalidParams';
import MissingParams from '@components/validate/MissingParams';
import React, { ReactNode, useEffect, useState } from 'react';

interface State {
  finished: boolean;
  missingParams: string[];
  invalidParams: string[];
}

type Item = {
  name: string;
  value: unknown;
  validateFn: (value: any) => boolean;
};
type Props = {
  params: Item[];
  children?: ReactNode;
};
export default function ValidateParams(props: Props) {
  const { params, children } = props;

  const [state, setState] = useState<State>({
    finished: false,
    missingParams: [],
    invalidParams: [],
  });

  function validateParams() {
    const tmpMissingParams: string[] = [];
    const tmpInvalidParams: string[] = [];
    params.forEach((e) => {
      if (!e.value) {
        tmpMissingParams.push(e.name);
      } else {
        const valid = e.validateFn(e.value);
        if (!valid) {
          tmpInvalidParams.push(e.name);
        }
      }
    });

    setState((prev) => ({
      ...prev,
      missingParams: tmpMissingParams,
      invalidParams: tmpInvalidParams,
      finished: true,
    }));
  }

  useEffect(() => {
    validateParams();
  }, []);

  return (
    <>
      {state.finished ? (
        state.missingParams.length > 0 ? (
          <MissingParams params={state.missingParams} />
        ) : state.invalidParams.length > 0 ? (
          <InvalidParams params={state.missingParams} />
        ) : (
          children
        )
      ) : (
        <Loading loading />
      )}
    </>
  );
}
