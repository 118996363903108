import { Rule } from 'antd/lib/form';

const PHONE_NO: Rule[] = [
  { required: true, message: 'โปรดระบุ${label}!' },
  { len: 10, message: '${label} 10 หลักเท่านั้น' },
  {
    pattern: RegExp(/^[0-9]*$/g),
    message: 'กรอกตัวเลขเท่านั้น',
  },
];

const EMAIL: Rule[] = [
  {
    required: true,
    type: 'email',
    message: 'อีเมลไม่ถูกต้อง!',
  },
];
const REQUIRE: Rule[] = [
  {
    required: true,
    message: 'โปรดระบุ ${label}!',
  },
];

const REQUIRE_UPLOAD: Rule[] = [
  {
    required: true,
    message: 'โปรดอัพโหลด ${label}!',
  },
];

const REQUIRE_URL: Rule[] = [
  { required: true },
  { type: 'url', warningOnly: true },
  { type: 'string', min: 6 },
];

const URL: Rule[] = [
  { type: 'url', warningOnly: true },
  { type: 'string', min: 6 },
];

export { EMAIL, PHONE_NO, REQUIRE, REQUIRE_UPLOAD, REQUIRE_URL, URL };
