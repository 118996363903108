import PermissionDeniedPage from '@pages/403';
import BackofficeManagesInfluencerLinkPage from '@pages/backoffice/manages/influencer-links';
import BackofficeManagesInfluencerLinkAddPage from '@pages/backoffice/manages/influencer-links/add';
import BackofficeManagesInfluencerLinkEditPage from '@pages/backoffice/manages/influencer-links/edit';
import BackofficeManagesJobsPage from '@pages/backoffice/manages/jobs';
import BackofficeManageJobsApproveUploadsPage from '@pages/backoffice/manages/jobs/approve-uploads';
import BackofficeManageJobsDetailPage from '@pages/backoffice/manages/jobs/detail';
import BackofficeManageJobsSelectCandidatesPage from '@pages/backoffice/manages/jobs/select-candidates';
import AnnouncementPage from '@pages/board/announcement';
import HomePage from '@pages/index';
import InfluencerRegisterPage from '@pages/influencer/register';
import InfluencerRegisterAgreementPage from '@pages/influencer/register/agreement';
import InfluencerRegisterSuccessPage from '@pages/influencer/register/success';
import InfluencerLinksPage from '@pages/influencer-links';
import JobEmployerReviewUpload from '@pages/job/[id]/employer/review-upload';
import JobEmployerSelectReviewer from '@pages/job/[id]/employer/select-reviewer';
import JobEmployerVerifyToken from '@pages/job/[id]/employer/verify-token';
import JobEmployerWaitingPage from '@pages/job/[id]/employer/waiting';
// import InfluencerRegisterFormPage from '@pages/InfluencerRegisterForm';
import JobInfluencerHomePage from '@pages/job/[id]/influencer';
import JobInfluencerRegisterPage from '@pages/job/[id]/influencer/register';
import JobInfluencerRegisterConfirmPage from '@pages/job/[id]/influencer/register/confirm';
import JobInfluencerRegisterSuccessPage from '@pages/job/[id]/influencer/register/success';
import JobInfluencerUploadPage from '@pages/job/[id]/influencer/upload';
import JobInfluencerUploadVerifiedReviewerPage from '@pages/job/[id]/influencer/upload/verified-reviewer';
import LoginPage from '@pages/Login';
import React, { ReactNode } from 'react';

export enum ROUTE {
  //------------------------------------------------PUBLIC------------------------------------------------//
  HOME = '/',
  LOGIN = '/login',
  EMPLOYER_FORM = '/employer-form',
  BOARD_ANNOUNCEMENT = '/board/announcement/job/:id',
  INFLUENCER_LINK = '/influencer-link/token/:token',

  //New
  INFLUENCER_REGISTER_AGREEMENT = '/influencer/register/agreement',
  INFLUENCER_REGISTER_SUCCESS = '/influencer/register/success',
  INFLUENCER_REGISTER = '/influencer/register',

  JOB_INFLUENCER_REGISTER_SUCCESS = '/job/:id/influencer/register/success',
  JOB_INFLUENCER_REGISTER_CONFIRM = '/job/:id/influencer/register/confirm',
  JOB_INFLUENCER_REGISTER = '/job/:id/influencer/register',
  JOB_INFLUENCER_UPLOAD_VERIFIED_REVIEWER = '/job/:id/influencer/upload/verified-reviewer',
  JOB_INFLUENCER_UPLOAD = '/job/:id/influencer/upload',
  JOB_INFLUENCER_HOME = '/job/:id/influencer',

  JOB_EMPLOYER_VERIFY_TOKEN = '/job/:id/employer/verify-token',
  JOB_EMPLOYER_WAITING = '/job/:id/employer/waiting',
  JOB_EMPLOYER_SELECT_REVIEWER = '/job/:id/employer/select-reviewer',
  JOB_EMPLOYER_REVIEW_UPLOAD = '/job/:id/employer/review-upload',

  //------------------------------------------------PRIVATE------------------------------------------------//
  BACKOFFICE_MANAGES_JOBS = '/backoffice/manages/jobs',
  BACKOFFICE_MANAGES_JOBS_DETAIL = '/backoffice/manages/jobs/:id/detail',
  BACKOFFICE_MANAGES_JOBS_SELECT_CANDIDATES = '/backoffice/manages/jobs/:id/select-candidates',
  BACKOFFICE_MANAGES_JOBS_APPROVE_UPLOADS = '/backoffice/manages/jobs/:id/approve-uploads',

  BACKOFFICE_MANAGES_INFLUENCER_LINKS_ADD = '/backoffice/manages/influencer-links/add',
  BACKOFFICE_MANAGES_INFLUENCER_LINKS_EDIT = '/backoffice/manages/influencer-links/:id/edit',
  BACKOFFICE_MANAGES_INFLUENCER_LINKS = '/backoffice/manages/influencer-links',

  //NotFound
  ERR_404 = '/not-found',
  ERR_403 = '/permission-denied',
}

type RoutePathComponent = {
  path: string;
  component: ReactNode;
  code?: string;
  exact: boolean;
};

export const PUBLIC_ROUTER: RoutePathComponent[] = [
  {
    path: ROUTE.LOGIN,
    component: <LoginPage />,
    exact: true,
  },
  {
    path: ROUTE.HOME,
    component: <HomePage />,
    exact: true,
  },
  {
    path: ROUTE.BOARD_ANNOUNCEMENT,
    component: <AnnouncementPage />,
    exact: false,
  },
  {
    path: ROUTE.INFLUENCER_LINK,
    component: <InfluencerLinksPage />,
    exact: false,
  },

  //influencer
  {
    path: ROUTE.INFLUENCER_REGISTER_AGREEMENT,
    component: <InfluencerRegisterAgreementPage />,
    exact: false,
  },
  {
    path: ROUTE.INFLUENCER_REGISTER_SUCCESS,
    component: <InfluencerRegisterSuccessPage />,
    exact: false,
  },
  {
    path: ROUTE.INFLUENCER_REGISTER,
    component: <InfluencerRegisterPage />,
    exact: false,
  },
  {
    path: ROUTE.JOB_INFLUENCER_UPLOAD_VERIFIED_REVIEWER,
    component: <JobInfluencerUploadVerifiedReviewerPage />,
    exact: true,
  },
  {
    path: ROUTE.JOB_INFLUENCER_UPLOAD,
    component: <JobInfluencerUploadPage />,
    exact: true,
  },
  {
    path: ROUTE.JOB_INFLUENCER_REGISTER_SUCCESS,
    component: <JobInfluencerRegisterSuccessPage />,
    exact: false,
  },
  {
    path: ROUTE.JOB_INFLUENCER_REGISTER_CONFIRM,
    component: <JobInfluencerRegisterConfirmPage />,
    exact: false,
  },
  {
    path: ROUTE.JOB_INFLUENCER_REGISTER,
    component: <JobInfluencerRegisterPage />,
    exact: false,
  },
  {
    path: ROUTE.JOB_INFLUENCER_HOME,
    component: <JobInfluencerHomePage />,
    exact: true,
  },
  {
    path: ROUTE.JOB_EMPLOYER_VERIFY_TOKEN,
    component: <JobEmployerVerifyToken />,
    exact: false,
  },
  {
    path: ROUTE.JOB_EMPLOYER_WAITING,
    component: <JobEmployerWaitingPage />,
    exact: false,
  },
  {
    path: ROUTE.JOB_EMPLOYER_SELECT_REVIEWER,
    component: <JobEmployerSelectReviewer />,
    exact: false,
  },
  {
    path: ROUTE.JOB_EMPLOYER_REVIEW_UPLOAD,
    component: <JobEmployerReviewUpload />,
    exact: false,
  },
  {
    path: ROUTE.ERR_403,
    component: <PermissionDeniedPage />,
    exact: true,
  },
];

export const PRIVATE_ROUTER: RoutePathComponent[] = [
  {
    path: ROUTE.BACKOFFICE_MANAGES_JOBS,
    component: <BackofficeManagesJobsPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL,
    component: <BackofficeManageJobsDetailPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_JOBS_SELECT_CANDIDATES,
    component: <BackofficeManageJobsSelectCandidatesPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_JOBS_APPROVE_UPLOADS,
    component: <BackofficeManageJobsApproveUploadsPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS_ADD,
    component: <BackofficeManagesInfluencerLinkAddPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS_EDIT,
    component: <BackofficeManagesInfluencerLinkEditPage />,
    exact: true,
  },
  {
    path: ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS,
    component: <BackofficeManagesInfluencerLinkPage />,
    exact: true,
  },
];
