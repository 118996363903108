import { JOB_APPLICATION_STAGE } from '@constants/enum';
import { Tag } from 'antd';
import React from 'react';

export default function renderJobApplicationStage(
  stage: string,
  reviseCount?: number
) {
  if (reviseCount === undefined) {
    reviseCount = 0;
  }

  let tag = <></>;
  switch (stage) {
    case JOB_APPLICATION_STAGE.IN_REVIEW: {
      tag = <Tag color='processing'>กำลังรีวิว</Tag>;
      break;
    }
    case JOB_APPLICATION_STAGE.UPLOAD: {
      tag = <Tag color='warning'>อัพโหลด</Tag>;
      break;
    }
    case JOB_APPLICATION_STAGE.PRE_PASS: {
      tag = <Tag color='orange'>กำลังรอตรวจ</Tag>;
      break;
    }
    case JOB_APPLICATION_STAGE.REVISE: {
      tag = (
        <Tag color='error'>
          กำลังแก้ไข {`${reviseCount > 1 ? '#' + (reviseCount - 1) : ''}`}
        </Tag>
      );
      break;
    }
    case JOB_APPLICATION_STAGE.FAIL: {
      tag = <Tag color='error'>ล้มเหลว</Tag>;
      break;
    }
    case JOB_APPLICATION_STAGE.ACCEPT: {
      tag = <Tag color='success'>ผ่าน</Tag>;
      break;
    }
  }

  return tag;
}
