import { VideoCameraOutlined } from '@ant-design/icons';
import Loading from '@components/loading/Loading';
import SectionSocial from '@components/section/SectionSocial';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { influencerAPI, userAgreementAPI } from '@services/apis';
import { JobApplication } from '@services/domains/jobApplication';
import { InfluencerGetBasicInfo } from '@services/models/influencer';
import { UserAgreementGetByUserId } from '@services/models/userAgreement';
import { failedToFetchAPI } from '@utils/apiUtils';
import { createMarkup, truncate } from '@utils/dataUtils';
import { dateFormat } from '@utils/dateUtils';
import { browserUtils } from '@utils/index';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Image,
  List,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

interface Item {
  title: ReactNode;
  data: {
    label: ReactNode;
    value?: ReactNode;
    requireAdminLayout?: boolean;
    labelSpan?: number;
    valueSpan?: number;
    labelStyle?: CSSProperties;
    valueStyle?: CSSProperties;
  }[];
  requireAdminLayout?: boolean;
}

interface State {
  loaded: boolean;
  profileImageURL?: string;
  infData?: InfluencerGetBasicInfo.ResponseData;
  jobApplication?: JobApplication;
  loadingCompCards: boolean;
  loadingVideoLinks: boolean;
  loadingProfileImage: boolean;
  acceptedAgreements?: UserAgreementGetByUserId.ResponseData[];
  compCardImageURLs: string[];
  compCardVideoURLs: string[];
}

type Props = {
  adminLayout?: boolean;
  jobApplication?: JobApplication;
  userId: number;
  open: boolean;
  onClose: () => void;
};
export default function DrawerCompCard(props: Props) {
  const { userId, open, jobApplication, adminLayout, onClose } = props;

  const { isDesktop } = useResponsiveContext();

  const [state, setState] = useState<State>({
    loaded: false,
    loadingCompCards: false,
    loadingVideoLinks: false,
    loadingProfileImage: false,
    compCardImageURLs: [],
    compCardVideoURLs: [],
  });

  const markLoaded = () => setState((prev) => ({ ...prev, loaded: true }));

  const showLoadingCompCards = () =>
    setState((prev) => ({ ...prev, loadingCompCards: true }));

  const hideLoadingCompCards = () =>
    setState((prev) => ({ ...prev, loadingCompCards: false }));

  const showLoadingVideoLinks = () =>
    setState((prev) => ({ ...prev, loadingVideoLinks: true }));

  const hideLoadingVideoLinks = () =>
    setState((prev) => ({ ...prev, loadingVideoLinks: false }));

  const showLoadingProfileImage = () =>
    setState((prev) => ({ ...prev, loadingProfileImage: true }));

  const hideLoadingProfileImage = () =>
    setState((prev) => ({ ...prev, loadingVideoLinks: false }));

  const items: Item[] = [
    {
      title: 'ข้อมูลของ Infleucner',
      data: [
        {
          label: (
            <ProfileRow>
              {state.profileImageURL ? (
                <Image
                  height={200}
                  width='100%'
                  src={state.profileImageURL}
                  style={{ borderRadius: '16px', objectFit: 'cover' }}
                />
              ) : (
                <Skeleton.Image active />
              )}
            </ProfileRow>
          ),
          labelSpan: 24,
        },
        { label: 'รหัส', value: state.infData && state.infData.userId },
        {
          label: 'ชื่อ-นามสกุล',
          value:
            state.infData &&
            `${state.infData.firstName} ${state.infData.lastName}`,
        },
        {
          label: 'เบอร์โทร',
          value: state.infData && state.infData.phoneNo,
          requireAdminLayout: true,
        },
        {
          label: 'อีเมล',
          value: state.infData && state.infData.email,
          requireAdminLayout: true,
        },
        {
          label: 'Line ID',
          value: (
            <a
              onClick={() => {
                state.infData &&
                  browserUtils.newTab(
                    `http://line.me/ti/p/~${state.infData.lineId}`
                  );
              }}
            >
              {state.infData && state.infData.lineId}
            </a>
          ),
          requireAdminLayout: true,
          labelSpan: 12,
          valueSpan: 12,
          valueStyle: { textAlign: 'right' },
        },
      ],
    },
    // {
    //   title: 'ข้อมูลสำหรับการเงิน',
    //   data: [
    //     { label: 'เลขบัญชี', value: influencer.bankAccountNo },
    //     {
    //       label: 'ชื่อบัญชี',
    //       value: `${influencer.firstName} ${influencer.lastName}`,
    //     },
    //     {
    //       label: 'ธนาคาร',
    //       value: influencer.bankAccountName,
    //     },
    //     {
    //       label: 'อีเมล',
    //       value: influencer.email,
    //     },
    //   ],
    //   requireAdminLayout: true,
    // },
    {
      title:
        'ที่อยู่ผู้สมัครร่วมกิจกรรม/ ที่อยู่สำหรับจัดส่งสินค้า (สำหรับงานรีวิว)',
      data: [{ label: jobApplication?.address }],
      requireAdminLayout: true,
    },
    {
      title: 'ช่องทางการติดต่อ Social',
      data: [
        {
          label: state.infData && <SectionSocial {...state.infData} />,
          labelSpan: 24,
        },
      ],
    },
    {
      title: (
        <Space>
          ข้อตกลง <Loading loading={state.acceptedAgreements ? false : true} />
        </Space>
      ),
      data: [
        {
          label: (
            <List
              size='large'
              dataSource={state.acceptedAgreements ?? []}
              renderItem={(item) => (
                <List.Item
                  key={item.templateAgreement.id}
                  actions={[
                    <a key='datetime' style={{ color: 'black' }}>
                      {dateFormat(item.acceptedAt)}
                    </a>,
                  ]}
                >
                  <Button
                    type='link'
                    style={{ padding: 0 }}
                    onClick={() => showModalAgreementData(item)}
                  >
                    {item.templateAgreement.title}
                  </Button>
                </List.Item>
              )}
            />
          ),
        },
      ],
      requireAdminLayout: true,
    },
  ];

  function showModalAgreementData(data: UserAgreementGetByUserId.ResponseData) {
    Modal.info({
      title: data.templateAgreement.title,
      okButtonProps: { hidden: true },
      width: isDesktop ? '75%' : '100%',
      closable: true,
      content: (
        <div
          dangerouslySetInnerHTML={createMarkup(data.templateAgreement.detail)}
        />
      ),
    });
  }

  async function fetchUserAgreements() {
    try {
      const res = await userAgreementAPI.getByUserId(userId);
      setState((prev) => ({ ...prev, acceptedAgreements: res.data }));
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchInfluencerBasicInfo() {
    try {
      // showLoadingData();
      const res = await influencerAPI.getBasicInfoByUserId(userId);
      if (res.data) {
        setState((prev) => ({ ...prev, infData: res.data }));
      } else {
        message.error(`ไม่พบข้อมูล`);
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      // hideLoadingData();
    }
  }

  async function fetchCompCards() {
    try {
      showLoadingCompCards();
      const res = await influencerAPI.getCompCardsByUserId(userId);
      setState((prev) => ({
        ...prev,
        compCardImageURLs: res.data.imageURLs,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingCompCards();
    }
  }

  async function fetchVideoLinks() {
    try {
      showLoadingVideoLinks();
      const res = await influencerAPI.getVideoLinksByUserId(userId);
      setState((prev) => ({
        ...prev,
        compCardVideoURLs: res.data.videoURLs,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingVideoLinks();
    }
  }

  async function fetchProfile() {
    try {
      showLoadingProfileImage();
      const res = await influencerAPI.getProfileImageByUserId(userId);
      setState((prev) => ({ ...prev, profileImageURL: res.data }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingProfileImage();
    }
  }

  useEffect(() => {
    if (open && !state.loaded) {
      fetchInfluencerBasicInfo();
      fetchCompCards();
      fetchVideoLinks();
      fetchProfile();
      markLoaded();
      fetchUserAgreements();
    }
  }, [open]);

  return (
    <Drawer
      title='Comp Card'
      placement='right'
      width='100%'
      onClose={onClose}
      open={open}
    >
      <Row gutter={[36, 36]}>
        <Col xs={24} sm={24} lg={18}>
          <Row>
            <Col span={24}>
              <Content.ImageSection>
                <Content.Title>รูปภาพ</Content.Title>
                <Content.Divider />
                <Image.PreviewGroup>
                  <List
                    key='images'
                    loading={state.loadingCompCards}
                    grid={{
                      gutter: 16,
                      xs: 2,
                      sm: 3,
                      md: 4,
                      lg: 5,
                      xl: 6,
                      xxl: 8,
                    }}
                    dataSource={state.compCardImageURLs}
                    renderItem={(url) => (
                      <Content.ListItem>
                        <Image
                          key={url}
                          src={url}
                          height='100%'
                          width='100%'
                          style={{ objectFit: 'cover', borderRadius: '16px' }}
                        />
                      </Content.ListItem>
                    )}
                  />
                </Image.PreviewGroup>
              </Content.ImageSection>
            </Col>

            <Col span={24}>
              <Content.VideoSection>
                <Content.Title>วีดีโอ</Content.Title>
                <Content.Divider />
                <List
                  key='videos'
                  loading={state.loadingVideoLinks}
                  dataSource={state.compCardVideoURLs}
                  renderItem={(item, idx) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<VideoCameraOutlined />}
                        title={
                          <a
                            href={item}
                            target='_blank'
                            rel='noopener noreferrer'
                          >{`ลิงค์ที่ ${idx + 1}`}</a>
                        }
                        description={truncate(item, 50)}
                      />
                    </List.Item>
                  )}
                />
              </Content.VideoSection>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} lg={6}>
          <Content.SectionRow>
            <Col span={24}>
              {items.map((e, index) => {
                if (
                  !e.requireAdminLayout ||
                  (e.requireAdminLayout && adminLayout)
                ) {
                  return (
                    <div key={index} style={{ marginBottom: '32px' }}>
                      <Content.Title>{e.title}</Content.Title>
                      <Content.Divider />

                      {e.data.map((k, indexK) => {
                        if (
                          !k.requireAdminLayout ||
                          (k.requireAdminLayout && adminLayout)
                        ) {
                          return (
                            <Content.Label key={indexK}>
                              <Content.Label>{k.label}</Content.Label>
                              <Content.Value>{k.value}</Content.Value>
                            </Content.Label>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </Col>
          </Content.SectionRow>
        </Col>
      </Row>
    </Drawer>
  );
}

const Content = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Container: styled.div``,
  CoverImage: styled(LazyLoadImage)`
    width: 100%;
    height: 200px;
    object-fit: cover;
  `,
  Title: styled.div`
    font-size: 16px;
    font-weight: bold;
  `,
  ListItem: styled(List.Item)`
    height: 140px;
    width: 140px;
  `,
  ImageContainer: styled.div`
    .ant-image {
      width: 100%;
    }
  `,
  ImageStyle: styled(LazyLoadImage)`
    width: 100%;
    height: 200px;
    object-fit: cover;
  `,
  ImageStyle2: styled(Image)`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
  `,

  Divider: styled(Divider)`
    margin: 0px 0px 16px;
  `,
  ImageSection: styled.div`
    margin-bottom: 24px;
  `,
  VideoSection: styled.div``,
  SectionRow: styled(Row)`
    margin-bottom: 20px;
  `,
  Label: styled.div`
    color: var(--gray-color-label);
    font-size: 14px;
  `,
  Value: styled(Typography.Paragraph)``,
  ValueCol: styled(Col)`
    color: grey;
  `,
};

const ProfileRow = styled(Row)`
  .ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-image {
    height: 12rem;
    width: 100%;
    border-radius: 16px;
  }
`;
