import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import { ReviewPackageList } from '@services/models/reviewPackage';

export async function list(options: Partial<ReviewPackageList.Request>) {
  const res = await api.post<ReviewPackageList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.REVIEW_PACKAG_LIST}`,
    options
  );
  return res.data;
}
