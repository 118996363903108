import ISocialIcon from '@components/icon/ISocialIcon';
import { jobOfferApi } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { newTab } from '@utils/browserUtils';
import { createMarkup } from '@utils/dataUtils';
import { Affix, Button, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface State {
  loading: boolean;
  jobDetail?: JobOfferGetDetailById.Response;
}

type Props = {
  jobId: number;
  buttonText: string;
  onClickButton: () => void;
};
export default function CardJob(props: Props) {
  const { jobId, buttonText, onClickButton } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  const items: LabelValue[] = [
    // {
    //   label: 'บริษัท',
    //   value: state?.jobDetail?.info?.companyName,
    // },
    {
      label: 'ขอบเขตงาน',
      value: state?.jobDetail?.info?.reviewPackageName,
    },
    {
      label: 'กิจกรรม/รีวิว',
      value: (
        <Space>
          {state?.jobDetail?.job.reviewSmcCodes.split(',').map((e) => {
            return <ISocialIcon key={e} network={e} width={24} height={24} />;
          })}
        </Space>
      ),
    },
    {
      label: 'เพศ',
      value: state?.jobDetail?.info.genderName,
    },
    {
      label: 'อายุ',
      value: state?.jobDetail?.info?.ageRangeName,
    },
  ];

  async function fetchJobDetail(jobId: number) {
    try {
      showLoading();
      const res = await jobOfferApi.getDetailById(jobId);
      setState((prev) => ({ ...prev, jobDetail: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchJobDetail(jobId);
  }, []);

  return (
    <Content.Wrapper>
      <Content.ImageWrapper>
        {state.jobDetail ? (
          <img
            src={state.jobDetail && state.jobDetail.info.productImageURL}
            alt='product'
            onClick={() => {
              state.jobDetail && newTab(state.jobDetail.info.productImageURL);
            }}
          />
        ) : (
          <Skeleton.Image active />
        )}
      </Content.ImageWrapper>

      <Content.Section>
        <Content.Title>
          {state.jobDetail ? (
            state.jobDetail.job.name
          ) : (
            <Skeleton active paragraph={{ rows: 0 }} />
          )}
        </Content.Title>
        <Content.Description>
          {state.jobDetail ? (
            <div
              dangerouslySetInnerHTML={createMarkup(
                state.jobDetail.job.description
              )}
            />
          ) : (
            <Skeleton
              active
              title={{ width: '100%' }}
              paragraph={{ rows: 1 }}
            />
          )}
        </Content.Description>
      </Content.Section>

      <Content.SectionLabelValue>
        {items.map((e) => (
          <div key={e.label}>
            <Content.Label>{e.label}</Content.Label>
            <Content.Value>
              {e.value ?? (
                <Skeleton
                  active
                  paragraph={{ rows: 0 }}
                  title={{ width: '80%' }}
                />
              )}
            </Content.Value>
          </div>
        ))}
      </Content.SectionLabelValue>

      <Content.ButtonGroup>
        <Affix offsetBottom={16}>
          <Button
            block
            type='primary'
            size='large'
            shape='round'
            onClick={onClickButton}
            disabled={state.loading}
          >
            {buttonText}
          </Button>
        </Affix>
      </Content.ButtonGroup>
    </Content.Wrapper>
  );
}

const Content = {
  Wrapper: styled.div`
    max-width: 560px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 100%;

    background: white;
    border-radius: 24px;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 16px;
    }
    .ant-skeleton-element {
      width: 100%;
    }

    .ant-skeleton-image {
      height: 30vh;
      width: 100%;
      border-radius: 16px;
    }
  `,
  Section: styled.div`
    width: 100%;
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 16px;
  `,
  Description: styled.div`
    /* width: 100%; */
    margin-top: 12px;
    font-weight: 400;
    font-size: 12px;
    color: black;
  `,
  SectionLabelValue: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  `,
  Label: styled.div`
    color: var(--gray-color-label);
    font-size: 12px;
  `,
  Value: styled.div``,
  ButtonGroup: styled.div`
    width: 100%;
    margin-top: 12px;
  `,
};
