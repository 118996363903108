import CardContent from '@components/card/CardContent';
import CardInfluencer from '@components/card/CardInfluencer';
import LayoutPublic from '@components/layout/LayoutPublic';
import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import { influencerLinkAPI } from '@services/apis';
import { InfluencerLinkGetInfluencersByToken } from '@services/models/influencerLink';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Divider, List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface State {
  loading: boolean;
  req: InfluencerLinkGetInfluencersByToken.Request;
  resp?: InfluencerLinkGetInfluencersByToken.Response;
}

export default function InfluencerLinksPage() {
  const { token }: { token: string } = useParams();

  const [state, setState] = useState<State>({
    loading: false,
    req: { pageNumber: 1, pageSize: 0 },
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(
    req: InfluencerLinkGetInfluencersByToken.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoading();
      const resp = await influencerLinkAPI.getInfluencersByToken(token, req);
      if (loadMoreMode) {
        setState((prev) => {
          resp.data = [...(prev.resp?.data ?? []), ...resp.data];
          return { ...prev, resp: resp };
        });
      } else {
        setState((prev) => ({ ...prev, resp: resp }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Content.Wrapper>
        <CardContent>
          <InfiniteScroll
            dataLength={state.resp?.data.length ?? 0}
            next={onLoadMore}
            hasMore={
              state.resp?.page
                ? state.resp.page.pageNumber < state.resp.page.totalPages
                : false
            }
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
            scrollableTarget='scrollableDiv'
          >
            <List
              loading={state.loading}
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 5,
              }}
              dataSource={state.resp?.data}
              renderItem={(item) => (
                <List.Item>
                  <CardInfluencer
                    key={item.userId}
                    data={{
                      ...item,
                    }}
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </CardContent>
      </Content.Wrapper>
    </LayoutPublic>
  );
}

const Content = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
};
