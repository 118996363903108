import { UnorderedListOutlined } from '@ant-design/icons';
import DrawerCompCard from '@components/drawer/DrawerCompCard';
import SectionSocial from '@components/section/SectionSocial';
import { Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';

type Data = {
  userId: number;
  profileImageURL: string;

  address: string;
  email: string;
  facebook: string;
  facebookFollower: number;
  firstName: string;
  instagram: string;
  instagramFollower: number;
  lastName: string;
  lineId: string;
  phoneNo: string;
  tiktok: string;
  tiktokFollower: number;
  twitter: string;
  twitterFollower: number;
};

interface State {
  selected: boolean;
  openCompCard: boolean;
}

type Props = {
  children?: ReactNode;
  selected?: boolean;
  selectedBgColor?: string;
  data: Data;
};
export default function CardInfluencer(props: Props) {
  const {
    data,
    children,
    selected,
    selectedBgColor = 'var(--secondary-color)',
  } = props;

  const [state, setState] = useState<State>({
    selected: false,
    openCompCard: false,
  });

  const openCompCard = () =>
    setState((prev) => ({ ...prev, openCompCard: true }));
  const closeCompCard = () =>
    setState((prev) => ({ ...prev, openCompCard: false }));

  useEffect(() => {
    if (selected) {
      setState((prev) => ({ ...prev, selected: true }));
    } else {
      setState((prev) => ({ ...prev, selected: false }));
    }
  }, [selected]);

  return (
    <ContentStyle.Wrapper>
      <DrawerCompCard
        userId={data.userId}
        open={state.openCompCard}
        onClose={closeCompCard}
      />

      <CardStyle.Card
        hoverable
        bodyStyle={{ padding: '16px' }}
        selected={state.selected}
        selectedBgColor={selectedBgColor}
      >
        <CardStyle.CardBody>
          <ContentStyle.CoverImage
            effect='blur'
            src={data.profileImageURL}
            style={{ borderRadius: '16px' }}
            onClick={openCompCard}
          />

          <Row justify='space-between' align='middle'>
            <Col>
              <CardStyle.Title
                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                style={{ marginBottom: '0' }}
                selected={state.selected}
              >
                {`${data.firstName} ${data.lastName}`}
              </CardStyle.Title>
            </Col>
            <Col>
              <Tooltip
                title='ดู Comp Card'
                color='orange'
                key='tooltips-comp-card'
              >
                <Button
                  type='text'
                  onClick={openCompCard}
                  icon={
                    <UnorderedListOutlined
                      style={{ color: state.selected ? 'white' : undefined }}
                    />
                  }
                />
              </Tooltip>
            </Col>
          </Row>

          <SectionSocial {...data} selected={state.selected} />
          {children}
        </CardStyle.CardBody>
      </CardStyle.Card>
    </ContentStyle.Wrapper>
  );
}

const ContentStyle = {
  Wrapper: styled.div`
    width: 100%;
  `,
  CoverImage: styled(LazyLoadImage)`
    width: 100%;
    height: 200px;
    object-fit: cover;
  `,
};

const CardStyle = {
  Card: styled(Card)<{ selected: boolean; selectedBgColor: string }>`
    border-radius: 16px;
    width: 100%;

    .ant-card-meta {
      display: block;
    }

    border-color: #d8d8d8;

    background-color: ${(props) =>
      props.selected ? props.selectedBgColor : undefined};
  `,
  Title: styled(Typography.Paragraph)<{ selected: boolean }>`
    font-size: 16px;
    ${(props) =>
      props.selected &&
      css`
        color: white;
      `}
  `,
  CardBody: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};
