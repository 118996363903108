import {
  AuditOutlined,
  CompassOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import CardContent from '@components/card/CardContent';
import DraggerInput from '@components/form/DraggerInput';
import ISocialIcon from '@components/icon/ISocialIcon';
import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import { ROUTE } from '@constants/routes';
import { InfluencerRegisterAgreementPageLocationState } from '@pages/influencer/register/agreement';
import { influencerAPI } from '@services/apis';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import { notifyFailed } from '@utils/notifyUtils';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  RowProps,
  Space,
  UploadFile,
} from 'antd';
import * as mime from 'mime-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

export interface Search {
  next?: string;
}

interface FormType {
  profileImages: UploadFile[];
  compCardImages: UploadFile[];

  firstName: string;
  lastName: string;
  phoneNo: string;
  email: string;
  lineId: string;
  facebook?: string;
  facebookFollower: number;
  instagram?: string;
  instagramFollower: number;
  tiktok?: string;
  tiktokFollower: number;
  twitter?: string;
  youtube?: string;
  twitterFollower: number;
  videoLinks: string[];
  address: string;
}

const FORM_SCHEMA = {
  profileImages: {
    name: 'profileImages',
    label: 'ภาพโปรไฟล์',
    rules: formRuleUtils.REQUIRE,
  },
  compCardImages: {
    name: 'compCardImages',
    label: 'Comp Cards',
  },
  firstName: { name: 'firstName', label: 'ชื่อ', rules: formRuleUtils.REQUIRE },
  lastName: {
    name: 'lastName',
    label: 'นามสกุล',
    rules: formRuleUtils.REQUIRE,
  },
  phoneNo: {
    name: 'phoneNo',
    label: 'เบอร์โทรศัพท์',
    rules: formRuleUtils.PHONE_NO,
  },
  email: { name: 'email', label: 'อีเมล', rules: formRuleUtils.EMAIL },
  lineId: {
    name: 'lineId',
    label: 'LineID',
    rules: formRuleUtils.REQUIRE,
  },
  facebook: {
    name: 'facebook',
    label: 'URL',
    rules: formRuleUtils.REQUIRE_URL,
  },
  facebookFollower: {
    name: 'facebookFollower',
    label: 'จำนวนผู้ติดตาม',
  },
  instagram: {
    name: 'instagram',
    label: 'URL',
    rules: formRuleUtils.URL,
  },
  instagramFollower: {
    name: 'instagramFollower',
    label: 'จำนวนผู้ติดตาม',
  },
  tiktok: {
    name: 'tiktok',
    label: 'URL',
    rules: formRuleUtils.URL,
  },
  tiktokFollower: {
    name: 'tiktokFollower',
    label: 'จำนวนผู้ติดตาม',
  },
  twitter: {
    name: 'twitter',
    label: 'twitter',
    rules: formRuleUtils.URL,
  },
  twitterFollower: {
    name: 'twitterFollower',
    label: 'จำนวนผู้ติดตาม',
  },
  youtube: {
    name: 'youtube',
    label: 'Youtube',
  },
  youtubeFollower: {
    name: 'youtubeFollower',
    label: 'จำนวนผู้ติดตาม',
  },
  videoLinks: {
    name: 'videoLinks',
    label: 'Video Links (ถ้ามี)',
  },
  address: {
    name: 'address',
    label:
      'ที่อยู่ผู้สมัครร่วมกิจกรรม/ ที่อยู่สำหรับจัดส่งสินค้า (สำหรับงานรีวิว)',
    placeholder:
      'เลขที่บ้าน ชื่อหมู่บ้าน/ร้านค้า - หมู่ - ซอย ซอยแยก (ถ้ามี) ชื่อถนน - แขวงหรือตำบล เขตหรืออำเภอ - จังหวัด และ รหัสไปรษณีย์',
    rules: formRuleUtils.REQUIRE,
  },
};

export interface InfluencerRegisterPageLocationState {
  jobId?: number;
  initialPhoneNo?: string;
}

interface State {
  loading: boolean;
}
export default function InfluencerRegisterPage() {
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as InfluencerRegisterPageLocationState;

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  const rowProps: RowProps = {
    gutter: 16,
    justify: 'center',
  };

  function appendHttps(url: string | undefined) {
    if (url) {
      const newUrl = url.replace('https://', '');
      return `https://${newUrl}`;
    } else {
      return undefined;
    }
  }

  async function onFinish(values: FormType) {
    // const parse: Search = parseQueryString(location.search);
    // console.log({ values, location, parse, next: parse.next });

    try {
      showLoading();

      const fileKeys = [
        FORM_SCHEMA.profileImages.name,
        FORM_SCHEMA.compCardImages.name,
      ];

      values.facebook = appendHttps(values.facebook);
      values.instagram = appendHttps(values.instagram);
      values.tiktok = appendHttps(values.tiktok);
      values.twitter = appendHttps(values.twitter);
      values.twitter = appendHttps(values.youtube);

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (!fileKeys.includes(key) && value !== undefined) {
          formData.append(key, value);
        }
      });

      formData.append(
        FORM_SCHEMA.profileImages.name,
        values.profileImages[0].originFileObj as File,
        values.profileImages[0].fileName
      );

      values.compCardImages?.forEach((e, index) => {
        formData.append(
          `${FORM_SCHEMA.compCardImages.name}[${index}]`,
          e.originFileObj as File,
          e.fileName
        );
      });

      const resp = await influencerAPI.create(formData);
      if (resp.userId) {
        const targetState: InfluencerRegisterAgreementPageLocationState = {
          userId: resp.userId,
          jobId: locationState?.jobId,
        };

        history.push({
          ...location,
          state: targetState,
          pathname: ROUTE.INFLUENCER_REGISTER_AGREEMENT,
        });
      } else {
        notifyFailed(
          'เกิดข้อผิดพลาด',
          'ติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ'
        );
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    form.setFieldValue(
      FORM_SCHEMA.phoneNo.name,
      locationState?.initialPhoneNo ?? undefined
    );
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Loading loading={state.loading}>
        <Row justify='center'>
          <Col xs={24} md={22} lg={18} xl={12}>
            <CardContent>
              <Content.Wrapper>
                <Content.FormTitle>
                  แบบฟอร์มสมัครสมาชิก Influencer
                </Content.FormTitle>
                <Form
                  scrollToFirstError
                  layout='vertical'
                  form={form}
                  onFinish={onFinish}
                >
                  <Content.Section>
                    <Content.SectionTitle>
                      <Space>
                        <UserOutlined />
                        โปรไฟล์
                      </Space>
                    </Content.SectionTitle>
                    <Content.SectionBody>
                      <Form.Item {...FORM_SCHEMA.profileImages}>
                        <DraggerInput
                          maxCount={1}
                          acceptList={[mime.lookup('jpeg').toString()]}
                          resizeMaxSizeMB={0.3}
                          listType='picture'
                        />
                      </Form.Item>

                      <Form.Item {...FORM_SCHEMA.compCardImages}>
                        <DraggerInput
                          multiple
                          maxCount={10}
                          acceptList={[mime.lookup('jpeg').toString()]}
                          resizeMaxSizeMB={0.5}
                          listType='picture'
                        />
                      </Form.Item>
                    </Content.SectionBody>
                  </Content.Section>

                  <Content.Section>
                    <Content.SectionTitle>
                      <Space>
                        <InfoCircleOutlined />
                        ข้อมูลทั่วไป
                      </Space>
                    </Content.SectionTitle>
                    <Content.SectionBody>
                      <Row {...rowProps}>
                        <Col xs={24} md={12}>
                          <Form.Item {...FORM_SCHEMA.firstName}>
                            <Input placeholder={FORM_SCHEMA.firstName.label} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item {...FORM_SCHEMA.lastName}>
                            <Input placeholder={FORM_SCHEMA.lastName.label} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row {...rowProps}>
                        <Col xs={24} md={12}>
                          <Form.Item {...FORM_SCHEMA.phoneNo}>
                            <Input placeholder={FORM_SCHEMA.phoneNo.label} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item {...FORM_SCHEMA.email}>
                            <Input
                              placeholder={FORM_SCHEMA.email.label}
                              type='email'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Content.SectionBody>
                  </Content.Section>

                  <Content.Section>
                    <Content.SectionTitle>
                      <Space>
                        <CompassOutlined />
                        Social Media
                      </Space>
                    </Content.SectionTitle>

                    <Content.SectionBody>
                      <Form.Item {...FORM_SCHEMA.lineId}>
                        <Input
                          addonBefore={
                            <ISocialIcon network='line' width={20} />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.lineId.label}
                        />
                      </Form.Item>

                      <SectionLabel>Facebook</SectionLabel>
                      <Form.Item {...FORM_SCHEMA.facebook}>
                        <Input
                          addonBefore={
                            <ISocialIcon
                              network='facebook'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder='ตัวอย่าง www.facebook.com/dooq'
                        />
                      </Form.Item>
                      <Form.Item {...FORM_SCHEMA.facebookFollower}>
                        <InputNumber
                          precision={0}
                          step={1}
                          addonBefore={
                            <ISocialIcon
                              network='facebook'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.facebookFollower.label}
                        />
                      </Form.Item>
                      <br />

                      <SectionLabel>Instagram</SectionLabel>
                      <Form.Item {...FORM_SCHEMA.instagram}>
                        <Input
                          addonBefore={
                            <ISocialIcon
                              network='instagram'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder='ตัวอย่าง www.instagram.com/dooq'
                        />
                      </Form.Item>
                      <Form.Item {...FORM_SCHEMA.instagramFollower}>
                        <InputNumber
                          precision={0}
                          step={1}
                          addonBefore={
                            <ISocialIcon
                              network='instagram'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.instagramFollower.label}
                        />
                      </Form.Item>
                      <br />

                      <SectionLabel>Tiktok</SectionLabel>
                      <Form.Item {...FORM_SCHEMA.tiktok}>
                        <Input
                          addonBefore={
                            <ISocialIcon
                              network='tiktok'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder='ตัวอย่าง www.tiktok.com/@dooq'
                        />
                      </Form.Item>
                      <Form.Item {...FORM_SCHEMA.tiktokFollower}>
                        <InputNumber
                          precision={0}
                          step={1}
                          addonBefore={
                            <ISocialIcon
                              network='tiktok'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.tiktokFollower.label}
                        />
                      </Form.Item>
                      <br />

                      <SectionLabel>Twitter</SectionLabel>
                      <Form.Item {...FORM_SCHEMA.twitter}>
                        <Input
                          addonBefore={
                            <ISocialIcon
                              network='twitter'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder='Twitter URL'
                        />
                      </Form.Item>
                      <Form.Item {...FORM_SCHEMA.twitterFollower}>
                        <InputNumber
                          precision={0}
                          step={1}
                          addonBefore={
                            <ISocialIcon
                              network='youtube'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.twitterFollower.label}
                        />
                      </Form.Item>
                      <br />

                      <SectionLabel>Youtube</SectionLabel>
                      <Form.Item {...FORM_SCHEMA.youtube}>
                        <Input
                          addonBefore={
                            <ISocialIcon
                              network='youtube'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder='Youtube URL'
                        />
                      </Form.Item>
                      <Form.Item {...FORM_SCHEMA.youtubeFollower}>
                        <InputNumber
                          precision={0}
                          step={1}
                          addonBefore={
                            <ISocialIcon
                              network='youtube'
                              height={20}
                              width={20}
                            />
                          }
                          style={{ width: '100%' }}
                          placeholder={FORM_SCHEMA.youtubeFollower.label}
                        />
                      </Form.Item>
                      <br />

                      <Form.Item label='Video Link (ถ้ามี)'>
                        <Form.List
                          {...FORM_SCHEMA.videoLinks}
                          // rules={[
                          //   {
                          //     validator: async (_, names) => {
                          //       if (!names || names.length < 2) {
                          //         return Promise.reject(
                          //           new Error('At least 2 passengers')
                          //         );
                          //       }
                          //     },
                          //   },
                          // ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field) => (
                                <Form.Item required={false} key={field.key}>
                                  <Row align='middle'>
                                    <Col span={22}>
                                      <Form.Item
                                        {...field}
                                        noStyle
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                          ...formRuleUtils.URL,
                                          {
                                            required: true,
                                            message:
                                              "Please input URL's of vidoe or delete this field.",
                                          },
                                        ]}
                                      >
                                        <Input placeholder='URL' />
                                      </Form.Item>
                                    </Col>
                                    <Col flex='auto'>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {fields.length > 0 && (
                                          <MinusCircleOutlined
                                            className='dynamic-delete-button'
                                            onClick={() => remove(field.name)}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Button
                                  type='dashed'
                                  onClick={() => add()}
                                  style={{ width: '100%' }}
                                  icon={<PlusOutlined />}
                                >
                                  เพิ่มลิ้งค์
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    </Content.SectionBody>
                  </Content.Section>

                  <Content.Section>
                    <Content.SectionTitle>
                      <Space>
                        <AuditOutlined />
                        ข้อมูล
                      </Space>
                    </Content.SectionTitle>

                    <Content.SectionBody>
                      <Form.Item {...FORM_SCHEMA.address}>
                        <Input.TextArea
                          placeholder={FORM_SCHEMA.address.placeholder}
                          rows={4}
                        />
                      </Form.Item>
                    </Content.SectionBody>
                  </Content.Section>
                  <Form.Item>
                    <Button
                      block
                      type='primary'
                      htmlType='submit'
                      size='large'
                      shape='round'
                      loading={state.loading}
                    >
                      ยืนยัน
                    </Button>
                  </Form.Item>
                </Form>
              </Content.Wrapper>
            </CardContent>
          </Col>
        </Row>
      </Loading>
    </LayoutPublic>
  );
}

const Content = {
  Wrapper: styled.div``,
  FormTitle: styled.h1`
    text-align: center;
    margin-bottom: 2rem;
  `,
  Section: styled.div`
    margin-top: 3rem;
  `,
  SectionTitle: styled.div`
    color: var(--primary-color);
    font-size: calc(var(--base-font-size) + 2px);
    margin-bottom: 12px;
  `,
  SectionBody: styled.div``,
};

const SectionLabel = styled.h3``;
