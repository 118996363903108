import CardContent from '@components/card/CardContent';
import DraggerInput from '@components/form/DraggerInput';
import Loading from '@components/loading/Loading';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import { jobApplicationApi, jobProgressApi } from '@services/apis';
import { JobProgressUpload } from '@services/models/jobProgress';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import { Button, Form, Input, Modal, notification, UploadFile } from 'antd';
import * as mime from 'mime-types';
import React, { useEffect, useState } from 'react';

interface FormType {
  files: UploadFile[];
  caption: string;
  link: string;
}

const FORM_SCHEMA = {
  userId: { name: 'userId', label: 'userId' },
  files: { name: 'files', label: 'ผลงานรรีวิว' },
  link: { name: 'link', label: 'ลิงค์' },
  caption: { name: 'caption', label: 'แคปชั่น' },
};

interface State {
  loading: boolean;
  loadingInfo: boolean;
  isRequiredFiles: boolean;
  flagDraggerInpu: number;
}

type Props = {
  jobId: number;
  onSuccess: () => void;
};
export default function SectionUpload(props: Props) {
  const { jobId, onSuccess } = props;

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loading: false,
    loadingInfo: false,
    isRequiredFiles: true,
    flagDraggerInpu: 0,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));
  const showLoadingInfo = () =>
    setState((prev) => ({ ...prev, loadingInfo: true }));
  const hideLoadingInfo = () =>
    setState((prev) => ({ ...prev, loadingInfo: false }));

  async function onFinish(values: FormType) {
    try {
      showLoading();
      const formData = new FormData();

      const req: JobProgressUpload.Request = {
        caption: values.caption,
        link: values.link,
      };

      Object.entries(req).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      values.files?.forEach((e, index) => {
        formData.append(
          `${FORM_SCHEMA.files.name}[${index}]`,
          e.originFileObj as File,
          e.fileName
        );
      });

      await jobProgressApi.upload(jobId, formData);
      notification['success']({
        message: 'อัพโหลดผลงานสำเร็จ',
      });
      onSuccess();
      setState((prev) => ({
        ...prev,
        flagDraggerInpu: prev.flagDraggerInpu + 1,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  async function fetchApplicationInfo() {
    try {
      showLoadingInfo();
      const resp = await jobApplicationApi.reviewerGetInfo(jobId);
      form.setFieldsValue({
        [FORM_SCHEMA.caption.name]: resp.caption,
        [FORM_SCHEMA.link.name]: resp.link,
      });

      switch (resp.stage) {
        case JOB_APPLICATION_STAGE.REVISE:
          Modal.error({
            title: 'งานรีวิวของคุณยังไม่ผ่าน',
            content: 'ด฿รายละเอียดได้ที่คอมเมนต์',
          });
          break;
        case JOB_APPLICATION_STAGE.ACCEPT:
          Modal.success({
            title: 'Congratulation',
            content:
              'งานรีวิวของคุณผ่านแล้ว, สามารถที่จะอัพเดทลิงค์และแคปชั่นได้',
          });
          setState((prev) => ({ ...prev, isRequiredFiles: false }));
          break;
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingInfo();
    }
  }

  useEffect(() => {
    fetchApplicationInfo();
  }, []);

  return (
    <CardContent title='ฟอร์มอัพโหลดงานรีวิว'>
      <Loading loading={state.loadingInfo}>
        <Form
          scrollToFirstError
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            {...FORM_SCHEMA.files}
            valuePropName='fileList'
            rules={formRuleUtils.REQUIRE_UPLOAD}
          >
            <DraggerInput
              key={state.flagDraggerInpu}
              multiple
              maxCount={100}
              acceptList={[mime.lookup('jpeg').toString()]}
              resizeMaxSizeMB={1}
              listType='picture'
            />
          </Form.Item>

          <Form.Item {...FORM_SCHEMA.link}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item {...FORM_SCHEMA.caption}>
            <Input.TextArea rows={3} />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              size='large'
              shape='round'
              htmlType='submit'
              loading={state.loading}
              disabled={state.loadingInfo}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </CardContent>
  );
}
