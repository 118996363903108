import { api } from '@core/api/api-client';
import {
  UserAgreementAccept,
  UserAgreementGetByUserId,
} from '@services/models/userAgreement';
import { generatePath } from 'react-router-dom';

import { ENDPOINT } from '.';

export async function accept(req: UserAgreementAccept.Request) {
  const res = await api.post<UserAgreementAccept.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.USER_AGREEMENT_BASE_URL}`,
    req
  );
  return res.data;
}

export async function getByUserId(userId: number) {
  const res = await api.get<UserAgreementGetByUserId.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.USER_AGREEMENT_GET_BY_USER_ID,
      { userId }
    )}`
  );
  return res.data;
}
