import { api } from '@core/api/api-client';
import { ENDPOINT } from '@services/apis';
import { EmployerGetInfo } from '@services/models/employer';
import { generateQueryString } from '@utils/apiUtils';

export async function getInfo(req: EmployerGetInfo.Request) {
  const query = generateQueryString(req);
  const res = await api.get<EmployerGetInfo.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.EMPLOYER_GET_INFO}?${query}`
  );
  return res.data;
}
