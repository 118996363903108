import {
  CheckOutlined,
  CloseOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import ISocialIcon from '@components/icon/ISocialIcon';
import JobOfferStageTag from '@components/stage/TagJobState';
import { ROUTE } from '@constants/routes';
import { RecordType } from '@pages/backoffice/manages/jobs/components/Table';
import { createMarkup } from '@utils/dataUtils';
import { Col, Dropdown, Menu, Row, Space, Switch, Typography } from 'antd';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

type CardApplicationProps = {
  item: RecordType;

  genINFRegisterLink: (id: number) => void;
  genINFUploadLink: (id: number) => void;
  genEMPLink: (id: number) => void;
  genAnnouncementLink: (id: number) => void;
  goToSelectCandidatePage: (id: number) => void;
  goToApproveUploadPage: (id: number) => void;
  completeJob: (id: number) => void;
  handleChangeJobApplySwitch: (id: number, value: boolean) => void;
};
export default function CardApplication(props: CardApplicationProps) {
  const {
    item,
    genINFRegisterLink,
    genINFUploadLink,
    genEMPLink,
    genAnnouncementLink,
    goToSelectCandidatePage,
    goToApproveUploadPage,
    completeJob,
    handleChangeJobApplySwitch,
  } = props;
  const id = item.item.datum.id;

  const history = useHistory();

  function goToView() {
    history.push(generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL, { id }));
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          type: 'group',
          label: 'เมนูลัด',
          children: [
            {
              key: '1-1',
              label: (
                <Space>
                  <span>รับสมัคร</span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={item.item.datum.canApply}
                    onClick={(value) => {
                      handleChangeJobApplySwitch(id, value);
                    }}
                  />
                </Space>
              ),
            },
          ],
        },
        {
          key: '2',
          label: 'สร้างลิงค์',
          children: [
            {
              key: '2-1',
              label: 'ลิงค์สมัคร',
              onClick: () => genINFRegisterLink(id),
            },
            {
              key: '2-2',
              label: 'ลิงค์อัพโหลด',
              onClick: () => genINFUploadLink(id),
            },
            {
              key: '2-3',
              label: ' ลิงค์ผู้ประกอบการ',
              onClick: () => genEMPLink(id),
            },
            {
              key: '2-4',
              label: 'ลิ้งค์ประกาศ',
              onClick: () => genAnnouncementLink(id),
            },
          ],
        },
        {
          key: '3',
          label: 'แอคชั่น',
          children: [
            {
              key: '3-1',
              label: 'เลือกผู้สมัคร',
              onClick: () => goToSelectCandidatePage(id),
            },
            {
              key: '3-2',
              label: 'ตรวจสอบ',
              onClick: () => goToApproveUploadPage(id),
            },
            {
              key: '3-3',
              label: 'จบงาน',
              onClick: () => completeJob(id),
            },
          ],
        },
      ]}
    />
  );

  return (
    <CardApplicationStyle.Wrapper key={id}>
      <CardApplicationStyle.Row>
        <CardApplicationStyle.ColLeft span={6}>
          <img
            // src='https://m.media-amazon.com/images/I/61ZRU9gnbxL._AC_SX342_.jpg'
            src={item.item.info.productImageURL}
            alt=''
            onClick={goToView}
          />
        </CardApplicationStyle.ColLeft>
        <CardApplicationStyle.ColRight span={17} offset={1}>
          {/* <CardStyle.Title onClick={goToView}>
            {item.item.datum.name}
          </CardStyle.Title> */}
          {/* <CardStyle.Social onClick={goToView}>ss</CardStyle.Social> */}

          <Row>
            <Col span={20}>
              <CardApplicationStyle.Title onClick={goToView}>
                {item.item.datum.name}
              </CardApplicationStyle.Title>
            </Col>
            <Col span={4} style={{ textAlign: 'end' }}>
              <ISocialIcon network={item.item.datum.reviewSmcCodes} />
            </Col>
          </Row>

          <CardApplicationStyle.Description ellipsis={{ rows: 2 }}>
            {/* {item.item.datum.description} */}
            <div
              dangerouslySetInnerHTML={createMarkup(
                item.item.datum.description ?? '</>'
              )}
            />
          </CardApplicationStyle.Description>

          <Row justify='space-between' gutter={6}>
            <Col span={8}>
              <CardApplicationStyle.Label>สถานะ</CardApplicationStyle.Label>
              <CardApplicationStyle.Value>
                {JobOfferStageTag(
                  item.item.datum.stage,
                  item.item.info.unCheckUploadAmount
                )}
              </CardApplicationStyle.Value>
            </Col>
            <Col span={8}>
              <CardApplicationStyle.Label>ผู้สมัคร</CardApplicationStyle.Label>
              <CardApplicationStyle.Value>
                <span>
                  <strong>{item.item.info.currentNoApplications}</strong> /{' '}
                  {item.item.info.maxNoApplications}
                </span>
              </CardApplicationStyle.Value>
            </Col>
            <Col span={8}>
              <CardApplicationStyle.Label>แอคชั่น</CardApplicationStyle.Label>
              <CardApplicationStyle.Value>
                <Row justify='center'>
                  <Dropdown overlay={menu}>
                    <DownCircleOutlined
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '18px',
                      }}
                    />
                  </Dropdown>
                </Row>
              </CardApplicationStyle.Value>
            </Col>
          </Row>
        </CardApplicationStyle.ColRight>
      </CardApplicationStyle.Row>
    </CardApplicationStyle.Wrapper>
  );
}

const CardApplicationStyle = {
  Wrapper: styled.div`
    background-color: white;
    border-radius: 16px;
    height: 190px;
    padding: 16px;
  `,
  Row: styled(Row)`
    height: 100%;
  `,
  ColLeft: styled(Col)`
    /* background-color: blue; */
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 8px;
    }
  `,
  ColRight: styled(Col)`
    height: 100%;

    padding-top: 4px;

    div.ant-typography,
    .ant-typography p {
      margin-bottom: 2em;
    }
  `,
  Title: styled.div`
    font-size: 18px;
    font-weight: bold;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    margin-bottom: 8px;
  `,
  Social: styled.div``,
  Description: styled(Typography.Paragraph)`
    color: gray;
    height: 40px;
  `,
  Label: styled.div`
    color: var(--gray-color-7);
    font-size: 16px;
  `,
  Value: styled.div``,
};
