import { LinkOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { PAGE_CODE } from '@constants/constants';
import { SVG } from '@constants/resource';
import { ROUTE } from '@constants/routes';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// const { SubMenu } = Menu;

type Props = {
  currentTab?: string;
  onMenuChange: () => void;
};

export default function SiderMain(props: Props) {
  const { currentTab, onMenuChange } = props;
  const [tab, setTab] = useState('');
  const [
    ,
    //openKeys
    setOpenKeys,
  ] = useState<string[] | undefined>(undefined);

  const { isDesktopOrTablet } = useResponsiveContext();

  const menu = [
    {
      name: 'งานรีวิว',
      key: PAGE_CODE.BACKOFFICE_MANAGE_JOBS,
      route: ROUTE.BACKOFFICE_MANAGES_JOBS,
      icon: <VideoCameraOutlined />,
    },
    {
      name: 'ลิงค์สมาชิกอินฟลูเอนเซอร์',
      key: PAGE_CODE.BACKOFFICE_MANAGE_INFLUENCER_LINK,
      route: ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS,
      icon: <LinkOutlined />,
    },
  ];
  useEffect(() => {
    handleMenu();
  }, [currentTab]);

  function handleMenu() {
    if (currentTab) {
      setMenuTab(currentTab);
    }
  }

  function setMenuTab(value: string) {
    if (tab !== value) {
      setTab(value ?? '');
    }
  }

  function handleSelect({ key }: { key: string }) {
    setMenuTab(key);
  }

  function expandSubMenu(key: string) {
    const pipeIndex = key.indexOf('|');
    setOpenKeys(pipeIndex > -1 ? [key.substring(0, pipeIndex)] : undefined);
  }

  useEffect(() => {
    // Expand submenu
    expandSubMenu(tab);
  }, [tab]);

  return (
    <Menu
      mode='inline'
      theme='dark'
      selectedKeys={[tab]}
      onSelect={handleSelect}
      // openKeys={collapsed ? undefined : openKeys}
    >
      {isDesktopOrTablet && (
        <div style={{ height: '120px' }}>
          <Row
            style={{ height: '100%', padding: '10px' }}
            align='middle'
            justify='center'
          >
            <ImgStyle
              src={SVG.LOGO_NEW_DOOQ}
              style={{ height: '100%', maxHeight: '65px' }}
            />
          </Row>
        </div>
      )}

      {menu.map((e) => (
        <Menu.Item key={e.key} icon={e.icon}>
          {e.name}
          <Link to={e.route} onClick={onMenuChange} />
        </Menu.Item>
      ))}
    </Menu>
  );
}

const ImgStyle = styled.img`
  width: 60%;
  min-width: 60px;
`;
