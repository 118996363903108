import { PRIVATE_ROUTER, PUBLIC_ROUTER } from '@constants/routes';
import ProviderComposer from '@contexts/ProviderComposer';
import NotFoundPage from '@pages/404';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';
import './App.less';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Authentication from './Authentication';
import ScrollToTop from './ScrollToTop';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback='Loading...'>
        <ProviderComposer>
          <Switch>
            {/* -------------- public ---------------- */}
            {PUBLIC_ROUTER?.map((e) => (
              <Route key={e.code} path={e.path} exact={e.exact}>
                {e.component}
              </Route>
            ))}
            {/* -------------- private ---------------- */}
            {PRIVATE_ROUTER?.map((e) => (
              <PrivateRoute
                key={e.code}
                path={e.path}
                exact={e.exact}
                code={e.code}
              >
                {e.component}
              </PrivateRoute>
            ))}
            {/* -------------- not found ---------------- */}
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </ProviderComposer>
      </Suspense>
    </BrowserRouter>
  );
}

function PrivateRoute({ children, ...rest }: RouteProps & { code?: string }) {
  return (
    <Route
      {...rest}
      render={(props) => Authentication(props, children, rest.code)}
    />
  );
}
