import { Affix, Button } from 'antd';
import React from 'react';

type Props = {
  loading: boolean;
  onClick: () => void;
};
export default function ButtonSubmit(props: Props) {
  const { loading, onClick } = props;
  return (
    <Affix offsetBottom={16}>
      <Button
        block
        type='primary'
        size='large'
        shape='round'
        loading={loading}
        onClick={onClick}
      >
        Submit
      </Button>
    </Affix>
  );
}
