import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import { ROUTE } from '@constants/routes';
import { JobApplication } from '@services/domains/jobApplication';
import { Button, Col, Result, Row } from 'antd';
import React from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

export interface JobInfluencerRegisterSuccessPageLocationState {
  jobApplication: JobApplication;
}

export default function JobInfluencerRegisterSuccessPage() {
  const { id }: { id: string } = useParams();

  const location = useLocation();
  const locationState =
    location.state as JobInfluencerRegisterSuccessPageLocationState;

  const history = useHistory();

  function goToHomePage() {
    history.push('');
  }

  function goToJobAnnoucementPage() {
    history.push(getJobAnnoucementPageURL());
  }

  function getJobAnnoucementPageURL() {
    return generatePath(ROUTE.BOARD_ANNOUNCEMENT, { id });
  }

  return (
    <LayoutPublic hideLogin>
      <Row justify='center'>
        <Col xs={24} sm={16} md={16} lg={12} xl={8}>
          <CardContent>
            <Result
              status='success'
              title='ลงทะเบียนสำเร็จ'
              subTitle={`เลขที่ใบสมัคร ${locationState.jobApplication.id}. สามารถตรวจสอบรายชื่อผู้ผ่านการคัดเลือกได้ที่หน้าประกาศ`}
              extra={[
                <Button type='primary' key='close' onClick={goToHomePage}>
                  กลับสู่หน้าหลัก
                </Button>,
                <Button key='close' onClick={goToJobAnnoucementPage}>
                  ไปหน้าประกาศ
                </Button>,
              ]}
            />
          </CardContent>
        </Col>
      </Row>
    </LayoutPublic>
  );
}
