import { EditOutlined } from '@ant-design/icons';
import { LabelValue } from '@services/models/common';
import { ProductList } from '@services/models/product';
import { Button, Col, Row, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

type Props = {
  isSelected?: boolean;
  data: ProductList.ResponseData;
  productTypes: LabelValue[];
  onSelect: (data: ProductList.ResponseData) => void;
  onDeselect?: () => void;
};
export default function ProductCard(props: Props) {
  const { data, isSelected = false, onSelect, onDeselect } = props;

  const CardStyle = {
    Wrapper: styled.div<{ isSelected: boolean }>`
      width: 100%;
      padding: 12px;
      border-radius: 24px;

      background-color: ${(props) =>
        props.isSelected ? 'var(--secondary-color)' : 'white'};
    `,
    ImageWrapper: styled.div`
      height: 140px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    `,
    Name: styled.div<{ isSelected: boolean }>`
      font-size: 16px;
      font-weight: bold;
      margin-top: 12px;

      color: ${(props) => (props.isSelected ? 'white' : 'black')};
    `,

    Description: styled(Typography.Paragraph)`
      margin-top: 4px;
      color: var(--gray-color-6);
      font-size: 14px;
    `,
    RowInfo: styled(Row)`
      margin-top: 8px;
    `,
    Label: styled.div`
      color: var(--gray-color-6);
      font-size: 14px;
    `,
    Value: styled.div<{ isSelected: boolean }>`
      font-size: 16px;
      color: ${(props) => (props.isSelected ? 'white' : 'black')};
    `,
    RowButton: styled(Row)`
      margin-top: 16px;

      button {
        border-radius: 24px;
      }
    `,
  };
  return (
    <CardStyle.Wrapper isSelected={isSelected}>
      <CardStyle.ImageWrapper>
        <img src={data.info.publicUrl}></img>
      </CardStyle.ImageWrapper>
      <CardStyle.Name isSelected={isSelected}>{data.data.name}</CardStyle.Name>
      <CardStyle.Description
        ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
      >
        {data.data.description}
      </CardStyle.Description>
      <CardStyle.RowInfo justify='space-between'>
        <Col>
          <CardStyle.Label>ประเภทสินค้า</CardStyle.Label>
          <CardStyle.Value isSelected={isSelected}>
            {
              _.find(
                props.productTypes,
                (e) => e.value === props.data.data.productTypeCode
              )?.label
            }
          </CardStyle.Value>
        </Col>
        <Col>
          <CardStyle.Label>ข้อมูลการรับบริการสินค้า</CardStyle.Label>
          <CardStyle.Value isSelected={isSelected}>Link</CardStyle.Value>
        </Col>
      </CardStyle.RowInfo>
      <CardStyle.RowButton gutter={12}>
        {isSelected ? (
          <Col span={24}>
            <Button
              block
              onClick={() => {
                onDeselect && onDeselect();
              }}
            >
              เลือกใหม่
            </Button>
          </Col>
        ) : (
          <>
            <Col span={8}>
              <Button block disabled icon={<EditOutlined />}>
                แก้ไข
              </Button>
            </Col>
            <Col flex='auto'>
              <Button block type='primary' onClick={() => onSelect(data)}>
                เลือก
              </Button>
            </Col>
          </>
        )}
      </CardStyle.RowButton>
    </CardStyle.Wrapper>
  );
}
