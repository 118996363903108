import CardContent from '@components/card/CardContent';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import TabSelectInfluencer from '@components/tabs/TabSelectInfluencer';
import { TextGrayStyle7 } from '@components/text/IStyleComponent';
import { ROUTE } from '@constants/routes';
import { influencerLinkAPI } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { failedToFetchAPI } from '@utils/apiUtils';
import { formRuleUtils } from '@utils/index';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
} from 'antd';
import React, { useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

export interface FormType {
  name: string;
  userIds: number[];
  description?: string;
  selectionType: string;
}

const FORM_SCHEMA = {
  userIds: { name: 'userIds', label: 'userIds' },
  name: { name: 'name', label: 'ชื่อลิงค์', rules: formRuleUtils.REQUIRE },
  description: { name: 'description', label: 'คำอธิบาย' },
  // isAutoAddNewinfluencers: {
  //   name: 'isAutoAddNewInfluencers',
  //   label: 'เพิ่มอินฟลูเอนเซอร์รายใหม่อัตโนมัติ',
  // },
  selectionType: {
    name: 'selectionType',
    label: 'รูปแบบการเลือก',
    rules: [...formRuleUtils.REQUIRE],
  },
  // total: {
  //   name: 'total',
  //   label: 'จำนวนที่เลือก',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'โปรดเลือกอินฟลูเอนเซอร์!',
  //     },
  //     () => ({
  //       validator(rule: unknown, value: number) {
  //         if (value <= 0) {
  //           return Promise.reject('โปรดเลือกอินฟลูเอนเซอร์');
  //         } else {
  //           return Promise.resolve();
  //         }
  //       },
  //     }),
  //   ],
  // },
};

interface State {
  loadingSubmit: boolean;
  selectionType: SelectionType;
  selectedUserIds: number[];
}

export type SelectionType =
  | undefined
  | 'custom'
  | 'all'
  | 'allWithAutoAddNewInfluencers';

export default function BackofficeManagesInfluencerLinkAddPage() {
  const history = useHistory();

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loadingSubmit: false,
    selectionType: undefined,
    selectedUserIds: [],
  });

  const selectionTypeOptions: LabelValue[] = [
    { label: 'กำหนดเอง', value: 'custom' },
    {
      label: 'ทั้งหมด (เฉพาะอินฟลูเอนเซอร์ที่อยู่ในระบบ​ ณ ปัจจุบัน)',
      value: 'all',
    },
    {
      label: 'ทั้งหมด (เพิ่มอินฟลูเอนเซอร์ใหม่อัติโนมัติ)',
      value: 'allWithAutoAddNewInfluencers',
    },
  ];

  const showLoading = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoading = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));

  function onSelect(id: number) {
    setState((prev) => {
      const selectedUserIds = [...prev.selectedUserIds, id];
      return {
        ...prev,
        selectedUserIds: selectedUserIds,
      };
    });
  }

  function onDeselect(id: number) {
    const selectedUserIds = state.selectedUserIds.filter((e) => e !== id);
    setState((prev) => ({
      ...prev,
      selectedUserIds,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onValuesChange(changedValues: any, _values: FormType) {
    const key = Object.keys(changedValues)[0];
    const value = changedValues[key];

    switch (Object.keys(changedValues)[0]) {
      case FORM_SCHEMA.selectionType.name:
        form.setFieldValue(key, value);
        setState((prev) => ({ ...prev, selectionType: value }));
        break;
    }
  }

  async function onFinish(values: FormType) {
    try {
      showLoading();

      const resp = await influencerLinkAPI.create({
        name: values.name,
        userIds: state.selectedUserIds,
        description: values.description,
        selectionType: values.selectionType,
      });

      if (resp.id) {
        notification['success']({
          message: 'สร้างลิงค์สำเร็จ',
        });
        history.push(
          generatePath(ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS_EDIT, {
            id: resp.id,
          })
        );
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  return (
    <LayoutBackoffice>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS}>
            ลิงค์สมาชิกอินฟลูเอนเซอร์
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>เพิ่มลิงค์</Breadcrumb.Item>
      </Breadcrumb>

      <Content.Wrapper>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <CardContent title='ฟอร์มสร้างลิงค์สมาชิกอินฟลูเอนเซอร์'>
              <Form
                form={form}
                layout='vertical'
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Form.Item
                  {...FORM_SCHEMA.name}
                  wrapperCol={{ xs: 24, xl: 12 }}
                >
                  <Input placeholder={FORM_SCHEMA.name.label} />
                </Form.Item>

                <Form.Item
                  {...FORM_SCHEMA.selectionType}
                  wrapperCol={{ xs: 24, xl: 12 }}
                >
                  <Select placeholder={FORM_SCHEMA.selectionType.label}>
                    {selectionTypeOptions.map((e) => (
                      <Select.Option key={e.value} value={e.value}>
                        {e.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...FORM_SCHEMA.description}
                  wrapperCol={{ xs: 24, xl: 12 }}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={FORM_SCHEMA.description.label}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={state.loadingSubmit}
                  >
                    ตกลง
                  </Button>
                </Form.Item>
              </Form>
            </CardContent>
          </Col>
        </Row>
        {state.selectionType === 'custom' && (
          <CardContent
            title={
              <Row justify='space-between'>
                <Col>อินฟลูเอนเซอร์</Col>
                <Col>
                  <TextGrayStyle7>
                    จำนวนที่เลือก: {state.selectedUserIds.length}
                  </TextGrayStyle7>
                </Col>
              </Row>
            }
          >
            <TabSelectInfluencer
              selectedUserIds={state.selectedUserIds}
              onSelect={onSelect}
              onDeselect={onDeselect}
            />
          </CardContent>
        )}
      </Content.Wrapper>
    </LayoutBackoffice>
  );
}

const Content = {
  Wrapper: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};
