import { Skeleton } from 'antd';
import React from 'react';

export default function SkeletonCard() {
  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '16px',
      }}
    >
      <Skeleton active />
    </div>
  );
}
