import {
  CheckOutlined,
  CloseOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import ITable, { ITableProps } from '@components/helper/ITable';
import ISocialIcon from '@components/icon/ISocialIcon';
import JobOfferStageTag from '@components/stage/TagJobState';
import { TextGrayStyle7 } from '@components/text/IStyleComponent';
import { MASTER_DATA_CATEGORY } from '@constants/constants';
import { JOB_OFFER_STAGE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import { masterApi } from '@services/apis';
import { JobOfferList } from '@services/models/jobOffer';
import { MasterList } from '@services/models/masterList';
import { failedToFetchAPI } from '@utils/apiUtils';
import { utcToDateFormat } from '@utils/dateUtils';
import { Button, Col, Dropdown, Menu, Row, Space, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
export interface RecordType {
  key: React.Key;
  item: JobOfferList.Data;
}

type Props = {
  onRefresh: () => void;
  genINFRegisterLink: (id: number) => void;
  genINFUploadLink: (id: number) => void;
  genEMPLink: (id: number) => void;
  genAnnouncementLink: (id: number) => void;
  goToSelectCandidatePage: (id: number) => void;
  goToApproveUploadPage: (id: number) => void;
  completeJob: (id: number) => void;
  handleChangeJobApplySwitch: (id: number, canApply: boolean) => void;
};
export function Table(props: ITableProps & Props) {
  const {
    // onRefresh,
    genINFRegisterLink,
    genINFUploadLink,
    genEMPLink,
    genAnnouncementLink,
    goToSelectCandidatePage,
    goToApproveUploadPage,
    completeJob,
    handleChangeJobApplySwitch,
  } = props;

  // TODO check
  const [, setSocialMediaChannelOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const renderReviewSmcCodes = (_text: string, record: RecordType) => {
    const splitCode = record.item.datum.reviewSmcCodes.split(',');
    const output = splitCode?.map((code) => {
      return <ISocialIcon key={code} network={code} width={24} height={24} />;
    });

    return <Space>{output}</Space>;
  };

  const renderCreateDate = (text: string, record: RecordType) => {
    return utcToDateFormat(record.item.datum.createDate);
  };

  const renderNoApplications = (text: string, record: RecordType) => {
    return (
      <span>{`${record.item.info.currentNoApplications} / ${record.item.info.maxNoApplications}`}</span>
    );
  };

  const renderStage = (text: string, record: RecordType) => {
    return JobOfferStageTag(
      record.item.datum.stage,
      record.item.info.unCheckUploadAmount
    );
  };

  const renderCanApply = (text: string, record: RecordType) => {
    const jobOfferId = record.item.datum.id;
    const canApply = record.item.datum.canApply;
    return (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={canApply}
        onClick={(value) => {
          handleChangeJobApplySwitch(jobOfferId, value);
        }}
      />
    );
  };

  const renderLinks = (text: string, record: RecordType) => {
    const jobOfferId = record.item.datum.id;

    // const onMenuClick: MenuProps['onClick'] = (e) => {
    //   console.log('click', e);
    // };

    const infMenu = (
      <Menu
        items={[
          {
            key: '1',
            label: 'ลิงค์สมัคร',
            onClick: () => genINFRegisterLink(jobOfferId),
          },
          {
            key: '2',
            label: 'ลิงค์อัพโหลด',
            onClick: () => genINFUploadLink(jobOfferId),
          },
        ]}
      />
    );

    const empMenu = (
      <Menu
        items={[
          {
            key: '1',
            label: 'ลิงค์เลือกผู้สมัคร',
            onClick: () => genEMPLink(jobOfferId),
          },
        ]}
      />
    );

    return (
      <>
        <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
          <Space>
            <Dropdown.Button
              overlay={infMenu}
              onClick={() => genINFRegisterLink(jobOfferId)}
            >
              INF
            </Dropdown.Button>
            <Dropdown.Button
              overlay={empMenu}
              onClick={() => genEMPLink(jobOfferId)}
            >
              EMP
            </Dropdown.Button>
          </Space>

          <Button
            block
            icon={<NotificationOutlined />}
            onClick={() => genAnnouncementLink(jobOfferId)}
          >
            ประกาศ
          </Button>
        </Space>
      </>
    );

    // return (
    //   <div>
    //     <Row gutter={8}>
    //       <Col span={12}>
    //         <Button block type='primary' onClick={() => genINFLink(jobOfferId)}>
    //           ลิงค์สมัคร
    //         </Button>
    //       </Col>
    //       <Col span={12}>
    //         <Button block>ลิงค์อัพโหลด</Button>
    //       </Col>
    //     </Row>

    //     <br />
    //     <Row gutter={8}>
    //       <Col span={16}>
    //         <Button block onClick={() => genEMPLink(jobOfferId)}>
    //           ผู้ประกอบการ
    //         </Button>
    //       </Col>
    //       <Col span={8}>
    //         <Button block onClick={() => genAnnouncementLink(jobOfferId)}>
    //           ประกาศ
    //         </Button>
    //       </Col>
    //     </Row>
    //   </div>
    // );
    // return (
    //   <>
    //     <Row justify='space-between' gutter={[0, 8]}>
    //       <Col span={24}>
    //         <Space>
    //           <Button
    //             block
    //             icon={<VideoCameraOutlined />}
    //             onClick={() => genINFLink(jobOfferId)}
    //           >
    //             ลิงค์สมัคร
    //           </Button>
    //         </Space>
    //       </Col>
    //       <Col span={24}>
    //         <Button
    //           block
    //           icon={<AuditOutlined />}
    //           onClick={() => genEMPLink(jobOfferId)}
    //         >
    //           ลิงค์ Employer
    //         </Button>
    //       </Col>
    //       <Col span={24}>
    //         {' '}
    //         <Button
    //           block
    //           icon={<NotificationOutlined />}
    //           onClick={() => genAnnouncementLink(jobOfferId)}
    //         >
    //           ลิ้งค์ประกาศ
    //         </Button>
    //       </Col>
    //     </Row>
    //   </>
    // );
  };

  const renderAction = (text: string, record: RecordType) => {
    const jobOfferStage = record.item.datum.stage;
    return (
      <div style={{ width: '100px' }}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <ActionBtnStyle
              onClick={() => goToSelectCandidatePage(record.item.datum.id)}
              disabled={
                record.item.info.currentNoApplications <
                  record.item.info.maxNoApplications ||
                jobOfferStage === JOB_OFFER_STAGE.COMPLETE
              }
            >
              เลือกผู้สมัคร
            </ActionBtnStyle>
          </Col>
          <Col span={24}>
            <ActionBtnStyle
              onClick={() => goToApproveUploadPage(record.item.datum.id)}
              disabled={jobOfferStage === JOB_OFFER_STAGE.COMPLETE}
            >
              ตรวจสอบ
            </ActionBtnStyle>
          </Col>
          <Col span={24}>
            <ActionBtnStyle
              onClick={() => completeJob(record.item.datum.id)}
              disabled={jobOfferStage === JOB_OFFER_STAGE.COMPLETE}
            >
              จบงาน
            </ActionBtnStyle>
          </Col>
        </Row>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: 'ชื่องาน',
      dataIndex: 'name',
      key: 'name',
      width: 360,
      sorter: () => 0,
      render: renderName,
    },
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: renderCompanyName,
    },
    {
      title: 'กิจกรรม/รีวิว',
      dataIndex: 'reviewSmcCodes',
      key: 'reviewSmcCodes',
      width: 240,
      render: renderReviewSmcCodes,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      key: 'createDate',
      sorter: () => 0,
      render: renderCreateDate,
    },
    {
      title: 'จำนวนผู้สมัคร',
      dataIndex: 'noApplications',
      key: 'noApplications',
      render: renderNoApplications,
    },
    {
      title: 'สถานะ',
      dataIndex: 'stage',
      key: 'stage',
      sorter: () => 0,
      render: renderStage,
    },
    {
      title: 'เปิดรับสมัคร',
      dataIndex: 'canApply',
      key: 'canApply',
      align: 'center',
      render: renderCanApply,
    },
    {
      title: 'สร้างลิงค์',
      dataIndex: 'links',
      key: 'links',
      align: 'center',
      fixed: 'right',
      render: renderLinks,
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: renderAction,
    },
  ];

  function renderName(value: string, record: RecordType) {
    const id = record.item.datum.id;
    return (
      <div>
        <a
          href={`${generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL, { id })}`}
        >
          <Row justify='space-between' gutter={12}>
            <Col span={8}>
              <div
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '80px',
                }}
              >
                <img
                  src={record.item.info.productImageURL}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </Col>
            <Col span={16}>
              {record.item.datum.name}
              <br />
              <TextGrayStyle7>ID: {id}</TextGrayStyle7>
            </Col>
          </Row>
        </a>
      </div>
    );
  }

  function renderCompanyName(value: string, record: RecordType) {
    return <div style={{ width: '200px' }}>{record.item.info.companyName}</div>;
  }

  async function fetchMasterReviewSocialMediaChannel() {
    const options: Partial<MasterList.Request> = {
      limit: { pageNumber: 1, pageSize: 10 },
      category: MASTER_DATA_CATEGORY.SOCIAL_MEDIA_CHANNEL,
    };

    try {
      const response = await masterApi.list(options);
      const selectOptions = response.data.map((item) => ({
        label: item.name,
        value: item.code,
      }));
      setSocialMediaChannelOptions(selectOptions);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  useEffect(() => {
    fetchMasterReviewSocialMediaChannel();
  }, []);

  return <ITable {...props} columns={columns} />;
}

const ActionBtnStyle = styled(Button)`
  width: 100%;
`;
