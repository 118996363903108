export enum API_MESSAGE {
  UNAUTHORIZED = 'unauthorized',
  NOT_FOUND = 'not_found',
  EXPIRED = 'expired',
}

export enum USER_TYPE {
  INFLUENCER = 'influencer',
  EMPLOYER = 'employer',
  BACKOFFICE = 'backoffice',
}

export enum JOB_OFFER_STAGE {
  NEW = 'new',
  HAS_CANDIDATE = 'has_candidate',
  HAS_REVIEWER = 'has_reviewer',
  IN_REVIEW = 'in_review',
  ACCEPT = 'accept',
  FAIL = 'fail',
  COMPLETE = 'complete',
}

export enum JOB_APPLICATION_STAGE {
  APPLY = 'apply',
  IN_REVIEW = 'in_review',
  UPLOAD = 'upload',
  PRE_PASS = 'pre_pass',
  REVISE = 'revise',
  ACCEPT = 'accept',
  FAIL = 'fail',
  COMPLETE = 'complete',
}

export enum REVIEW_SOCIAL_MEDIA_CHANNEL {
  FACEBOOK = 'SMC-FB',
  INSTAGRAM = 'SMC-IG',
  TWITTER = 'SMC-TW',
  TIKTOK = 'SMC-TK',
  YOUTUBE = 'SMC-YT',
}
