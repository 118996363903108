import { ENV } from '@constants/constants';

export interface ConfigENV {
  baseUrl: string;
}

const development = {
  baseURL: 'https://dooq-api-development-elzsljxf7a-de.a.run.app',
  // baseURL: 'http://localhost:8080',
};

const production = {
  baseURL: 'https://api.dooq.org',
};

let envConfig;
switch (process.env.REACT_APP_ENV) {
  case ENV.DEVELOPMENT:
    envConfig = development;
    break;
  case ENV.PRODUCTION:
    envConfig = production;
    break;
  default:
    envConfig = development;
}

const config = {
  // default config
  other: 1,
  ...envConfig,
};

export default config;
