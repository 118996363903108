import { api } from '@core/api/api-client';
import {
  JobProgressCopyFromId,
  JobProgressDetailByJobApplicationId,
  JobProgressDownloadFileInfoByJobApplicationId,
  JobProgressGet,
  JobProgressUpload,
} from '@services/models/jobProgress';
import {
  generateQueryString,
  getEmployerTokenHeader,
  getReviewerTokenHeader,
} from '@utils/apiUtils';
import { generatePath } from 'react-router-dom';

import { ENDPOINT } from '.';

export async function create(req: FormData) {
  // const config: AxiosRequestConfig = {
  //     maxContentLength: 100000000,
  //     maxBodyLength: 1000000000,
  // }

  const res = await api.post(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_PROGRESS_CREATE}`,
    req
  );
  return res.data;
}

export async function detailByJobApplicationId(id: number) {
  const res = await api.get<JobProgressDetailByJobApplicationId.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_PROGRESS_DETAIL_BY_JOB_APPLICATION_ID,
      { id }
    )}`
  );
  return res.data;
}

export async function downloadFileInfoByJobApplicationId(id: number) {
  const res =
    await api.get<JobProgressDownloadFileInfoByJobApplicationId.Response>(
      `${ENDPOINT.BASE_URL}${generatePath(
        ENDPOINT.JOB_PROGRESS_DOWNLOAD_FILE_INFO_BY_JOB_APPLICATION_ID,
        { id }
      )}`
    );
  return res.data;
}

export async function copyFromId(req: JobProgressCopyFromId.Request) {
  const res = await api.post(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_PROGRESS_COPY_FROM_ID}`,
    req
  );
  return res.data;
}

export async function upload(jobId: number, formData: FormData) {
  const config = getReviewerTokenHeader();
  const res = await api.post<JobProgressUpload.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_PROGRESS_REVIEWER_UPLOAD, {
      jobId,
    })}`,
    formData,
    config
  );
  return res.data;
}

export async function reviewerGet(jobId: number, req: JobProgressGet.Request) {
  const config = getReviewerTokenHeader();
  const res = await api.get<JobProgressGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_PROGRESS_REVIEWER_GET, {
      jobId,
    })}?${generateQueryString(req)}`,
    config
  );
  return res.data;
}

export async function employerGet(
  jobId: number,
  applicationId: number,
  req: JobProgressGet.Request
) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobProgressGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_PROGRESS_EMPLOYER_GET, {
      jobId,
      applicationId,
    })}?${generateQueryString(req)}`,
    config
  );
  return res.data;
}

export async function get(
  jobId: number,
  applicationId: number,
  req: JobProgressGet.Request
) {
  const res = await api.get<JobProgressGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_PROGRESS_GET, {
      jobId,
      applicationId,
    })}?${generateQueryString(req)}`
  );
  return res.data;
}
