/* eslint-disable @typescript-eslint/no-explicit-any */
import { InboxOutlined } from '@ant-design/icons';
import { niceBytes } from '@utils/dataUtils';
import { apiUtils, dataUtils } from '@utils/index';
import { message, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadListType } from 'antd/lib/upload/interface';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import * as mime from 'mime-types';
import React, { useState } from 'react';

const mimeImageTypes = [
  mime.lookup('jpeg').toString(),
  mime.lookup('png').toString(),
  mime.lookup('svg').toString(),
];

type Props = {
  name?: string;
  multiple?: boolean;
  maxCount?: number;
  maxSize?: number;
  resizeMaxSizeMB?: number;
  resizeMaxWidthOrHeight?: number;
  // maxResolution?: [width: number, height: number]; // TODO implement optional
  listType?: UploadListType;
  acceptList?: string[];
  value?: UploadFile<any>[]; //TODO value (get from parent)
  onChange?: (fileList: UploadFile<any>[]) => void;
};
export default function DraggerInput(props: Props) {
  const {
    name = 'file',
    multiple = false,
    maxCount,
    maxSize,
    resizeMaxSizeMB = 1,
    resizeMaxWidthOrHeight = 1920,
    listType,
    acceptList,
    // value, // TODO implement required
    onChange,
  } = props;

  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  const triggerChange = (fileList: UploadFile<any>[]) => {
    onChange?.(fileList);
  };

  function getHint() {
    const extensionList = acceptList?.map((e) => mime.extension(e));

    let maxSizeHint = 'ไม่จำกัด';
    if (maxSize) {
      maxSizeHint = niceBytes(maxSize);
    }

    let maxCountHint = 'ไม่จำกัด';
    if (maxCount) {
      maxCountHint = `${maxCount} ไฟล์`;
    }

    return `รองรับไฟล์ ${
      extensionList ?? 'ทุกประเภท'
    } | จำนวน ${maxCountHint} | ขนาดสูงสุด ${maxSizeHint} `;
  }
  async function handleOnChange(info: UploadChangeParam<UploadFile>) {
    // const { status } = info.file;

    const sizeValid = isSizeValid(info.file.size);
    await resizeIfImage(info, info.file);

    if (sizeValid) {
      setFileList(info.fileList);
      triggerChange(info.fileList);
    }

    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  }

  function isSizeValid(size?: number) {
    if (maxSize && size && size > maxSize) {
      const niceBytes = dataUtils.niceBytes(maxSize);
      message.error(`File must smaller than ${niceBytes}!`);
      return false;
    }
    return true;
  }

  async function resizeIfImage(
    info: UploadChangeParam<UploadFile>,
    uploadFile: UploadFile
  ) {
    const index = _.findIndex(info.fileList, { uid: uploadFile.uid });

    //index > -1 is 'Case: add file'
    if (index > -1 && uploadFile.type && uploadFile.size) {
      const isImage = mimeImageTypes.includes(uploadFile.type);

      if (isImage) {
        const imageFile = uploadFile.originFileObj;

        if (imageFile) {
          const imageCompressionOptions = {
            maxSizeMB: resizeMaxSizeMB,
            maxWidthOrHeight: resizeMaxWidthOrHeight,
            useWebWorker: true,
          };

          try {
            const compressedFile = await imageCompression(
              imageFile,
              imageCompressionOptions
            );

            // const base64 = await getBase64(compressedFile as RcFile);
            // console.log({ base64 });

            const newUploadFile: UploadFile<File> = {
              ...uploadFile,
              status: 'done',
              originFileObj: compressedFile as any,
              size: compressedFile.size,
              fileName: uploadFile.name,
              // url: `${'2222'}`,
            };

            info.fileList.splice(index, 1, newUploadFile);
            info.file.status = 'done';
          } catch (error) {
            message.error(`Can not resize image!`);
          }
        }
      }
    }
  }

  return (
    <>
      <Dragger
        fileList={fileList}
        name={name}
        multiple={multiple}
        maxCount={maxCount}
        listType={listType}
        customRequest={apiUtils.dummyRequest}
        onChange={handleOnChange}
        accept={
          acceptList && acceptList.length > 0
            ? acceptList.toString()
            : undefined
        }
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>วางไฟล์หรือคลิกเพื่ออัพโหลด</p>
        <p className='ant-upload-hint'>{getHint()}</p>
      </Dragger>
    </>
  );
}
