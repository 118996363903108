// import { PlusOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import PageHeader from '@components/pages/PageHeader';
import PagePanel from '@components/pages/PagePanel';
import { DEFAULT_PAGE_SIZE, PAGE_CODE } from '@constants/constants';
import { ROUTE } from '@constants/routes';
import ResponsiveDataView from '@pages/backoffice/manages/influencer-links/components/ResponsiveDataView';
import SearchFilter, {
  FormType,
} from '@pages/backoffice/manages/influencer-links/components/SearchFilter';
import { RecordType } from '@pages/backoffice/manages/influencer-links/components/Table';
import { influencerLinkAPI } from '@services/apis';
import { ITablePagination } from '@services/models/common';
import { InfluencerLinkSearch } from '@services/models/influencerLink';
import { Page } from '@services/models/page';
import { failedToFetchAPI } from '@utils/apiUtils';
import { getTableSorter } from '@utils/dataUtils';
import { Button } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface State {
  loadingSearch: boolean;
  searchReq: InfluencerLinkSearch.Request;
  searchResp?: InfluencerLinkSearch.Response;
  tableData: RecordType[];
  tablePagination: ITablePagination;
}

export default function BackofficeManagesInfluencerLinkPage() {
  const history = useHistory();

  const [state, setState] = useState<State>({
    loadingSearch: false,
    searchReq: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
    tableData: [],
    tablePagination: { pageNumber: 1, pageSize: 0, total: 0, totalPages: 0 },
  });

  const showLoadingSearch = () =>
    setState((prev) => ({ ...prev, loadingSearch: true }));
  const hideLoadingSearch = () =>
    setState((prev) => ({ ...prev, loadingSearch: false }));
  const setTablePagination = (page: Page) =>
    setState((prev) => ({ ...prev, tablePagination: page }));

  function goToAddPage() {
    history.push(ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS_ADD);
  }

  function onSearch(values: FormType) {
    fetchSearch({
      ...values,
      pageNumber: 1,
      pageSize: state.tablePagination.pageSize,
    });
  }

  function onChangePagination(
    pageNumber: number,
    pageSize: number,
    total: number,
    totalPages: number
  ) {
    setTablePagination({ pageNumber, pageSize, total, totalPages });
    fetchSearch({ ...state.searchReq, pageNumber, pageSize });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChangeSorterColumn(sorter: any) {
    fetchSearch({ ...state.searchReq, ...getTableSorter(sorter) });
  }

  function onRefresh() {
    fetchSearch({
      ...state.searchReq,
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  }

  function onLoadMore() {
    fetchSearch(
      {
        ...state.searchReq,
        pageNumber: state.tablePagination.pageNumber + 1,
        pageSize: state.tablePagination.pageSize,
      },
      true
    );
  }

  async function fetchSearch(
    req: InfluencerLinkSearch.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoadingSearch();
      setState((prev) => ({ ...prev, searchReq: req }));
      const resp = await influencerLinkAPI.search(req);
      const rows = resp.data?.map((item) => {
        const row: RecordType = {
          key: item.influencerLink.id,
          item: item,
        };
        return row;
      });
      setTablePagination(resp.page);

      if (loadMoreMode) {
        setState((prev) => ({
          ...prev,
          tableData: [...prev.tableData, ...rows],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          tableData: rows,
        }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSearch();
    }
  }

  useEffect(() => {
    fetchSearch({ ...state.searchReq });
  }, []);

  return (
    <LayoutBackoffice currentTab={PAGE_CODE.BACKOFFICE_MANAGE_INFLUENCER_LINK}>
      <PageHeader
        title='ลิงค์สมาชิกอินฟลูเอนเซอร์'
        extra={[
          <Button
            key='add'
            type='primary'
            icon={<PlusOutlined />}
            onClick={goToAddPage}
          >
            เพิ่มลิงค์
          </Button>,
        ]}
      />

      <PagePanel>
        <SearchFilter onSearch={onSearch} loading={state.loadingSearch} />
      </PagePanel>

      <br />
      <ResponsiveDataView
        loading={state.loadingSearch}
        dataSource={state.tableData}
        pagination={state.tablePagination}
        onLoadMore={onLoadMore}
        onRefresh={onRefresh}
        onChangePagination={onChangePagination}
        onChangeSorterColumn={onChangeSorterColumn}
      />
    </LayoutBackoffice>
  );
}
