import PagePermissionHOC from '@components/permissionHoc/PagePermissionHOC';
import { ROUTE } from '@constants/routes';
import * as UserLoginUtils from '@utils/authUtils';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

export default function Authentication(
  props: RouteComponentProps,
  children: React.ReactNode,
  code?: string
) {
  const result = UserLoginUtils.isLogedIn();
  return result ? (
    <PagePermissionHOC code={code}>
      <>{children}</>
    </PagePermissionHOC>
  ) : (
    <Redirect
      to={{
        pathname: ROUTE.LOGIN,
        state: { from: props.location },
      }}
    />
  );
}
