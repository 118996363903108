import CardContent from '@components/card/CardContent';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import Loading from '@components/loading/Loading';
import SectioJobDetail from '@components/section/SectionJobDetail';
import TabApprove from '@components/tabs/TabApprove';
import { PAGE_CODE } from '@constants/constants';
import { JOB_APPLICATION_STAGE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import { jobApplicationApi, jobOfferApi } from '@services/apis';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Breadcrumb, Button, Col, notification, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

export type CommentType = { id: number; comment: string };

interface State {
  loadingJobDetail: boolean;
  loadingSubmit: boolean;
  jobDetailResp?: JobOfferGetDetailById.Response;
  passIdList: number[];
  failIdList: number[];
  commentList: CommentType[];
  flagTabApprove: number;
}

export default function BackofficeManageJobsApproveUploadsPage() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    loadingJobDetail: false,
    passIdList: [],
    failIdList: [],
    commentList: [],
    loadingSubmit: false,
    flagTabApprove: 0,
  });

  const showLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: true }));
  const hideLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: false }));
  const showLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));

  async function onSubmit() {
    try {
      showLoadingSubmit();
      await jobApplicationApi.approveUpload(+id, {
        jobId: +id,
        failList: state.commentList,
        passIds: state.passIdList,
      });
      notification['success']({
        message: 'บันทึกสำเร็จ',
      });
      setState((prev) => ({
        ...prev,
        flagTabApprove: prev.flagTabApprove + 1,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }

  function onPass(id: number) {
    setState((prev) => ({
      ...prev,
      passIdList: [...prev.passIdList, id],
    }));
  }

  function onDepass(id: number) {
    const newList = state.passIdList.filter((e) => e !== id);
    setState((prev) => ({
      ...prev,
      passIdList: newList,
    }));
  }

  function onDefail(id: number) {
    const failIdList = state.failIdList.filter((e) => e !== id);
    const commentList = _.filter(state.commentList, (e) => e.id !== id);
    setState((prev) => ({
      ...prev,
      failIdList,
      commentList,
    }));
  }

  function onFail(id: number, comment: string) {
    setState((prev) => {
      const tmpFailList = _.filter(prev.commentList, (e) => e.id !== id);
      tmpFailList.push({ id, comment });
      return {
        ...prev,
        commentList: tmpFailList,
        failIdList: [...prev.failIdList, id],
      };
    });
  }

  async function fetchJobDetail() {
    try {
      showLoadingJobDetail();
      const res = await jobOfferApi.getDetailById(+id);
      setState((prev) => ({ ...prev, jobDetailResp: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingJobDetail();
    }
  }

  useEffect(() => {
    fetchJobDetail();
    // fetchJobOfferDetail();
    // fetchJobApplicationList();
  }, []);

  return (
    <LayoutBackoffice
      currentTab={PAGE_CODE.BACKOFFICE_MANAGE_JOBS_SELECT_CANDIDATE}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTE.BACKOFFICE_MANAGES_JOBS}>งานรีวิว</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {state.jobDetailResp?.job.name ? (
            <Link
              to={generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_DETAIL, {
                id: state.jobDetailResp.job.id,
              })}
            >
              {state.jobDetailResp?.job.name}
            </Link>
          ) : (
            <Loading loading />
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>ตรวจงานรีวิว</Breadcrumb.Item>
      </Breadcrumb>

      <Content.Wrapper>
        <SectioJobDetail jobId={+id} hideTitle />

        <CardContent>
          <Content.RowHeader justify='space-between'>
            <Content.ColTitleLeft>ตรวจงานรีวิว</Content.ColTitleLeft>
            <Content.ColTitleRight>
              <Content.TitleRightSummary>
                ผ่าน {state.passIdList.length}, ไม่ผ่าน{' '}
                {state.commentList.length}
              </Content.TitleRightSummary>
              <Button
                type='primary'
                size='large'
                shape='round'
                onClick={onSubmit}
                loading={state.loadingSubmit}
                disabled={
                  state.passIdList.length + state.commentList.length === 0
                }
              >
                Submit
              </Button>
            </Content.ColTitleRight>
          </Content.RowHeader>

          <TabApprove
            adminLayout
            key={state.flagTabApprove}
            apiMode='admin'
            stage={JOB_APPLICATION_STAGE.UPLOAD}
            jobId={+id}
            passIdList={state.passIdList}
            failIdList={state.failIdList}
            onPass={onPass}
            onDepass={onDepass}
            onFail={onFail}
            onDefail={onDefail}
          />
        </CardContent>
      </Content.Wrapper>
    </LayoutBackoffice>
  );
}

const Content = {
  Wrapper: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  RowHeader: styled(Row)`
    font-size: 16px;
    margin-bottom: 16px;
  `,
  ColTitleLeft: styled(Col)`
    font-size: 1.4rem;
  `,
  ColTitleRight: styled(Col)`
    text-align: end;
  `,
  TitleRightSummary: styled.div`
    margin-bottom: 12px;
  `,
  RowProgress: styled(Row)`
    margin-bottom: 16px;
  `,
};
