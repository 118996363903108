import CardContent from '@components/card/CardContent';
import { formRuleUtils } from '@utils/index';
import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import styled from 'styled-components';

export interface FormType {
  firstName: string;
  lastName: string;
  phoneNo: string;
  address: string;
}

export const FORM_SCHEMA = {
  firstName: {
    name: 'firstName',
    label: 'ชื่อ',
    rules: formRuleUtils.REQUIRE,
  },
  lastName: {
    name: 'lastName',
    label: 'นามสกุล',
    rules: formRuleUtils.REQUIRE,
  },
  phoneNo: {
    name: 'phoneNo',
    label: 'เบอร์โทร',
    rules: formRuleUtils.PHONE_NO,
  },
  address: {
    name: 'address',
    label:
      'ที่อยู่ผู้สมัครร่วมกิจกรรม/ ที่อยู่สำหรับจัดส่งสินค้า (สำหรับงานรีวิว)',
    rules: formRuleUtils.REQUIRE,
    placeholder:
      'เลขที่บ้าน ชื่อหมู่บ้าน/ร้านค้า - หมู่ - ซอย ซอยแยก (ถ้ามี) ชื่อถนน - แขวงหรือตำบล เขตหรืออำเภอ - จังหวัด และ รหัสไปรษณีย์',
  },
};

type Props = {
  onSuccess: (values: FormType) => void;
  form: FormInstance;
};

export default function CardUpdateAddress(props: Props) {
  const { form, onSuccess } = props;

  function onFinish(values: FormType) {
    onSuccess(values);
  }

  return (
    <CardContent>
      <Content.Title>
        ที่อยู่ผู้สมัครร่วมกิจกรรม/ ที่อยู่สำหรับจัดส่งสินค้า (สำหรับงานรีวิว)
      </Content.Title>

      <Content.SectionForm>
        <Form
          scrollToFirstError
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item {...FORM_SCHEMA.firstName}>
            <Input disabled />
          </Form.Item>
          <Form.Item {...FORM_SCHEMA.lastName}>
            <Input disabled />
          </Form.Item>
          <Form.Item {...FORM_SCHEMA.phoneNo}>
            <Input disabled />
          </Form.Item>
          <Form.Item {...FORM_SCHEMA.address}>
            <Input.TextArea
              placeholder={FORM_SCHEMA.address.placeholder}
              rows={4}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type='primary'
              htmlType='submit'
              size='large'
              shape='round'
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </Content.SectionForm>
    </CardContent>
  );
}

const Content = {
  Title: styled.div`
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.2rem;
  `,
  SectionForm: styled.div``,
};
