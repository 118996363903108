import { ITableProps } from '@components/helper/ITable';
import IConfirmModal from '@components/IConfirmModal';
import PagePanel from '@components/pages/PagePanel';
import { ROUTE } from '@constants/routes';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import CardList from '@pages/backoffice/manages/jobs/components/CardList';
import {
  RecordType,
  Table,
} from '@pages/backoffice/manages/jobs/components/Table';
import { jobOfferApi } from '@services/apis';
import { ITablePagination } from '@services/models/common';
import {
  JobOfferChangeCanApply,
  JobOfferComplete,
} from '@services/models/jobOffer';
import {
  genAnnouncementLink,
  genEMPLink,
  genINFRegisterLink,
  genINFUploadLink,
} from '@utils/actionUtils';
import { failedToFetchAPI } from '@utils/apiUtils';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {
  loading?: boolean;
  dataSource: RecordType[];
  pagination: ITablePagination;
  onLoadMore: () => void;
  onRefresh: () => void;
};
export default function ResponsiveDataView(props: ITableProps & Props) {
  const {
    loading,
    dataSource,
    pagination,
    onLoadMore,
    onRefresh,
    onChangePagination,
    onChangeSorterColumn,
  } = props;

  const { isDesktop } = useResponsiveContext();

  const history = useHistory();

  const goToSelectCandidate = (id: number) => {
    history.push(
      generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_SELECT_CANDIDATES, { id })
    );
  };

  const goToApproveUpload = (id: number) => {
    history.push(
      generatePath(ROUTE.BACKOFFICE_MANAGES_JOBS_APPROVE_UPLOADS, {
        id,
      })
    );
  };

  function completeJob(id: number) {
    IConfirmModal({
      type: 'confirm',
      title: 'ยืนยันการจบงาน ?',
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        try {
          const request: JobOfferComplete.Request = {
            jobOfferId: id,
            // updateById: getLogedInUser()?.id!,
            //TODO remove updateById
          };
          const res = await jobOfferApi.complete(request);
          if (res.status) {
            onRefresh();
          }
        } catch (error) {
          failedToFetchAPI(error);
        }
      },
    });
  }

  async function handleChangeJobApplySwitch(id: number, canApply: boolean) {
    try {
      const request: JobOfferChangeCanApply.Request = {
        id: id,
        canApply: canApply,
      };
      await jobOfferApi.changeCanApply(request);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  return (
    <div>
      {isDesktop ? (
        <PagePanel>
          <Table
            loading={loading}
            dataSource={dataSource}
            pagination={pagination}
            onChangePagination={onChangePagination}
            onChangeSorterColumn={onChangeSorterColumn}
            onRefresh={onRefresh}
            genINFRegisterLink={genINFRegisterLink}
            genINFUploadLink={genINFUploadLink}
            genEMPLink={genEMPLink}
            genAnnouncementLink={genAnnouncementLink}
            goToSelectCandidatePage={goToSelectCandidate}
            goToApproveUploadPage={goToApproveUpload}
            completeJob={completeJob}
            handleChangeJobApplySwitch={handleChangeJobApplySwitch}
          />
        </PagePanel>
      ) : (
        <CardList
          loading={loading}
          dataSource={dataSource}
          pagination={pagination}
          onLoadMore={onLoadMore}
          onRefresh={onRefresh}
          genINFRegisterLink={genINFRegisterLink}
          genINFUploadLink={genINFUploadLink}
          genEMPLink={genEMPLink}
          genAnnouncementLink={genAnnouncementLink}
          goToSelectCandidatePage={goToSelectCandidate}
          goToApproveUploadPage={goToApproveUpload}
          completeJob={completeJob}
          handleChangeJobApplySwitch={handleChangeJobApplySwitch}
        />
      )}
    </div>
  );
}
