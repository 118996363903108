import { ROUTE } from '@constants/routes';
import { AuthContext } from '@contexts/AuthContext';
import { authApi } from '@services/apis';
import * as userLoginUtils from '@utils/authUtils';
import { logout } from '@utils/authUtils';
import { notifyFailed } from '@utils/notifyUtils';
import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  children?: React.ReactElement[] | React.ReactElement;
  code?: string;
};

function PagePermissionHOC(props: Props) {
  const { children } = props;
  const { loggedInUser, setLogedInUser } = useContext(AuthContext);
  const [result, setResult] = useState(false);

  const history = useHistory();
  const user = userLoginUtils.getLogedInUser();

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      setResult(true);
    } else {
      fetchUserLoginByToken();
    }
  }, []);

  async function fetchUserLoginByToken() {
    try {
      setResult(true);

      if (user) {
        const res = await authApi.getMe(user.token.refreshToken);
        if (res.status) {
          setLogedInUser(res.data);
        }
      } else {
        notifyFailed('no user');
      }
    } catch (error) {
      notification['error']({
        message: 'Cannot call menu permission',
        description: 'กรุณาลองใหม่อีกครั้ง',
      });
      logout();
      history.replace(ROUTE.LOGIN);
    }
  }

  return result ? <>{React.cloneElement(<>{children}</>)}</> : <>Loading...</>;
}

export default PagePermissionHOC;
