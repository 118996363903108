import { Result } from 'antd';
import React from 'react';

type Props = {
  params: string[];
};
export default function InvalidParams(props: Props) {
  const { params } = props;

  return (
    <Result
      status='500'
      title='Invalid Parameters'
      subTitle={`Sorry, ${params.toString()} is invalid format, please check`}
    />
  );
}
