import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

type RecordType = { label: ReactNode; value: ReactNode };

type Props = {
  rows: RecordType[];
};
export default function SummaryCard(props: Props) {
  const { rows = [] } = props;

  return (
    <CardStyle.Wrapper>
      {rows.map((e, index) => (
        <CardStyle.Row key={index}>
          <CardStyle.ColLeft>{e.label}</CardStyle.ColLeft>
          <CardStyle.ColRight>{e.value}</CardStyle.ColRight>
        </CardStyle.Row>
      ))}
    </CardStyle.Wrapper>
  );
}

const CardStyle = {
  Wrapper: styled.div`
    background-color: white;
    border-radius: 24px;
    padding: 12px;
  `,
  Row: styled(Row)`
    justify-content: space-between;
    line-height: 32px;
  `,
  ColLeft: styled(Col)`
    font-size: calc(var(--base-font-size) - 2px);
    color: var(--gray-color-6);
  `,
  ColRight: styled(Col)``,
};
