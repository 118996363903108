import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import CardContent from '@components/card/CardContent';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import Loading from '@components/loading/Loading';
import TabSelectInfluencer from '@components/tabs/TabSelectInfluencer';
import { TextGrayStyle7 } from '@components/text/IStyleComponent';
import { ROUTE } from '@constants/routes';
import { SelectionType } from '@pages/backoffice/manages/influencer-links/add';
import { influencerLinkAPI } from '@services/apis';
import { LabelValue } from '@services/models/common';
import { InfluencerLinkGetById } from '@services/models/influencerLink';
import { copyLinkToClipboard } from '@utils/actionUtils';
import { failedToFetchAPI } from '@utils/apiUtils';
import { getHostname } from '@utils/helpsUtils';
import { formRuleUtils } from '@utils/index';
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

export interface FormType {
  name: string;
  description: string;
  userIds: number[];
}

const FORM_SCHEMA = {
  userIds: { name: 'userIds', label: 'userIds' },
  name: { name: 'name', label: 'ชื่อลิงค์', rules: formRuleUtils.REQUIRE },
  description: { name: 'description', label: 'คำอธิบาย' },
  selectionType: {
    name: 'selectionType',
    label: 'รูปแบบการเลือก',
    rules: [...formRuleUtils.REQUIRE],
  },
};

interface State {
  loading: boolean;
  loadingSubmit: boolean;
  resp?: InfluencerLinkGetById.Response;
  initialSelectedUserIds: number[];
  selectedUserIds: number[];
  addIds: number[];
  removeIds: number[];
  token?: string;
  selectionType: SelectionType;
}

export default function BackofficeManagesInfluencerLinkEditPage() {
  const { id }: { id: string } = useParams();

  const [form] = Form.useForm();

  const [state, setState] = useState<State>({
    loading: false,
    loadingSubmit: false,
    initialSelectedUserIds: [],
    selectedUserIds: [],
    addIds: [],
    removeIds: [],
    selectionType: undefined,
  });

  const selectionTypeOptions: LabelValue[] = [
    { label: 'กำหนดเอง', value: 'custom' },
    {
      label: 'ทั้งหมด (เฉพาะอินฟลูเอนเซอร์ที่อยู่ในระบบ​ ณ ปัจจุบัน)',
      value: 'all',
    },
    {
      label: 'ทั้งหมด (เพิ่มอินฟลูเอนเซอร์ใหม่อัติโนมัติ)',
      value: 'allWithAutoAddNewInfluencers',
    },
  ];

  const showLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: true }));
  const hideLoadingSubmit = () =>
    setState((prev) => ({ ...prev, loadingSubmit: false }));
  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onSelect(id: number) {
    setState((prev) => {
      const selectedUserIds = [...prev.selectedUserIds, id];
      return {
        ...prev,
        selectedUserIds,
      };
    });
  }

  function onDeselect(id: number) {
    setState((prev) => {
      const selectedUserIds = prev.selectedUserIds.filter((e) => e !== id);
      return {
        ...prev,
        selectedUserIds,
      };
    });
  }

  async function onFinish(values: FormType) {
    const removeIds = _.differenceBy(
      state.initialSelectedUserIds,
      state.selectedUserIds
    );

    const addIds = _.filter(
      state.selectedUserIds,
      (e) => !state.initialSelectedUserIds.includes(e)
    );

    try {
      showLoadingSubmit();
      const resp = await influencerLinkAPI.update({
        ...values,
        id: +id,
        removeIds,
        addIds,
      });

      if (resp.updatedAt) {
        notification['success']({
          message: 'บันทึกสำเร็จ',
        });
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingSubmit();
    }
  }

  function onCopyURL() {
    copyLinkToClipboard(generateURL(state.token ?? ''));
  }

  async function onRefreshLink() {
    try {
      setState((prev) => ({ ...prev, token: undefined }));
      if (state.resp?.influencerLink.id) {
        const resp = await influencerLinkAPI.refreshLink({
          id: state.resp.influencerLink.id,
        });
        setState((prev) => ({ ...prev, token: resp.newToken }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      //
    }
  }

  async function fetchData() {
    try {
      showLoading();
      const resp = await influencerLinkAPI.getById(+id);

      const selectionType = resp.influencerLink.isAutoAddNewInfluencers
        ? 'allWithAutoAddNewInfluencers'
        : 'custom';
      form.setFieldsValue({
        [FORM_SCHEMA.name.name]: resp.influencerLink.name,
        [FORM_SCHEMA.description.name]: resp.influencerLink.description,
        [FORM_SCHEMA.selectionType.name]: selectionType,
      });

      setState((prev) => ({
        ...prev,
        resp: resp,
        selectedUserIds: resp.userIds,
        initialSelectedUserIds: resp.userIds,
        token: resp.influencerLink.token,
        selectionType: selectionType,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LayoutBackoffice>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTE.BACKOFFICE_MANAGES_INFLUENCER_LINKS}>
            ลิงค์สมาชิกอินฟลูเอนเซอร์
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>แก้ไข</Breadcrumb.Item>
      </Breadcrumb>

      <Content.Wrapper>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <CardContent title='ฟอร์มอัพเดทลิงค์สมาชิกอินฟลูเอนเซอร์'>
              <Loading loading={state.loading}>
                <Form form={form} layout='vertical' onFinish={onFinish}>
                  <Form.Item {...FORM_SCHEMA.name}>
                    <Input placeholder={FORM_SCHEMA.name.label} />
                  </Form.Item>

                  <Form.Item {...FORM_SCHEMA.selectionType}>
                    <Select
                      disabled
                      placeholder={FORM_SCHEMA.selectionType.label}
                    >
                      {selectionTypeOptions.map((e) => (
                        <Select.Option key={e.value} value={e.value}>
                          {e.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item {...FORM_SCHEMA.description}>
                    <Input.TextArea
                      rows={3}
                      placeholder={FORM_SCHEMA.description.label}
                    />
                  </Form.Item>

                  {/* <Form.Item name='autoAdd' valuePropName='checked'>
              <Checkbox>เพิ่มอินฟลูเอนเซอร์ใหม่อัตโนมัติ</Checkbox>
            </Form.Item> */}

                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={state.loadingSubmit}
                    >
                      บันทึก
                    </Button>
                  </Form.Item>
                </Form>
              </Loading>
            </CardContent>
          </Col>
          <Col xs={24} xl={12}>
            <CardContent title='ข้อมูลลิงค์'>
              <Descriptions bordered>
                <Descriptions.Item
                  label={
                    <Space>
                      <Button
                        icon={<ReloadOutlined />}
                        danger
                        onClick={onRefreshLink}
                      >
                        Refresh
                      </Button>
                      <Button
                        icon={<CopyOutlined />}
                        onClick={onCopyURL}
                      ></Button>
                    </Space>
                  }
                >
                  {state.token ? generateURL(state.token) : <Loading loading />}
                </Descriptions.Item>
              </Descriptions>
            </CardContent>
          </Col>
        </Row>

        {state.selectionType === 'custom' && (
          <CardContent
            title={
              <Row justify='space-between'>
                <Col>อินฟลูเอนเซอร์</Col>
                <Col>
                  <TextGrayStyle7>
                    จำนวนที่เลือก: {state.selectedUserIds.length}
                  </TextGrayStyle7>
                </Col>
              </Row>
            }
          >
            <TabSelectInfluencer
              selectedUserIds={state.selectedUserIds}
              onSelect={onSelect}
              onDeselect={onDeselect}
            />
          </CardContent>
        )}
      </Content.Wrapper>
    </LayoutBackoffice>
  );
}

const Content = {
  Wrapper: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

export function generateURL(token: string) {
  return `${getHostname()}${generatePath(`${ROUTE.INFLUENCER_LINK}`, {
    token,
  })}`;
}
