import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import React, { ReactNode } from 'react';

type Props = {
  fontSize?: number | string;
  size?: SpinSize;
  loading?: boolean;
  children?: ReactNode;
};
export default function Loading(props: Props) {
  const { fontSize: fontSize = 24, size, loading = false, children } = props;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: fontSize,
      }}
      spin
    />
  );

  return (
    <Spin indicator={antIcon} spinning={loading} size={size}>
      {children}
    </Spin>
  );
}
