import { ROUTE } from '@constants/routes';
import { User, UserLogin } from '@services/models/user';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
interface AuthContextValues {
  loggedInUser: User | null;
  setLogedInUser: (user: UserLogin | null) => void;
}

const initialState: AuthContextValues = {
  loggedInUser: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLogedInUser: () => {},
};

export const AuthContext = createContext<AuthContextValues>(initialState);

export function useAuthContext() {
  return useContext(AuthContext);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthProvider({ children }: any) {
  const history = useHistory();

  const [loggedInUser, setAuthUser] = useState<UserLogin | null>(null);

  useEffect(() => {
    const syncSignout = (e: StorageEvent) => {
      if (e.key === 'signout') {
        setAuthUser(null);
        history.replace(ROUTE.HOME);
      }
    };

    window.addEventListener('storage', syncSignout);
    return () => window.removeEventListener('storage', syncSignout);
  }, []);

  return (
    <AuthContext.Provider value={{ loggedInUser, setLogedInUser: setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
}
