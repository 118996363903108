/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';
interface ResponsiveContextValues {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isNotMobile: boolean;
  isDesktopOrTablet: boolean;
  setIsDesktop: (value: boolean) => void;
  setIsTablet: (value: boolean) => void;
  setIsMobile: (value: boolean) => void;
  setIsNotMobile: (value: boolean) => void;
  setIsDesktopOrTablet: (value: boolean) => void;
}

const initialState: ResponsiveContextValues = {
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  isNotMobile: false,
  isDesktopOrTablet: false,
  setIsDesktop: () => {},
  setIsTablet: () => {},
  setIsMobile: () => {},
  setIsNotMobile: () => {},
  setIsDesktopOrTablet: () => {},
};

export const ResponsiveContext =
  createContext<ResponsiveContextValues>(initialState);

export function useResponsiveContext() {
  return useContext(ResponsiveContext);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ResponsiveProvider({ children }: any) {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isNotMobile, setIsNotMobile] = useState<boolean>(false);
  const [isDesktopOrTablet, setIsDesktopOrTablet] = useState<boolean>(false);

  return (
    <ResponsiveContext.Provider
      value={{
        isDesktop,
        isTablet,
        isMobile,
        isNotMobile,
        isDesktopOrTablet,
        setIsDesktop,
        setIsTablet,
        setIsMobile,
        setIsNotMobile,
        setIsDesktopOrTablet,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
}
