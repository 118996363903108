import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import { JOB_OFFER_STAGE } from '@constants/enum';
import { ROUTE } from '@constants/routes';
import { Button, Col, Row, Steps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

export interface LocationStateJobEmployerWaitingPage {
  step: JOB_OFFER_STAGE | string;
}

type ItemType = { title: string; description: ReactNode };
export default function JobEmployerWaitingPage() {
  const { id }: { id: string } = useParams();

  const location = useLocation();
  const locationState = location.state as LocationStateJobEmployerWaitingPage;
  const history = useHistory();

  const [current, setCurrent] = useState(0);

  const items: ItemType[] = [
    {
      title: 'สร้างงานรีวิวสำเร็จ',
      description: 'ระบบกำลังเปิดรับสมัคร Influencer.',
    },
    {
      title: 'เจ้าหน้าที่กำลังคัดเลือก Influencer',
      description:
        'เจ้าหน้าที่กำลังตรวจสอบและคัดเลือก Influencers ที่เหมาะสมที่สุดให้ท่าน. โปรดเข้ามาตรวจสอบอีกครั้งภายหลัง.',
    },
    {
      title: 'เลือกรีวิวเวอร์แล้ว',
      description: (
        <>
          รีวิวเวอร์กำลังรีวิวสินค้า.{' '}
          <Button
            type='link'
            onClick={goToUploadReviewPage}
            style={{ padding: '0' }}
          >
            ไปหน้ารีวิวผลงาน
          </Button>
        </>
      ),
    },
    {
      title: 'สำเร็จ',
      description: 'สำเร็จ',
    },
  ];

  function goToUploadReviewPage() {
    history.push(generatePath(ROUTE.JOB_EMPLOYER_REVIEW_UPLOAD, { id }));
  }

  function getCurrent() {
    let tmpCurrent = 0;
    switch (locationState.step) {
      case JOB_OFFER_STAGE.NEW:
      case JOB_OFFER_STAGE.HAS_CANDIDATE:
        tmpCurrent = 1;
        break;
      case JOB_OFFER_STAGE.HAS_REVIEWER:
        tmpCurrent = 2;
        break;
      case JOB_OFFER_STAGE.IN_REVIEW:
        tmpCurrent = 3;
        break;
      case JOB_OFFER_STAGE.COMPLETE:
      case JOB_OFFER_STAGE.ACCEPT:
        tmpCurrent = 4;
        break;
    }

    setCurrent(tmpCurrent);
  }

  useEffect(() => {
    getCurrent();
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Row justify='center'>
        <Col xs={24} md={18} lg={14} xl={12}>
          <CardContent>
            <h1>สถานะงานงานรีวิว</h1>
            <Steps direction='vertical' current={current}>
              {items.map((e) => (
                <Steps.Step key={e.title} {...e} />
              ))}
            </Steps>
          </CardContent>
        </Col>
      </Row>
    </LayoutPublic>
  );
}
