export function newTab(url: string) {
  window.open(url);
}

export function scrollToRef(ref: React.RefObject<HTMLDivElement>) {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
}

export function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}
