import FormButtonController from '@components/form/FormButtonController';
import PagePanelTitle from '@components/pages/PagePanelTitle';
import { JOB_OFFER_STAGE, REVIEW_SOCIAL_MEDIA_CHANNEL } from '@constants/enum';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useState } from 'react';

export interface FormType {
  name: string;
}
const FORM_SCHEMA = {
  name: { name: 'name', label: 'ชื่องานรีวิว' },
  reviewSmcCodes: { name: 'reviewSmcCodes', label: 'กิจกรรม/รีวิว' },
  stage: { name: 'stage', label: 'สถานะ' },
};

type Props = {
  onSearch: (values: FormType) => void;
  loading: boolean;
};
export default function SearchFilter(props: Props) {
  const { onSearch, loading } = props;

  const [form] = Form.useForm();

  const { isMobile } = useResponsiveContext();

  const [showAdvanceItem, setShowAdvanceItem] = useState(false);

  function onFinish(values: FormType) {
    onSearch(values);
  }

  function onClear() {
    form.resetFields();
    onSearch(form.getFieldsValue());
  }

  return (
    <div>
      <PagePanelTitle
        title='ค้นหา'
        extra={[
          <>
            {isMobile && (
              <Switch
                key='advance-searh-mode'
                checkedChildren='ขั้นสูง'
                unCheckedChildren='พื้นฐาน'
                onChange={(value) => setShowAdvanceItem(isMobile && value)}
              />
            )}
          </>,
        ]}
      />

      <Form
        scrollToFirstError
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item {...FORM_SCHEMA.name}>
              <Input placeholder={FORM_SCHEMA.name.label} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8} hidden={isMobile && !showAdvanceItem}>
            <Form.Item {...FORM_SCHEMA.reviewSmcCodes}>
              <Select placeholder={FORM_SCHEMA.reviewSmcCodes.label}>
                {/* TODO fetch from API */}
                {/* {dropdownShopRes?.data?.map((e) => (
                    <Select.Option value={e.shop.id}>{e.shop.name}</Select.Option>
                  ))} */}
                <Select.Option value={REVIEW_SOCIAL_MEDIA_CHANNEL.FACEBOOK}>
                  Facebook
                </Select.Option>
                <Select.Option value={REVIEW_SOCIAL_MEDIA_CHANNEL.INSTAGRAM}>
                  Instagram
                </Select.Option>
                <Select.Option value={REVIEW_SOCIAL_MEDIA_CHANNEL.TIKTOK}>
                  Tiktok
                </Select.Option>
                <Select.Option value={REVIEW_SOCIAL_MEDIA_CHANNEL.TWITTER}>
                  Twitter
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8} hidden={isMobile && !showAdvanceItem}>
            <Form.Item {...FORM_SCHEMA.stage}>
              <Select placeholder={FORM_SCHEMA.stage.label}>
                <Select.Option value='all'>ทั้งหมด</Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.NEW}>ใหม่</Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.HAS_CANDIDATE}>
                  Has Candidate
                </Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.HAS_REVIEWER}>
                  Has Reviewer
                </Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.IN_REVIEW}>
                  กำลังรีวิว
                </Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.ACCEPT}>
                  ผ่าน
                </Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.FAIL}>
                  ล้มเหลว
                </Select.Option>
                <Select.Option value={JOB_OFFER_STAGE.COMPLETE}>
                  สำเร็จ
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <Form.Item label=' '>
              <FormButtonController onClear={onClear} loading={loading} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
