import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import CardConfirmation, {
  INFConfirmData,
} from '@pages/job/[id]/influencer/register/confirm/components/CardConfirmation';
import CardUpdateAddress, {
  FORM_SCHEMA,
  FormType,
} from '@pages/job/[id]/influencer/register/confirm/components/CardUpdateAddress';
import { influencerAPI, jobOfferApi } from '@services/apis';
import { InfluencerGetBasicInfo } from '@services/models/influencer';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { scrollToTop } from '@utils/browserUtils';
import { Col, Form, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export interface JobInfluencerRegisterConfirmPageLocationState {
  userId: number;
}

interface Param {
  id: string;
}

interface State {
  loadingData: boolean;
  infInfo?: InfluencerGetBasicInfo.ResponseData;
  jobDetail?: JobOfferGetDetailById.Response;
  infConfirmData?: INFConfirmData;
}
export default function JobInfluencerRegisterConfirmPage() {
  const { id }: Param = useParams();

  const location = useLocation();
  const locationState =
    location.state as JobInfluencerRegisterConfirmPageLocationState;

  const [state, setState] = useState<State>({
    loadingData: false,
  });

  const [form] = Form.useForm();

  const showLoadingData = () =>
    setState((prev) => ({ ...prev, loadingData: true }));
  const hideLoadingData = () =>
    setState((prev) => ({ ...prev, loadingData: false }));

  const clearINFConfirmData = () =>
    setState((prev) => ({ ...prev, infConfirmData: undefined }));

  function onSuccessUpdateAddress(values: FormType) {
    scrollToTop();
    setState((prev) => ({
      ...prev,
      infConfirmData: { ...values, userId: locationState.userId },
    }));
  }

  function initialFormValeus(inf: InfluencerGetBasicInfo.ResponseData) {
    form.setFieldsValue({
      [FORM_SCHEMA.firstName.name]: inf.firstName,
      [FORM_SCHEMA.lastName.name]: inf.lastName,
      [FORM_SCHEMA.phoneNo.name]: inf.phoneNo,
      [FORM_SCHEMA.address.name]: inf.address,
    });
  }

  async function fetchINFBasicInfo() {
    try {
      showLoadingData();
      const res = await influencerAPI.getBasicInfoByUserId(
        locationState.userId
      );

      if (res.data) {
        initialFormValeus(res.data);
      } else {
        message.error(`ไม่พบข้อมูล`);
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingData();
    }
  }

  async function fetchJobDetail() {
    try {
      showLoadingData();

      const res = await jobOfferApi.getDetailById(+id);
      setState((prev) => ({ ...prev, jobDetail: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingData();
    }
  }

  useEffect(() => {
    fetchINFBasicInfo();
    fetchJobDetail();
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Row justify='center'>
        <Col xs={24} md={18} lg={14} xl={12}>
          <Loading loading={state.loadingData}>
            {state.jobDetail && state.infConfirmData ? (
              <CardConfirmation
                jobDetail={state.jobDetail}
                infConfirmData={state.infConfirmData}
                onBack={clearINFConfirmData}
              />
            ) : (
              <CardUpdateAddress
                onSuccess={onSuccessUpdateAddress}
                form={form}
              />
            )}
          </Loading>
        </Col>
      </Row>
    </LayoutPublic>
  );
}
