import { Modal, ModalFuncProps } from 'antd';
import React from 'react';

type Props = ModalFuncProps & {
  // type: 'confirm' | 'warning' | 'danger';
  title?: string;
  icon?: React.ReactNode;
  message?: string;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  afterClose?: () => void;
};

function IConfirmModal(props: Props) {
  const {
    type,
    title,
    icon,
    message,
    okText,
    cancelText,
    onOk,
    onCancel,
    afterClose,
  } = props;

  let primaryBtnColor = '';

  switch (type) {
    case 'confirm':
      primaryBtnColor = 'var(--primary-color)';
      break;
    case 'warning':
      primaryBtnColor = '#ff860d';
      break;
  }
  return Modal.confirm({
    style: { textAlign: 'center' },
    title: title,
    icon: icon,
    content: <a style={{ color: 'black' }}>{message}</a>,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
    afterClose: afterClose,
    okButtonProps: {
      style: {
        width: '48%',
        height: '48px',
        borderRadius: '8px',
        backgroundColor: primaryBtnColor,
        borderColor: 'unset',
      },
    },
    cancelButtonProps: {
      style: { width: '48%', height: '48px', borderRadius: '8px' },
    },
    className: 'custom-confirm-modal',
  });
}

export default IConfirmModal;
