import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import { MasterList } from '@services/models/masterList';

export async function list(req: Partial<MasterList.Request>) {
  const res = await api.post<MasterList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.MASTER_DATA_LIST}`,
    req
  );
  return res.data;
}
