// export const URL = config.apiURL;
// export const WEB = config.webURL;
// export const BASE = `${URL}/api`;

import config from '@configs/index';

export const BASE_URL = `${config.baseURL}/api`;

///auth
export const AUTH_BASE_URL = '/auth';
export const AUTH_ME = `${AUTH_BASE_URL}/me`;
export const AUTH_BACKOFFICE_LOGIN = `${AUTH_BASE_URL}/backoffice/login`;
export const AUTH_CHECK_EXISTS = `${AUTH_BASE_URL}/check-exists`;
export const AUTH_REGISTER_EMPLOYER = `${AUTH_BASE_URL}/register/employer`;
export const AUTH_REGISTER_INFLUENCER = `${AUTH_BASE_URL}/register/influencer`;

//user
export const USER_BASE_URL = '/user';
export const USER_CHECK_EXISTS = `${USER_BASE_URL}/check-exists`;
export const USER_LOGIN = `${USER_BASE_URL}/login`;
export const USER_INFLUENCER_UPLOAD_COMP_CARD = `${USER_BASE_URL}/influencer/upload-comp-card`;
export const USER_INFLUENCER_GET_COMP_CARD_BY_USER_ID = `${USER_BASE_URL}/influencer/get-comp-card/:id`;
export const USER_INFLUENCER_UPDATE_ADDRESS = `${USER_BASE_URL}/influencer/update-address`;
export const USER_INFLUENCER_DETAIL_BY_USER_ID = `${USER_BASE_URL}/influencer/detail/:id`;

//product
export const PRODUCT_BASE_URL = '/product';
export const PRODUCT_LIST = `${PRODUCT_BASE_URL}/list`;
export const PRODUCT_CREATE = `${PRODUCT_BASE_URL}/create`;
export const PRODUCT_DETAIL_BY_ID = `${PRODUCT_BASE_URL}/detail/:id`;
export const PRODUCT_UPLOAD_FILE = `${PRODUCT_BASE_URL}/upload-file`;

//master-data
export const MASTER_DATA_BASE_URL = '/master-data';
export const MASTER_DATA_LIST = `${MASTER_DATA_BASE_URL}/list`;

//review-package
export const REVIEW_PACKAGE_BASE_URL = '/review-package';
export const REVIEW_PACKAG_LIST = `${REVIEW_PACKAGE_BASE_URL}/list`;

//job-offers
export const JOB_OFFERS_BASE_URL = '/job-offers';
export const JOB_OFFERS_LIST_SUMMARY = `${JOB_OFFERS_BASE_URL}/list-summary`;
export const JOB_OFFERS_CREATE = `${JOB_OFFERS_BASE_URL}/create`;
export const JOB_OFFERS_UPDATE = `${JOB_OFFERS_BASE_URL}/update`;
export const JOB_OFFERS_LIST = `${JOB_OFFERS_BASE_URL}/list`;
export const JOB_OFFERS_DEATAIL_BY_ID = `${JOB_OFFERS_BASE_URL}/detail/:id`;
export const JOB_OFFERS_SELECT_CANDIDATE = `${JOB_OFFERS_BASE_URL}/select-candidate`;
export const JOB_OFFERS_SELECT_REVIEWER = `${JOB_OFFERS_BASE_URL}/select-reviewer`;
export const JOB_OFFERS_UPDATE_TRACKING_NO = `${JOB_OFFERS_BASE_URL}/update-tracking-no`;
export const JOB_OFFERS_BO_ALL_UPLOAD_PASS = `${JOB_OFFERS_BASE_URL}/bo-all-upload-pass`;
export const JOB_OFFERS_COMPLETE = `${JOB_OFFERS_BASE_URL}/complete`;
export const JOB_OFFERS_ANNOUNCEMENT_OF_REVIEWER_BY_ID = `${JOB_OFFERS_BASE_URL}/announcement-of-reviewer/:id`;
export const JOB_OFFERS_GET_ACCESS_LINK_BY_ID = `${JOB_OFFERS_BASE_URL}/get-access-link/:id`;
export const JOB_OFFERS_VERIFY_ACCESS_LINK = `${JOB_OFFERS_BASE_URL}/verify-access-link/`;
export const JOB_OFFERS_CHANGE_CAN_APPLY = `${JOB_OFFERS_BASE_URL}/change-can-apply/`;
export const JOB_OFFERS_IS_APPLICATION_EXIST = `${JOB_OFFERS_BASE_URL}/is-application-eixists`;

export const JOB_OFFERS_GET_DEATAIL_BY_ID = `${JOB_OFFERS_BASE_URL}/:id/detail`;

//job-progress
export const JOB_PROGRESS_BASE_URL = '/job-progresses';
export const JOB_PROGRESS_CREATE = `${JOB_PROGRESS_BASE_URL}/create`;
export const JOB_PROGRESS_GET = `${JOB_PROGRESS_BASE_URL}/job/:jobId/application/:applicationId`;
export const JOB_PROGRESS_REVIEWER_GET = `${JOB_PROGRESS_BASE_URL}/reviewer/job/:jobId`;
export const JOB_PROGRESS_REVIEWER_UPLOAD = `${JOB_PROGRESS_BASE_URL}/reviewer/job/:jobId/upload`;
export const JOB_PROGRESS_EMPLOYER_GET = `${JOB_PROGRESS_BASE_URL}/employer/job/:jobId/application/:applicationId`;
export const JOB_PROGRESS_DETAIL_BY_JOB_APPLICATION_ID = `${JOB_PROGRESS_BASE_URL}/detail-by-job-application-id/:id`;
export const JOB_PROGRESS_DOWNLOAD_FILE_INFO_BY_JOB_APPLICATION_ID = `${JOB_PROGRESS_BASE_URL}/download-file-info-by-job-application-id/:id`;
export const JOB_PROGRESS_COPY_FROM_ID = `${JOB_PROGRESS_BASE_URL}/copy-from-id`;

//job-application
export const JOB_APPLICATION_BASE_URL = '/job-applications';
export const JOB_APPLICATION_GET_CANDIDATES = `${JOB_APPLICATION_BASE_URL}/job/:jobId/candidates`;
export const JOB_APPLICATION_GET_TABS_BY_JOB_ID = `${JOB_APPLICATION_BASE_URL}/job/:jobId/tabs`;
export const JOB_APPLICATION_SELECT_CANDIDATES = `${JOB_APPLICATION_BASE_URL}/job/:jobId/select-candidates`;
export const JOB_APPLICATION_SELECT_ALL_TO_BE_CANDIDATE = `${JOB_APPLICATION_BASE_URL}/job/:jobId/select-all-to-be-candidate`;
export const JOB_APPLICATION_IS_REVIEWER = `${JOB_APPLICATION_BASE_URL}/job/:jobId/is-reviewer`;
export const JOB_APPLICATION_APPROVE_UPLOAD = `${JOB_APPLICATION_BASE_URL}/approve-upload`;
export const JOB_APPLICATION_CREATE = `${JOB_APPLICATION_BASE_URL}/create`;
export const JOB_APPLICATION_DELETE = `${JOB_APPLICATION_BASE_URL}/delete`;
export const JOB_APPLICATION_LIST_SUMMARY = `${JOB_APPLICATION_BASE_URL}/list-summary`;
export const JOB_APPLICATION_DETAIL_BY_ID = `${JOB_APPLICATION_BASE_URL}/detail/:id`;
export const JOB_APPLICATION_BATCH_UPDATE = `${JOB_APPLICATION_BASE_URL}/batch-update`;
export const JOB_APPLICATION_FIND_BY_PHONE_NO = `${JOB_APPLICATION_BASE_URL}/find-by-phone-no`;
export const JOB_APPLICATION_UPLOAD_REVIEW = `${JOB_APPLICATION_BASE_URL}/upload-review`;
export const JOB_APPLICATION_QC_REVIEW_PASS = `${JOB_APPLICATION_BASE_URL}/qc-review-pass`;
export const JOB_APPLICATION_QC_REVIEW_FAIL = `${JOB_APPLICATION_BASE_URL}/qc-review-fail`;
export const JOB_APPLICATION_REVIEW_PASS = `${JOB_APPLICATION_BASE_URL}/review-pass`;
export const JOB_APPLICATION_REVIEW_FAIL = `${JOB_APPLICATION_BASE_URL}/review-fail`;
export const JOB_APPLICATION_UPLOAD_LINK = `${JOB_APPLICATION_BASE_URL}/update-link`;
export const JOB_APPLICATION_EMPLOYER_GET = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId`;
export const JOB_APPLICATION_EMPLOYER_GET_TABS_REVIER_UPLOAD = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId/tabs/review-upload`;
export const JOB_APPLICATION_EMPLOYER_GET_TABS_SELECT_CANDIDATE = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId/tabs/select-candidate`;
export const JOB_APPLICATION_EMPLOYER_GET_SELECT_REVIEWERS = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId/select-reviewers`;
export const JOB_APPLICATION_EMPLOYER_SUBMIT = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId/submit`;
export const JOB_APPLICATION_REVIEWER_GET_COMMENTS = `${JOB_APPLICATION_BASE_URL}/reviewer/job/:jobId/comments`;
export const JOB_APPLICATION_REVIEWER_GET_INFO = `${JOB_APPLICATION_BASE_URL}/reviewer/job/:jobId/info`;
export const JOB_APPLICATION_EMPLOYER_GET_COMMENTS = `${JOB_APPLICATION_BASE_URL}/employer/job/:jobId/:id/comments`;
export const JOB_APPLICATION_GET_COMMENTS = `${JOB_APPLICATION_BASE_URL}/job/:jobId/:id/comments`;

//agreement
export const AGREEMENT_BASE_URL = '/agreement';
export const AGREEMENT_GET_LATEST = `${AGREEMENT_BASE_URL}/latest`;
export const AGREEMENT_ACCEPT = `${AGREEMENT_BASE_URL}/accept`;
export const AGREEMENT_GET_BY_USER_ID = `${AGREEMENT_BASE_URL}/get-by-user-id/:id`;

//employer
export const EMPLOYER_BASE_URL = '/employer';
export const EMPLOYER_GET_INFO = `${EMPLOYER_BASE_URL}/info`;
export const EMPLOYER_ACTION_LINK = `${EMPLOYER_BASE_URL}/action-link/job/:id`;
// const link = `${ENDPOINT.WEB}/employer/action-link/job/${jobOfferId}?token=${token}`;

// template-agreements
export const TEMPLATE_AGREEMENT_BASE_URL = '/template-agreements';
export const TEMPLATE_AGREEMENT_GET_BY_USER_ID = `${TEMPLATE_AGREEMENT_BASE_URL}/user/:userId`;

// user-agreements
export const USER_AGREEMENT_BASE_URL = '/user-agreements';
export const USER_AGREEMENT_GET_BY_USER_ID = `${USER_AGREEMENT_BASE_URL}/user/:userId`;

// influencers
export const INFLUENCERS_BASE_URL = '/influencers';
export const INFLUENCERS_GET_BASIC_INFO_BY_USER_ID = `${INFLUENCERS_BASE_URL}/user/:userId/basic-info`;
export const INFLUENCERS_GET_COMP_CARDS_BY_USER_ID = `${INFLUENCERS_BASE_URL}/user/:userId/comp-cards`;
export const INFLUENCERS_GET_VIDEO_LINKS_BY_USER_ID = `${INFLUENCERS_BASE_URL}/user/:userId/video-links`;
export const INFLUENCERS_GET_PROFILE_IMAGE_USER_ID = `${INFLUENCERS_BASE_URL}/user/:userId/profile`;
export const INFLUENCERS_UPDATE_ADDRESS = `${INFLUENCERS_BASE_URL}/update-address`;

//job-offer-access-links
export const JOB_OFFER_ACCESS_LINKS_BASE_URL = '/job-offer-access-links';
export const JOB_OFFER_ACCESS_LINKS_VALIDATE_TOKEN = `${JOB_OFFER_ACCESS_LINKS_BASE_URL}/validate-token`;

//auth
export const INFLUENCER_LINK_BASE_URL = '/influencer-links';
export const INFLUENCER_LINK_UPDATE = `${INFLUENCER_LINK_BASE_URL}/update`;
export const INFLUENCER_LINK_SEARCH = `${INFLUENCER_LINK_BASE_URL}/search`;
export const INFLUENCER_LINK_REFRESH_LINK = `${INFLUENCER_LINK_BASE_URL}/refresh-link`;
export const INFLUENCER_LINK_GET_INFLUENCERS_BY_TOKEN = `${INFLUENCER_LINK_BASE_URL}/token/:token/influencers`;
export const INFLUENCER_LINK_GET_BY_ID = `${INFLUENCER_LINK_BASE_URL}/:id`;
