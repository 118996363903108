import ButttonViewUpload from '@components/button/ButtonViewUpload';
import CardApplication from '@components/card/CardApplication';
import { JobApplicationGet } from '@services/models/jobApplication';
import { Page } from '@services/models/page';
import { Divider, List, Skeleton } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  showViewUploadButton?: boolean;
  loading?: boolean;
  datasource?: JobApplicationGet.Data[];
  pagination?: Page;
  onLoadMore: () => void;
};
export default function DataView(props: Props) {
  const {
    showViewUploadButton,
    loading = false,
    datasource = [],
    pagination = { pageNumber: 0, pageSize: 0, total: 0, totalPages: 0 },
    onLoadMore,
  } = props;

  return (
    <InfiniteScroll
      dataLength={datasource.length}
      next={onLoadMore}
      hasMore={
        pagination ? pagination.pageNumber < pagination.totalPages : false
      }
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        loading={loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={datasource}
        renderItem={(item) => (
          <List.Item>
            <CardApplication
              adminLayout={false}
              key={item.jobApplication.id}
              jobApplication={item.jobApplication}
              data={item.info}
            >
              {showViewUploadButton && (
                <ButttonViewUpload
                  apiMode='employer'
                  id={item.jobApplication.id}
                  jobId={item.jobApplication.jobOfferId}
                />
              )}
            </CardApplication>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
}
