import IConfirmModal from '@components/IConfirmModal';
import { ROUTE } from '@constants/routes';
import { AuthContext } from '@contexts/AuthContext';
import { logout } from '@utils/authUtils';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { RiArrowDownSLine, RiMenu2Line, RiUserLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const { Header } = Layout;

type Props = {
  onCollapse?: () => void;
};

function HeaderMain(props: Props) {
  const { onCollapse } = props;
  const { loggedInUser, setLogedInUser } = useContext(AuthContext);

  const history = useHistory();

  const menu = (
    <Menu
      onClick={(e) => {
        handleMenuClick(e.key);
      }}
    >
      <Menu.Item key='2'>ออกจากระบบ</Menu.Item>
    </Menu>
  );

  function handleMenuClick(key: string) {
    if (key === '2') {
      IConfirmModal({
        type: 'confirm',
        title: 'ออกจากระบบ?',
        message: 'คุณต้องการที่จะออกจากระบบ ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          // TODO call api signout
          logout();

          // Set auth user to null
          setLogedInUser(null);

          // Sync signout
          window.localStorage.setItem('signout', Date.now().toString());

          history.push(ROUTE.LOGIN);
        },
        afterClose: () => ({}),
      });
    }
  }

  // const renderLogo = (logoUrl: string, width?: number) => {
  //     if (!width) { width = 40 }
  //     return logoUrl
  //         ? <InfoStyle.Logo> <img src={userInfo.logo} width={width} /> </InfoStyle.Logo>
  //         : <></>
  //     // : <RiImage2Fill fontSize={width} style={{ verticalAlign: 'middle' }}></RiImage2Fill>
  // }

  return (
    <>
      <HeaderStyle>
        <TitlePanelStyle>
          <a
            onClick={() => {
              onCollapse && onCollapse();
            }}
          >
            <RiMenu2Line
              style={{
                verticalAlign: 'middle',
                fontSize: '18px',
              }}
            />
          </a>
        </TitlePanelStyle>
        <UserPanelStyle>
          <AvartarPanelStyle>
            <Avatar
              size='large'
              icon={<RiUserLine style={{ verticalAlign: 'middle' }} />}
            />
            &emsp;
            <Dropdown overlay={menu} trigger={['click']}>
              <ProfileMenuPanelStyle onClick={(e) => e.preventDefault()}>
                <MenuLabelStyle>{loggedInUser?.email}</MenuLabelStyle>
                &nbsp;&nbsp;
                <RiArrowDownSLine
                  style={{
                    fontSize: '16px',
                    verticalAlign: 'middle',
                  }}
                />
              </ProfileMenuPanelStyle>
            </Dropdown>
          </AvartarPanelStyle>
        </UserPanelStyle>
      </HeaderStyle>
    </>
  );
}

const HeaderStyle = styled(Header)`
  background-color: #001529;
  padding-left: 20px;
  padding-right: 40px;
`;

const TitlePanelStyle = styled.div`
  background-color: transparent;
  display: inline-block;
`;

const UserPanelStyle = styled.div`
  background-color: transparent;
  float: right;
  position: relative;
`;

const AvartarPanelStyle = styled.div`
  display: inline-block;
`;

const ProfileMenuPanelStyle = styled.label`
  cursor: pointer;
  color: white;
`;

const MenuLabelStyle = styled.span`
  font-size: '16px';
  font-weight: 500;
`;

export default HeaderMain;
