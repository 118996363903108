import { useResponsiveContext } from '@contexts/ResponsiveContext';
import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

interface State {
  isHover: boolean;
}

type Props = {
  title: ReactNode;
  src: string;
  disabled?: boolean;
  onClick: () => void;
};
export default function SelectionCard(props: Props) {
  const { title, src, disabled = false, onClick } = props;

  const { isMobile } = useResponsiveContext();

  const [state, setState] = useState<State>({
    isHover: false,
  });

  function onHoverIn() {
    setState((prev) => ({ ...prev, isHover: true }));
  }

  function onHoverOut() {
    setState((prev) => ({ ...prev, isHover: false }));
  }

  return (
    <CardStyle.Wrapper
      onClick={onClick}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      onTouchStart={onHoverIn}
      onTouchEnd={onHoverOut}
      isHover={state.isHover}
      disabled={disabled}
    >
      <div>
        <CardStyle.CardTitle>{title}</CardStyle.CardTitle>
        <CardStyle.ImageWrapper isMobile={isMobile}>
          <img src={src} />
        </CardStyle.ImageWrapper>
      </div>
    </CardStyle.Wrapper>
  );
}

const CardStyle = {
  Wrapper: styled.div<{ isHover: boolean; disabled: boolean }>`
    border-radius: 16px 16px 32px 32px;

    :hover {
      box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
        0 5px 12px 4px #00000017;
    }

    .ant-card-body {
      padding: 0px;
    }

    background-color: white;

    cursor: pointer;

    ${(props) =>
      props.disabled
        ? css`
            cursor: not-allowed;

            img {
              filter: grayscale(100%);
            }
          `
        : props.isHover &&
          css`
            background-color: var(--secondary-color);
            color: white;
          `}
  `,
  CardTitle: styled.div`
    padding: 16px;
    /* font-weight: bold; */
    font-size: 18px;
  `,
  ImageWrapper: styled.div<{ isMobile: boolean }>`
    /* height: 300px; */
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 32px;
    }

    ${(props) =>
      props.isMobile
        ? css`
            height: 160px;
            img {
              border-radius: 16px;
            }
          `
        : css`
            height: 300px;
          `}
  `,
};
