import CardContent from '@components/card/CardContent';
import LayoutBackoffice from '@components/layout/LayoutBackoffice';
import SectioJobDetail from '@components/section/SectionJobDetail';
import { ROUTE } from '@constants/routes';
import SectionControl from '@pages/backoffice/manages/jobs/detail/components/SectionControl';
import SectionProgress from '@pages/backoffice/manages/jobs/detail/components/SectionProgress';
import TabAccept from '@pages/backoffice/manages/jobs/detail/components/TabAccept';
import TabAll from '@pages/backoffice/manages/jobs/detail/components/TabAll';
import TabCandidate from '@pages/backoffice/manages/jobs/detail/components/TabCandidate';
import TabInReview from '@pages/backoffice/manages/jobs/detail/components/TabInReview';
import TabPrePass from '@pages/backoffice/manages/jobs/detail/components/TabPrePass';
import TabReviewer from '@pages/backoffice/manages/jobs/detail/components/TabReviewer';
import TabRevise from '@pages/backoffice/manages/jobs/detail/components/TabRevise';
import TabUpload from '@pages/backoffice/manages/jobs/detail/components/TabUpload';
import { jobApplicationApi, jobOfferApi } from '@services/apis';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Breadcrumb, Col, Row, Spin, Tabs } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface State {
  loadingJobDetail: boolean;
  loadingTabs: boolean;
  jobDetailResp?: JobOfferGetDetailById.Response;
  totalApplicants: number;
  totalCandidates: number;
  totalReviewers: number;
  totalInReviews: number;
  totalUploads: number;
  totalPrePasses: number;
  totalRevises: number;
  totalAccepts: number;
}

export default function BackofficeManageJobsDetailPage() {
  const { id }: { id: string } = useParams();

  const [state, setState] = useState<State>({
    loadingJobDetail: false,
    loadingTabs: false,
    totalApplicants: 0,
    totalCandidates: 0,
    totalReviewers: 0,
    totalInReviews: 0,
    totalUploads: 0,
    totalPrePasses: 0,
    totalRevises: 0,
    totalAccepts: 0,
  });

  const showLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: true }));
  const hideLoadingJobDetail = () =>
    setState((prev) => ({ ...prev, loadingJobDetail: false }));
  const showLoadingTabs = () =>
    setState((prev) => ({ ...prev, loadingTabs: true }));
  const hideLoadingTabs = () =>
    setState((prev) => ({ ...prev, loadingTabs: false }));

  const tabItems = [
    {
      label: <>ผู้สมัครทั้งหมด ({state.totalApplicants})</>,
      key: 'tab-1',
      children: <TabAll jobId={+id} />,
    },
    {
      label: <>Candidates ({state.totalCandidates})</>,
      key: 'tab-2',
      children: <TabCandidate jobId={+id} />,
    },
    {
      label: <>Reviewers ({state.totalReviewers})</>,
      key: 'tab-3',
      children: <TabReviewer jobId={+id} />,
    },
    {
      label: <>รออัพโหลด ({state.totalInReviews})</>,
      key: 'tab-4',
      children: <TabInReview jobId={+id} />,
    },
    {
      label: <>รอตรวจ ({state.totalUploads})</>,
      key: 'tab-5',
      children: <TabUpload jobId={+id} />,
    },
    {
      label: <>ตรวจงานแล้ว ({state.totalPrePasses})</>,
      key: 'tab-6',
      children: <TabPrePass jobId={+id} />,
    },
    {
      label: <>กำลังแก้ไข ({state.totalRevises})</>,
      key: 'tab-7',
      children: <TabRevise jobId={+id} />,
    },
    {
      label: <>ผ่าน ({state.totalAccepts})</>,
      key: 'tab-8',
      children: <TabAccept jobId={+id} />,
    },
  ];

  async function fetchGetDetailById() {
    try {
      showLoadingJobDetail();
      const resp = await jobOfferApi.getDetailById(+id);
      setState((prev) => ({ ...prev, jobDetailResp: resp }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingJobDetail();
    }
  }

  async function fetchTabs() {
    try {
      showLoadingTabs();
      const res = await jobApplicationApi.GetTabsByJobID(+id);

      const totalApplicants =
        _.find(res?.data, (e) => e.tab === 'total')?.total ?? 0;
      const totalCandidates =
        _.find(res?.data, (e) => e.tab === 'candidate')?.total ?? 0;
      const totalReviewers =
        _.find(res?.data, (e) => e.tab === 'reviewer')?.total ?? 0;
      const totalInReviews =
        _.find(res?.data, (e) => e.tab === 'inReview')?.total ?? 0;
      const totalUploads =
        _.find(res?.data, (e) => e.tab === 'upload')?.total ?? 0;
      const totalPrePasses =
        _.find(res?.data, (e) => e.tab === 'prePass')?.total ?? 0;
      const totalRevises =
        _.find(res?.data, (e) => e.tab === 'revise')?.total ?? 0;
      const totalAccepts =
        _.find(res?.data, (e) => e.tab === 'accept')?.total ?? 0;

      setState((prev) => ({
        ...prev,
        totalApplicants,
        totalCandidates,
        totalReviewers,
        totalInReviews,
        totalUploads,
        totalPrePasses,
        totalRevises,
        totalAccepts,
      }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingTabs();
    }
  }

  useEffect(() => {
    fetchGetDetailById();
    fetchTabs();
  }, []);

  return (
    <LayoutBackoffice>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTE.BACKOFFICE_MANAGES_JOBS}>งานรีวิว</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>รายละเอียดงาน</Breadcrumb.Item>
      </Breadcrumb>

      <Content.Wrapper>
        <SectioJobDetail jobId={+id} hideTitle />

        <Row justify='space-between' gutter={[32, 32]}>
          <Col xs={24} md={24} lg={14}>
            <Spin spinning={state.loadingJobDetail}>
              <SectionProgress stage={state.jobDetailResp?.job.stage ?? ''} />
            </Spin>
          </Col>
          <Col xs={24} md={24} lg={10}>
            <SectionControl
              loading={state.loadingJobDetail}
              jobId={+id}
              canApply={state?.jobDetailResp?.job.canApply ?? false}
            />
          </Col>
        </Row>

        <CardContent>
          <h2>ผู้สมัคร</h2>
          <Tabs items={tabItems} />
        </CardContent>
      </Content.Wrapper>
    </LayoutBackoffice>
  );
}

const Content = {
  Wrapper: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};
