import SectionComment, {
  APIModeType,
} from '@components/section/SectionComment';
import SectionUploadHistory from '@components/section/SectionUploadHistory';
import { Col, Drawer, Row } from 'antd';
import React from 'react';

type Props = {
  apiMode: APIModeType;
  id: number;
  jobId: number;
  title: string;
  open: boolean;
  onClose: () => void;
};
export default function DrawerViewUpload(props: Props) {
  const { apiMode, id, jobId, title, open, onClose } = props;

  return (
    <Drawer
      title={title}
      placement='right'
      width='100%'
      onClose={onClose}
      open={open}
      bodyStyle={{ backgroundColor: 'var(--bg-gray-color)' }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={16}>
          <SectionUploadHistory apiMode={apiMode} jobId={jobId} id={id} />
        </Col>
        <Col xs={24} lg={8}>
          <SectionComment apiMode={apiMode} jobId={jobId} id={id} />
        </Col>
      </Row>
    </Drawer>
  );
}
