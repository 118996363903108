import { EmployerGetInfo } from '@services/models/employer';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  data: EmployerGetInfo.Response;
};
export default function EMPCard(props: Props) {
  const { data } = props;

  const CardStyle = {
    Wrapper: styled.div`
      height: 180px;
      width: 100%;
      padding: 12px;
      background-color: var(--secondary-color);
      border-radius: 24px;
    `,
    Row: styled(Row)`
      height: 100%;
    `,
    ColLeft: styled(Col)`
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    `,
    ColRight: styled(Col)`
      color: white;
    `,
    Title: styled.div`
      font-size: 18px;
      font-weight: bold;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-wrap: break-word;

      margin-bottom: 8px;
    `,
    Description: styled(Typography.Paragraph)`
      margin-bottom: 8px;
      color: white;
      font-size: calc(var(--base-font-size) - 2px);
    `,

    Label: styled.div`
      color: var(--gray-color-5);
      /* color: white; */
      font-size: 14px;
    `,
    Value: styled.div`
      font-size: 16px;
    `,
  };

  return (
    <CardStyle.Wrapper>
      <CardStyle.Row gutter={[12, 0]}>
        <CardStyle.ColLeft span={6}>
          <img src='https://i.pinimg.com/originals/51/10/19/51101970f30856c14fe25bbfbaae2817.png'></img>
        </CardStyle.ColLeft>

        <CardStyle.ColRight span={18}>
          <CardStyle.Title>{data.employer.companyName}</CardStyle.Title>
          <CardStyle.Description>
            ชื่อเจ้าของ:{' '}
            {`${data.employer.firstName} ${data.employer.lastName}`} <br />
            เบอร์โทร: {data.employer.phoneNo}
          </CardStyle.Description>

          <Row gutter={6} justify='space-between'>
            <Col span={6}>
              <CardStyle.Label>สินค้า</CardStyle.Label>
              <CardStyle.Value>7</CardStyle.Value>
            </Col>
            <Col span={8}>
              <CardStyle.Label>งานที่สำเร็จ</CardStyle.Label>
              <CardStyle.Value>12</CardStyle.Value>
            </Col>
            <Col span={8}>
              <CardStyle.Label>งานรีวิว</CardStyle.Label>
              <CardStyle.Value>33</CardStyle.Value>
            </Col>
          </Row>
        </CardStyle.ColRight>
      </CardStyle.Row>
    </CardStyle.Wrapper>
  );
}
