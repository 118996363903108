import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import { IMAGE } from '@constants/resource';
import { ROUTE } from '@constants/routes';
import SelectionCard from '@pages/job/[id]/influencer/components/SelectionCard';
import { jobOfferApi } from '@services/apis';
import { JobOfferGetDetailById } from '@services/models/jobOffer';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

interface ActionState {
  loading?: boolean;
  jobDetail?: JobOfferGetDetailById.Response;
}

export default function JobInfluencerHomePage() {
  const { id }: { id: string } = useParams();

  const history = useHistory();

  const [actionState, setActionState] = useState<ActionState>({});

  async function fetchJobOfferDetail() {
    try {
      setActionState((prev) => ({ ...prev, loading: true }));
      const res = await jobOfferApi.getDetailById(+id);
      setActionState((prev) => ({ ...prev, jobDetail: res }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setActionState((prev) => ({ ...prev, loading: false }));
    }
  }

  function goToRegisterPage() {
    if (actionState.jobDetail?.job.canApply) {
      history.push(generatePath(ROUTE.JOB_INFLUENCER_REGISTER, { id }));
    }
  }

  function goToUploadPage() {
    history.push(generatePath(ROUTE.JOB_INFLUENCER_UPLOAD, { id }));
  }

  useEffect(() => {
    fetchJobOfferDetail();
  }, []);

  return (
    <LayoutPublic>
      <Row
        gutter={[16, 40]}
        justify='center'
        align='middle'
        style={{ marginTop: '32px' }}
      >
        <Col xs={24} sm={20} lg={8}>
          <SelectionCard
            key='register'
            title={
              actionState.jobDetail ? (
                actionState.jobDetail.job.canApply ? (
                  'ลงทะเบียนงานนี้'
                ) : (
                  'ปิดการรับสมัคร'
                )
              ) : (
                <Loading loading />
              )
            }
            src={IMAGE.COVER_INFLUENCER_REGISTER}
            onClick={goToRegisterPage}
            disabled={!actionState?.jobDetail?.job.canApply}
          />
        </Col>

        <Col xs={24} sm={20} lg={8}>
          <SelectionCard
            title='อัพโหลดผลงาน'
            src={IMAGE.COVER_INFLUENCER_UPLOAD}
            onClick={goToUploadPage}
          />
        </Col>
      </Row>
    </LayoutPublic>
  );
}
