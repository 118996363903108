import { Button, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  dataId: string;
  loading: boolean;
  onAction: (id: number, isAccept: boolean) => void;
};
export default function ButtonGroup(props: Props) {
  const { dataId, loading, onAction } = props;

  return (
    <Wrapper>
      <Row justify='space-between' gutter={16}>
        <Col span={12}>
          <Button
            block
            size='large'
            shape='round'
            onClick={() => onAction(+dataId, false)}
            disabled={loading}
          >
            ปฏิเสธ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={loading}
            block
            size='large'
            type='primary'
            shape='round'
            onClick={() => onAction(+dataId, true)}
          >
            ยอมรับ
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
  background-color: white;

  border-radius: 24px 24px 0 0;

  position: relative;
  bottom: 0;
  left: 0;
`;
