import {
  BankOutlined,
  DropboxOutlined,
  FormOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import EditorInput from '@components/form/EditorInput';
import ISocialIcon from '@components/icon/ISocialIcon';
import PagePanel from '@components/pages/PagePanel';
import { DEFAULT_PAGE_SIZE, MASTER_DATA_CATEGORY } from '@constants/constants';
import { useResponsiveContext } from '@contexts/ResponsiveContext';
import AddProductDrawer from '@pages/backoffice/manages/jobs/components/AddProductDrawer';
import ButtonSheet from '@pages/backoffice/manages/jobs/components/ButtonSheet';
import EMPCard from '@pages/backoffice/manages/jobs/components/EMPCard';
import NotFoundEMPCardAndAddDrawer from '@pages/backoffice/manages/jobs/components/NotFoundEMPCardAndAddDrawer';
import PreviewDescriptionModal from '@pages/backoffice/manages/jobs/components/PreviewDescriptionModal';
import ProductCard from '@pages/backoffice/manages/jobs/components/ProductCard';
import SuccessCard from '@pages/backoffice/manages/jobs/components/SuccessCard';
import SummaryCard from '@pages/backoffice/manages/jobs/components/SummaryCard';
import {
  employerAPI,
  jobOfferApi,
  masterApi,
  productApi,
  reviewPackageApi,
} from '@services/apis';
import { LabelValue } from '@services/models/common';
import { EmployerGetInfo } from '@services/models/employer';
import { ProductList } from '@services/models/product';
import { ReviewPackageList } from '@services/models/reviewPackage';
import { failedToFetchAPI } from '@utils/apiUtils';
import { delay } from '@utils/helpsUtils';
import {
  apiUtils,
  browserUtils,
  dataUtils,
  formRuleUtils,
  helpsUtils,
} from '@utils/index';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Select,
  Skeleton,
  Space,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { EditorState } from 'draft-js';
import { StatusCodes } from 'http-status-codes';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
// import { EditorState } from 'react-draft-wysiwyg';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';

interface EMPSearchFormType {
  phoneNo: string;
}

export interface CreateJobFormType {
  productId: number;
  name: string;
  description?: EditorState;
  genderCode: string;
  ageRangeCode: string;
  reviewKeyword?: string;
  reviewSmcCodes: string;
  reviewPackageId: number;
  lineNotifyTokens: string[];
  userId: number;
}

export interface State {
  selectedEMPData?: EmployerGetInfo.Response;
  selectedProduct?: ProductList.ResponseData;
  empSearchNotFound?: boolean;
  visibleEMPCreateForm?: boolean;
  isSummaryState?: boolean;
  visibleSuccessCard?: boolean;
  openModalDescriptionPreview?: boolean;
  previewHTML?: string;
  empSearchPhoneNo?: string;
  openAddProductDrawer?: boolean;
}

const empSearchFormSchema = {
  phoneNo: { name: 'phoneNo', label: 'เบอร์โทรศัพท์' },
};

const formSchema = {
  name: { name: 'name', label: 'ชื่องานรีวิว' },
  description: { name: 'description', label: 'รายละเอียดงานรีวิว' },
  genderCode: { name: 'genderCode', label: 'เพศ' },
  ageRangeCode: { name: 'ageRangeCode', label: 'ช่วงอายุ' },
  reviewKeyword: {
    name: 'reviewKeyword',
    label: 'คีย์เวิร์ดที่ต้องใส่ในงานรีวิว',
  },
  reviewSmcCodes: { name: 'reviewSmcCodes', label: 'กิจกรรม/รีวิว' },
  reviewPackageId: { name: 'reviewPackageId', label: 'แพ็กเกจ' },
  lineNotifyTokens: { name: 'lineNotifyTokens', label: 'Line Notify Tokens' },

  productId: { name: 'productId', label: 'รหัสสินค้า' },
  userId: { name: 'userId', label: '' },
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
export default function DrawerAdd(props: Props) {
  const { open, onClose, onSuccess } = props;

  const productSectionRef = useRef<HTMLDivElement>(null);
  const createJobFormSectionRef = useRef<HTMLDivElement>(null);

  const { isDesktop } = useResponsiveContext();

  const [formCreateJob] = Form.useForm();
  const [formEMPSearch] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingEMPSearch, setLoadingEMPSearch] = useState(false);
  const [loadingProductList, setLoadingProductList] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);

  const [state, setState] = useState<State>({});
  const [dropdownProductType, setDropdownProductType] = useState<LabelValue[]>(
    []
  );

  const showModalDescriptionPreview = () =>
    setState((prev) => ({
      ...prev,
      openModalDescriptionPreview: true,
    }));
  const hideModalDescriptionPreview = () =>
    setState((prev) => ({
      ...prev,
      openModalDescriptionPreview: false,
    }));
  const showAddProductDrawer = () => {
    setState((prev) => ({ ...prev, openAddProductDrawer: true }));
  };
  const hideAddProductDrawer = () => {
    setState((prev) => ({ ...prev, openAddProductDrawer: false }));
  };

  const [genderCodeOptions, setGenderCodeOptions] = useState<
    DefaultOptionType[]
  >([]);
  const [ageRangeCodeoptions, setAgeRangeCodeoptions] = useState<
    DefaultOptionType[]
  >([]);
  const [reviewSmsOptions, setReviewSmsOptions] = useState<DefaultOptionType[]>(
    []
  );
  const [reviewPackageOptions, setReviewPackageOptions] = useState<
    DefaultOptionType[]
  >([]);

  const [productListRes, setProductListRes] = useState<ProductList.Response>();
  const [productListData, setProductListData] = useState<
    ProductList.ResponseData[]
  >([]);

  const enableFullScreen = () => setFullScreen(true);
  const disableFullScreen = () => setFullScreen(false);
  const resetActionState = () => setState({});
  const resetActionStateAndCreateForm = () => {
    setState({});
    formCreateJob.resetFields();
  };
  const onSelectProduct = (data: ProductList.ResponseData) => {
    delay(() => browserUtils.scrollToRef(createJobFormSectionRef), 250);
    setState((prev) => ({ ...prev, selectedProduct: data }));
    formCreateJob.setFieldValue(formSchema.productId.name, data.data.id);
  };
  const onDeselectProduct = () =>
    setState((prev) => ({ ...prev, selectedProduct: undefined }));

  function onRegisterEMPSuccess(phoneNo: string) {
    formEMPSearch.setFieldValue(empSearchFormSchema.phoneNo.name, phoneNo);
    formEMPSearch.submit();
    setState((prev) => ({ ...prev, empSearchNotFound: false }));
  }

  function onAddProductSuccess(productId: number) {
    fetchProductList(
      { limit: { pageNumber: 1, pageSize: 1 }, id: productId },
      true
    );
  }

  async function onFinishFormCreatejob(values: CreateJobFormType) {
    let description: string | undefined = undefined;
    if (values.description) {
      description = dataUtils.editorStateToHTML(values.description);
    }
    try {
      setLoading(true);
      await jobOfferApi.create({
        ...values,
        description: description,
      });

      setState((prev) => ({ ...prev, visibleSuccessCard: true }));
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoading(false);
    }
  }

  function handleShowDescriptionPreviewModal() {
    showModalDescriptionPreview();
    const html = dataUtils.editorStateToHTML(
      formCreateJob.getFieldValue(formSchema.description.name)
    );
    setState((prev) => ({ ...prev, previewHTML: html }));
  }

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      formEMPSearch.submit();
    }
  }

  function handleOnClose() {
    formCreateJob.resetFields();
    formEMPSearch.resetFields();
    setState({});
    onClose();
  }

  function renderName() {
    return formCreateJob.getFieldValue(formSchema.name.name);
  }

  function renderGender() {
    const value = formCreateJob.getFieldValue(formSchema.genderCode.name);
    const item = _.find(genderCodeOptions, (o) => o.value === value);
    return item?.label;
  }

  function renderAgeRange() {
    const value = formCreateJob.getFieldValue(formSchema.ageRangeCode.name);
    const item = _.find(ageRangeCodeoptions, (o) => o.value === value);
    return item?.label;
  }

  function renderReviewKeyword() {
    const value = formCreateJob.getFieldValue(formSchema.reviewKeyword.name);
    return value;
  }

  function renderReviewSmc() {
    const value = formCreateJob.getFieldValue(formSchema.reviewSmcCodes.name);
    const items = reviewSmsOptions.filter((e) => value.includes(e.value));

    return (
      <Row gutter={10}>
        {items?.map((e) => {
          return <Col key={e.value}>{e.label}</Col>;
        })}
      </Row>
    );
  }

  function renderPackage() {
    const value = formCreateJob.getFieldValue(formSchema.reviewPackageId.name);
    const item = _.find(reviewPackageOptions, (o) => o.value === value);
    return item?.label2;
  }

  async function handleOnSummary() {
    await formCreateJob.validateFields();
    setState((prev) => ({ ...prev, isSummaryState: true }));
    helpsUtils.delay(() => {
      browserUtils.scrollToRef(createJobFormSectionRef);
    }, 250);
  }

  function handleOnSubmit() {
    // TODO call API
    // notifyUtils.notifyAvailableSoon();
    formCreateJob.submit();
  }

  function handleOnBackFromSummary() {
    setState((prev) => ({ ...prev, isSummaryState: false }));

    helpsUtils.delay(() => {
      browserUtils.scrollToRef(createJobFormSectionRef);
    }, 250);
  }

  async function onFinishEMPSearch(values: EMPSearchFormType) {
    try {
      setLoadingEMPSearch(true);

      const res = await employerAPI.getInfo({
        phoneNo: values.phoneNo,
      });
      setState((prev) => ({
        ...prev,
        selectedEMPData: res,
      }));

      fetchProductList({
        userId: res.employer.userId,
        limit: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
      });
      browserUtils.scrollToRef(productSectionRef);
    } catch (error) {
      const status = apiUtils.getHTTPErroStatus(error);
      if (status === StatusCodes.NOT_FOUND) {
        setState({
          empSearchNotFound: true,
          empSearchPhoneNo: values.phoneNo,
        });
      }
    } finally {
      setLoadingEMPSearch(false);
    }
  }

  async function fetchDropdownGender() {
    try {
      const res = await masterApi.list({
        category: MASTER_DATA_CATEGORY.GENDER,
        limit: {
          pageNumber: 1,
          pageSize: Number.MAX_SAFE_INTEGER, // TODO create new API for this
        },
      });

      const data = res.data?.map((e) => {
        const item: LabelValue = {
          value: e.code,
          label: e.name,
        };
        return item;
      });
      setGenderCodeOptions(data);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchDropdownAgeRange() {
    try {
      const res = await masterApi.list({
        category: MASTER_DATA_CATEGORY.AGE_RANGE,
        limit: {
          pageNumber: 1,
          pageSize: Number.MAX_SAFE_INTEGER, // TODO create new API for this
        },
      });

      const data = res.data?.map((e) => {
        const item: LabelValue = {
          value: e.code,
          label: e.name,
        };
        return item;
      });
      setAgeRangeCodeoptions(data);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchDropdownReviewSMC() {
    try {
      const res = await masterApi.list({
        category: MASTER_DATA_CATEGORY.SOCIAL_MEDIA_CHANNEL,
        limit: {
          pageNumber: 1,
          pageSize: Number.MAX_SAFE_INTEGER, // TODO create new API for this
        },
      });

      const data = res.data?.map((e) => {
        const name = e.name;
        const SocialIcon = (
          <ISocialIcon network={e.code} width={20} height={20} />
        );

        const item: LabelValue = {
          value: e.code,
          label: (
            <Row justify='space-between' gutter={10}>
              <Col>{name}</Col>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                {SocialIcon}
              </Col>
            </Row>
          ),
          label2: (
            <Space>
              <div>{name}</div>
              <div>{SocialIcon}</div>
            </Space>
          ),
        };
        return item;
      });
      setReviewSmsOptions(data);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchDropdownReviewPackage() {
    const options: Partial<ReviewPackageList.Request> = {
      limit: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
    try {
      const res = await reviewPackageApi.list(options);
      const data: LabelValue[] = res.data.map((e) => {
        const name = e.name;
        const price = `${dataUtils.numberFormat(e.price)} บาท`;

        return {
          value: e.id,
          label: (
            <Row justify='space-between'>
              <Col>{name}</Col>
              <Col>{price}</Col>
            </Row>
          ),
          label2: `${name} | ${price}`,
        };
      });
      setReviewPackageOptions(data);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  async function fetchProductList(
    req: Partial<ProductList.Request>,
    selectFirstItem?: boolean,
    loadMoreMode?: boolean
  ) {
    try {
      setLoadingProductList(true);
      const res = await productApi.list(req);
      setProductListRes(res);

      if (loadMoreMode) {
        setProductListData((prev) => [...prev, ...res.data]);
      } else {
        setProductListData(res.data);
      }

      if (selectFirstItem) {
        onSelectProduct(res.data[0]);
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      setLoadingProductList(false);
    }
  }

  function onLoadMoreProductList() {
    fetchProductList(
      {
        userId: state.selectedEMPData?.employer.userId,
        limit: {
          pageNumber:
            productListRes?.page.pageNumber !== undefined
              ? productListRes?.page.pageNumber + 1
              : 1,
          pageSize: productListRes?.page.pageSize ?? DEFAULT_PAGE_SIZE,
        },
      },
      false,
      true
    );
  }

  async function fetchProductTypes() {
    try {
      const res = await masterApi.list({
        category: MASTER_DATA_CATEGORY.PRODUCT_TYPE,
        limit: {
          pageNumber: 1,
          pageSize: 999, // TODO implement select with loadMore()
        },
      });

      const data: LabelValue[] = res.data.map((e) => ({
        label: e.name,
        value: e.code,
      }));
      setDropdownProductType(data ?? []);
    } catch (error) {
      failedToFetchAPI(error);
    }
  }

  useEffect(() => {
    if (open && genderCodeOptions.length === 0) {
      if (genderCodeOptions.length === 0) {
        fetchDropdownGender();
      }
      if (ageRangeCodeoptions.length === 0) {
        fetchDropdownAgeRange();
      }
      if (reviewSmsOptions.length === 0) {
        fetchDropdownReviewSMC();
      }
      if (reviewPackageOptions.length === 0) {
        fetchDropdownReviewPackage();
      }
      if (dropdownProductType.length === 0) {
        fetchProductTypes();
      }
    }
  }, [open]);

  return (
    <Drawer
      title={
        <Space>
          {isDesktop &&
            (fullScreen ? (
              <FullscreenExitOutlined onClick={disableFullScreen} />
            ) : (
              <FullscreenOutlined onClick={enableFullScreen} />
            ))}
          เพิ่มงานรีวิว
        </Space>
      }
      onClose={handleOnClose}
      open={open}
      width={isDesktop ? (fullScreen ? '100%' : '720px') : '100%'}
      bodyStyle={{
        backgroundColor: 'var(--bg-gray-color)',
        padding: '16px 16px 0',
      }}
    >
      <div>
        <PreviewDescriptionModal
          actionState={state}
          hideModalDescriptionPreview={hideModalDescriptionPreview}
        />

        {state.visibleSuccessCard ? (
          <SuccessCard
            onClose={() => {
              onSuccess();
              handleOnClose();
            }}
            onReset={resetActionStateAndCreateForm}
          />
        ) : (
          <>
            <Section.Container>
              {state.selectedEMPData ? (
                <>
                  <Section.Item>
                    <Row justify='space-between'>
                      <Col>
                        <Section.Title>
                          <BankOutlined /> ผู้ประกอบการ
                        </Section.Title>
                      </Col>
                      <Col>
                        <Button onClick={resetActionState}>ค้นหาใหม่</Button>
                      </Col>
                    </Row>

                    <EMPCard data={state.selectedEMPData} />
                  </Section.Item>

                  <br />
                  <br />
                  <Section.Item ref={productSectionRef}>
                    <AddProductDrawer
                      open={state.openAddProductDrawer ?? false}
                      userId={state.selectedEMPData.employer.userId}
                      onClose={hideAddProductDrawer}
                      onSuccess={onAddProductSuccess}
                    />

                    <Row justify='space-between'>
                      <Col>
                        <Section.Title>
                          <DropboxOutlined />
                          สินค้า
                        </Section.Title>
                      </Col>

                      <Col>
                        <Button
                          onClick={showAddProductDrawer}
                          hidden={state.selectedProduct !== undefined}
                        >
                          <PlusOutlined />
                          เพิ่มสินค้า
                        </Button>
                      </Col>
                    </Row>

                    {state.selectedProduct ? (
                      <ProductCard
                        isSelected
                        data={state.selectedProduct}
                        onSelect={onSelectProduct}
                        onDeselect={onDeselectProduct}
                        productTypes={dropdownProductType}
                      />
                    ) : (
                      <div
                        id='scrollableDiv'
                        style={{
                          height: 400,
                          overflow: 'auto',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={productListData.length}
                          next={onLoadMoreProductList}
                          hasMore={
                            productListRes?.page
                              ? productListRes.page.pageNumber <
                                productListRes.page.totalPages
                              : false
                          }
                          loader={
                            <Skeleton avatar paragraph={{ rows: 1 }} active />
                          }
                          endMessage={<Divider plain>แสดงทุกรายการ</Divider>}
                          scrollableTarget='scrollableDiv'
                        >
                          <List
                            loading={loadingProductList}
                            dataSource={productListData}
                            renderItem={(item) => (
                              <List.Item key={item.data.id}>
                                <ProductCard
                                  onSelect={onSelectProduct}
                                  data={item}
                                  productTypes={dropdownProductType}
                                />
                              </List.Item>
                            )}
                          />
                        </InfiniteScroll>
                      </div>
                    )}
                  </Section.Item>

                  <br />
                  <br />
                  <Section.Item ref={createJobFormSectionRef}>
                    <Row justify='space-between'>
                      <Col>
                        <Section.Title>
                          <FormOutlined /> แบบฟอร์มสร้างงานรีวิว
                        </Section.Title>
                      </Col>
                      <Col hidden={state.selectedProduct !== undefined}>
                        <Section.Title blink style={{ color: '#FEB816' }}>
                          <WarningOutlined /> *กรุณาเลือกสินค้า
                        </Section.Title>
                      </Col>
                    </Row>

                    {state.isSummaryState && (
                      <SummaryCard
                        rows={[
                          { label: 'ชื่องานรีวิว', value: renderName() },
                          { label: 'เพศ', value: renderGender() },
                          { label: 'ช่วงอายุ', value: renderAgeRange() },
                          {
                            label: 'คีย์เวิร์ดที่ต้องใส่ในงานรีวิว',
                            value: renderReviewKeyword(),
                          },
                          {
                            label: 'กิจกรรม/รีวิว',
                            value: renderReviewSmc(),
                          },
                          { label: 'แพ็กเกจ', value: renderPackage() },
                          {
                            label: 'รายละเอียดงานรีวิว',
                            value: 'ดูตัวอย่าง',
                          },
                        ]}
                      />
                    )}

                    <FormCard.Wrapper hidden={state.isSummaryState}>
                      <Form
                        scrollToFirstError
                        form={formCreateJob}
                        layout='vertical'
                        onFinish={onFinishFormCreatejob}
                        disabled={state.selectedProduct === undefined}
                      >
                        <Form.Item
                          hidden
                          {...formSchema.productId}
                          rules={formRuleUtils.REQUIRE}
                        >
                          <Input placeholder={formSchema.name.label} />
                        </Form.Item>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              {...formSchema.name}
                              rules={formRuleUtils.REQUIRE}
                            >
                              <Input placeholder={formSchema.name.label} />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...formSchema.genderCode}
                              rules={formRuleUtils.REQUIRE}
                            >
                              <Select placeholder={formSchema.genderCode.label}>
                                {genderCodeOptions.map((e) => (
                                  <Select.Option key={e.value} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...formSchema.ageRangeCode}
                              rules={formRuleUtils.REQUIRE}
                            >
                              <Select
                                placeholder={formSchema.ageRangeCode.label}
                              >
                                {ageRangeCodeoptions.map((e) => (
                                  <Select.Option key={e.value} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...formSchema.reviewSmcCodes}
                              rules={formRuleUtils.REQUIRE}
                            >
                              <Select
                                mode='multiple'
                                placeholder={formSchema.reviewSmcCodes.label}
                              >
                                {reviewSmsOptions.map((e) => (
                                  <Select.Option key={e.value} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...formSchema.reviewPackageId}
                              rules={formRuleUtils.REQUIRE}
                            >
                              <Select
                                placeholder={formSchema.reviewPackageId.label}
                              >
                                {reviewPackageOptions.map((e) => (
                                  <Select.Option key={e.value} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item {...formSchema.reviewKeyword}>
                              <Input
                                placeholder={formSchema.reviewKeyword.label}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <FormItemBlockStyleLable
                              {...formSchema.description}
                              label={
                                <Row justify='space-between'>
                                  <Col>{formSchema.description.label}</Col>
                                  <Col>
                                    <Button
                                      type='link'
                                      onClick={
                                        handleShowDescriptionPreviewModal
                                      }
                                    >
                                      ดูตัวอย่าง
                                    </Button>
                                  </Col>
                                </Row>
                              }
                            >
                              <EditorInput />
                            </FormItemBlockStyleLable>
                          </Col>

                          <Col span={24}>
                            <label>{formSchema.lineNotifyTokens.label}</label>
                            <Form.List name={formSchema.lineNotifyTokens.name}>
                              {(fields, { add, remove }, { errors }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key}>
                                      <Col span={23}>
                                        <Form.Item
                                          {...restField}
                                          hasFeedback
                                          name={[name]}
                                          validateTrigger={[
                                            'onChange',
                                            'onBlur',
                                            'onFocus',
                                          ]}
                                          dependencies={[
                                            formSchema.lineNotifyTokens.name,
                                            'name',
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                'โปรดระบุ Line Notify Token!',
                                            },
                                            {
                                              required: true,
                                              pattern: new RegExp(
                                                '^[A-Za-z0-9_.]+$'
                                              ),
                                              message: 'Token ไม่ถูกต้อง!',
                                            },
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (value) {
                                                  const values: string[] =
                                                    getFieldValue(
                                                      formSchema
                                                        .lineNotifyTokens.name
                                                    );

                                                  const valuesTmp = [...values];
                                                  const idx =
                                                    valuesTmp.findIndex(
                                                      (e) => e === value
                                                    );
                                                  valuesTmp.splice(idx, 1);
                                                  return valuesTmp.includes(
                                                    value
                                                  )
                                                    ? Promise.reject(
                                                        'พบข้อมูลซ้ำกัน'
                                                      )
                                                    : Promise.resolve();
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input
                                            placeholder={
                                              formSchema.lineNotifyTokens.label
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={1} style={{ padding: 5 }}>
                                        <MinusCircleOutlined
                                          onClick={async () => remove(name)}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type='dashed'
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Line Notify Token
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Col>
                        </Row>
                      </Form>
                    </FormCard.Wrapper>
                  </Section.Item>
                </>
              ) : (
                <Section.Item>
                  <PagePanel>
                    <Section.Title>
                      <SearchOutlined /> ค้นหาผู้ประกอบการ
                    </Section.Title>

                    <Form
                      layout='vertical'
                      form={formEMPSearch}
                      onFinish={onFinishEMPSearch}
                    >
                      <Row gutter={16} justify='space-between'>
                        <Col span={24}>
                          <Form.Item
                            {...empSearchFormSchema.phoneNo}
                            rules={formRuleUtils.PHONE_NO}
                            initialValue='0864405047'
                          >
                            <Input
                              placeholder={empSearchFormSchema.phoneNo.label}
                              onKeyDown={handleOnKeyDown}
                            />
                          </Form.Item>

                          <Form.Item>
                            <Button
                              block
                              htmlType='submit'
                              loading={loadingEMPSearch}
                            >
                              ค้นหา
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </PagePanel>
                </Section.Item>
              )}

              {state.empSearchNotFound && (
                <NotFoundEMPCardAndAddDrawer
                  phoneNo={state.empSearchPhoneNo ?? ''}
                  onRegisterEMPSuccess={onRegisterEMPSuccess}
                />
              )}
            </Section.Container>

            {state.selectedProduct !== undefined && (
              <ButtonSheet
                actionState={state}
                loading={loading}
                handleOnBackFromSummary={handleOnBackFromSummary}
                handleOnSubmit={handleOnSubmit}
                handleOnSummary={handleOnSummary}
              />
            )}
          </>
        )}
      </div>
    </Drawer>
  );
}

const Section = {
  Container: styled.div`
    margin-bottom: 90px;
  `,
  Item: styled.div``,
  Title: styled(Space)<{ blink?: boolean }>`
    color: var(--primary-color);
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;

    ${(props) =>
      props.blink &&
      css`
        animation: blinker 1s linear infinite;

        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      `}
  `,
};

const FormCard = {
  Wrapper: styled.div`
    background-color: white;
    border-radius: 24px;
    padding: 12px;
  `,
};

const FormItemBlockStyleLable = styled(Form.Item)`
  .ant-form-item-label > label {
    display: block;
  }

  .ant-form-item-label > label > div > div > span {
    color: var(--primary-color);
    text-decoration: underline;
    /* :hover */
  }
`;
