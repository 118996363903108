import { TemplateAgreement } from '@services/domains/templateAgreement';
import { scrollToRef } from '@utils/browserUtils';
import { createMarkup } from '@utils/dataUtils';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';

type Props = {
  data: TemplateAgreement;
  onEndOfContent: (id: string) => void;
};
export default function DataView(props: Props) {
  const { data, onEndOfContent } = props;
  const stringId = data.id.toString();

  const ref = useRef<HTMLDivElement>(null);

  const [hideBtn, setHideBtn] = useState(false);

  function scrollToBottom() {
    scrollToRef(ref);
  }

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <DataContainer key={stringId}>
        <div dangerouslySetInnerHTML={createMarkup(data.detail ?? '</>')} />

        <CenterItem>
          <Button hidden={hideBtn} onClick={scrollToBottom}>
            เลื่อนลง
          </Button>
        </CenterItem>

        <InView
          key={stringId}
          onChange={(inView) => {
            setHideBtn(inView);
            inView && onEndOfContent(stringId);
          }}
        >
          <div ref={ref} id={stringId}></div>
        </InView>
      </DataContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Title = styled.h1`
  font-size: 24px;
`;

const DataContainer = styled.div`
  height: calc(
    100vh - 64px - 200px
  ); // 64px height of header, 136px height of ButtonGroup
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

const CenterItem = styled.div`
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 120px;
  left: 50%;
  right: 50%;
`;
