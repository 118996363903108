import { ROUTE } from '@constants/routes';
import { jobOfferApi } from '@services/apis';
import { failedToFetchAPI } from '@utils/apiUtils';
import { getHostname } from '@utils/helpsUtils';
import { generatePath } from 'react-router-dom';

import { notifySuccess } from './notifyUtils';

export function copyLinkToClipboard(link: string, title?: string) {
  title = title ?? 'คัดลอกลิ้งค์สำเร็จ';
  navigator.clipboard.writeText(link);
  notifySuccess(title, link);
}

export function genINFRegisterLink(jobId: number) {
  copyLinkToClipboard(
    `${getHostname()}${generatePath(`${ROUTE.JOB_INFLUENCER_REGISTER}`, {
      id: jobId,
    })}`
  );
}

export function genINFUploadLink(jobId: number) {
  copyLinkToClipboard(
    `${getHostname()}${generatePath(`${ROUTE.JOB_INFLUENCER_UPLOAD}`, {
      id: jobId,
    })}`
  );
}

export async function genEMPLink(jobId: number) {
  try {
    const res = await jobOfferApi.getAccessLinkById(jobId);
    const token = res.data.token;
    const link = `${getHostname()}${generatePath(
      ROUTE.JOB_EMPLOYER_VERIFY_TOKEN,
      {
        id: jobId,
      }
    )}?token=${token}`;
    copyLinkToClipboard(link);
  } catch (error) {
    failedToFetchAPI(error);
  }
}

export function genAnnouncementLink(jobId: number) {
  const link = `${getHostname()}${generatePath(ROUTE.BOARD_ANNOUNCEMENT, {
    id: jobId,
  })}`;

  copyLinkToClipboard(link);
}
