import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

type Props = {
  children?: ReactNode;
  style?: CSSProperties;
  title?: ReactNode;
  titleStyle?: CSSProperties;
};
export default function CardContent(props: Props) {
  const { children, style, title, titleStyle = { fontSize: '1.2rem' } } = props;

  return (
    <Content.Wrapper>
      {title && <Content.Title style={titleStyle}>{title}</Content.Title>}
      <Content.Body style={style}>{children}</Content.Body>
    </Content.Wrapper>
  );
}

const Content = {
  Wrapper: styled.div`
    padding: 16px;
    background-color: white;
    border-radius: 24px;
  `,
  Title: styled.div`
    margin-bottom: 16px;
  `,
  Body: styled.div``,
};
