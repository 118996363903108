import * as authUtils from '@utils/authUtils';
import { notifyFailed } from '@utils/notifyUtils';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class ApiClient {
  static getAxioInstance() {
    if (!this.axiosInstance) {
      this.axiosInstance = axios.create();
      registerInterceptors(this.axiosInstance);
    }
    return this.axiosInstance;
  }

  static getAxioExternalInstance() {
    if (!this.axiosExternalInstance) {
      this.axiosExternalInstance = axios.create();
    }
    return this.axiosExternalInstance;
  }
  private static axiosInstance?: AxiosInstance;
  private static axiosExternalInstance?: AxiosInstance;
}

const api = ApiClient.getAxioInstance();
const apiExternal = ApiClient.getAxioExternalInstance();

function registerInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (config) => {
      authInterceptor(config);
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      switch (error?.response?.status) {
        // case 400:
        //   notifyFailed(
        //     'ไม่สามารถดำเนินการต่อได้',
        //     error?.response?.data?.error
        //   );
        //   break;
        case 401:
          notifyFailed('Authorized Failed', error?.response?.data?.error);
          window.location.href = '/login';
          return Promise.reject(error);
        case 403:
          notifyFailed('Permission Denied', error?.response?.data?.error);
          return Promise.reject(error);
        // case 404: //TODO remove > use in page
        //   notifyFailed('ไม่พบข้อมูล', error?.response?.data?.error);
        //   return Promise.reject(error);
        case 500:
          notifyFailed('เกิดข้อผิดพลาด', error?.response?.data?.error);
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    }
  );
}

function authInterceptor(config: AxiosRequestConfig) {
  //   const xApiKey = "X-API-KEY";
  // config.headers.common[xApiKey] = configUrl.apiKey;
  if (authUtils.isLogedIn()) {
    const accessToken = authUtils.getUserToken()?.accessToken;
    const key = 'Authorization';
    config.headers[key] = `Bearer ${accessToken}`;
  }
}

export { api, apiExternal };
