export function performClickById(id: string) {
  const element: HTMLElement = document.getElementById(id) as HTMLElement;
  element.click();
}

export function randomString() {
  return Math.random().toString(36).slice(-8);
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export function delay(
  callback: (...args: unknown[]) => void,
  ms: number,
  ...args: unknown[]
) {
  setTimeout(callback, ms, ...args);
}

export function getHostname() {
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  const full = `${protocol}//${domain}${port ? `:${port}` : ''}`;
  // console.log({
  //   REACT_APP_ENV: process.env.REACT_APP_ENV,
  //   full,
  //   location: window.location,
  //   origin: window.location.origin,
  // });
  return full;
}
// export function getSearchAsObject(searchParams: URLSearchParams) {
//   let obj: any = {};
//   for (const key of searchParams.keys()) {
//     const arrValues = searchParams.getAll(key);
//     const value = searchParams.get(key);
//     const isArray = arrValues.length > 1;

//     if (isArray) {
//       obj = { ...obj, [key]: arrValues };
//     } else {
//       obj = { ...obj, [key]: value };
//     }
//   }

//   // return Object.fromEntries(new URLSearchParams(searchParams));
//   return obj;
// }

// export function inputNumberValidator(
//   min: number,
//   max: number,
//   rule: any,
//   value: any
// ) {
//   if (value) {
//     const current = +value;

//     if (current < min || current > max) {
//       return Promise.reject('จำนวนไม่ถูกต้อง!');
//     }
//   }
//   return Promise.resolve();
// }

// export function tryParseJSONObject(jsonString: any) {
//   try {
//     const o = JSON.parse(jsonString);
//     if (o && typeof o === 'object') {
//       return o;
//     }
//   } catch (error) {}

//   return jsonString;
// }
