import { ExclamationCircleOutlined } from '@ant-design/icons';
import CardContent from '@components/card/CardContent';
import LayoutPublic from '@components/layout/LayoutPublic';
import Loading from '@components/loading/Loading';
import { ROUTE } from '@constants/routes';
import ButtonGroup from '@pages/influencer/register/agreement/components/ButtonGroup';
import DataView from '@pages/influencer/register/agreement/components/DataView';
import { JobInfluencerRegisterConfirmPageLocationState } from '@pages/job/[id]/influencer/register/confirm';
import { templateAgreementAPI, userAgreementAPI } from '@services/apis';
import { TemplateAgreement } from '@services/domains/templateAgreement';
import { UserAgreementAccept } from '@services/models/userAgreement';
import { failedToFetchAPI } from '@utils/apiUtils';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

export interface InfluencerRegisterAgreementPageLocationState {
  userId: number;
  jobId?: number;
}

type AcceptResult = { id: number; isAccept: boolean };

interface State {
  data?: TemplateAgreement[];
  currentIndex: number;
  enabledButtonIdList: string[];
  acceptResultList: AcceptResult[];
  loadingData: boolean;
  loadingButton: boolean;
}

export default function InfluencerRegisterAgreementPage() {
  const location = useLocation();
  const locationState =
    location.state as InfluencerRegisterAgreementPageLocationState;

  const history = useHistory();

  const [state, setState] = useState<State>({
    currentIndex: 0,
    enabledButtonIdList: [],
    acceptResultList: [],
    loadingData: false,
    loadingButton: false,
  });

  const showLoadingData = () =>
    setState((prev) => ({ ...prev, loadingData: true }));
  const hideLoadingData = () =>
    setState((prev) => ({ ...prev, loadingData: false }));
  const showLoadingButton = () =>
    setState((prev) => ({ ...prev, loadingButton: true }));
  const hideLoadingButton = () =>
    setState((prev) => ({ ...prev, loadingButton: false }));

  const handleEndOfContent = (id: string) => {
    setState((prev) => ({
      ...prev,
      enabledButtonIdList: [...prev.enabledButtonIdList, id],
    }));
  };

  function handleNexPage() {
    if (locationState.jobId) {
      const targetState: JobInfluencerRegisterConfirmPageLocationState = {
        userId: locationState.userId,
      };
      history.push({
        state: targetState,
        pathname: generatePath(ROUTE.JOB_INFLUENCER_REGISTER_CONFIRM, {
          id: locationState.jobId,
        }),
      });
    } else {
      history.push({
        ...location,
        pathname: ROUTE.INFLUENCER_REGISTER_SUCCESS,
      });
    }
  }

  const onAction = (id: number, isAccept: boolean) => {
    if (isAccept) {
      const resultList: AcceptResult[] = [
        ...state.acceptResultList,
        { id: id, isAccept: isAccept },
      ];
      setState((prev) => ({
        ...prev,
        acceptResultList: resultList,
      }));

      const newIndex = state.currentIndex + 1;
      if (state.data && newIndex > state.data.length - 1) {
        fetchAccept({ userId: locationState.userId, resultList: resultList });
      } else {
        setState((prev) => ({ ...prev, currentIndex: newIndex }));
      }
    } else {
      Modal.confirm({
        title: 'ไม่ต้องการสมัครสมาชิก ?',
        icon: <ExclamationCircleOutlined />,
        okText: 'ตกลง',
        cancelText: 'ยกเลิก',
        onOk: history.goBack,
      });
    }
  };

  async function fetchAccept(req: UserAgreementAccept.Request) {
    try {
      showLoadingButton();
      const res = await userAgreementAPI.accept(req);
      if (res.success) {
        handleNexPage();
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoadingButton();
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        showLoadingData();
        const res = await templateAgreementAPI.getByUserId(
          locationState.userId
        );
        if (res.data && res.data.length > 0) {
          setState((prev) => ({ ...prev, data: res.data }));
        } else {
          handleNexPage();
        }
      } catch (error) {
        failedToFetchAPI(error);
      } finally {
        hideLoadingData();
      }
    }

    fetchData();
  }, []);

  return (
    <LayoutPublic hideLogin>
      <Row justify='center'>
        <Col xs={24} md={18} lg={14} xl={12}>
          {state.loadingData && (
            <CardContent>
              <Loading loading={true} />
            </CardContent>
          )}

          {state.data && state.data.length && (
            <CardContent>
              <DataView
                data={state.data[state.currentIndex]}
                onEndOfContent={handleEndOfContent}
              />
              <ButtonGroup
                loading={state.loadingButton}
                dataId={state.data[state.currentIndex].id.toString()}
                onAction={onAction}
              />
            </CardContent>
          )}
        </Col>
      </Row>
    </LayoutPublic>
  );
}
