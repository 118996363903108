import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  JobOfferAnnouncement,
  JobOfferBoAllUploaPass,
  JobOfferChangeCanApply,
  JobOfferComplete,
  JobOfferCreate,
  JobOfferGetAccessLink,
  JobOfferGetDetailById,
  JobOfferIsApplicationExist,
  JobOfferUpdateTrackingNo,
  JobOfferVerifyAccessLink,
} from '@services/models/jobOffer';
import {
  JobOfferBoSelectCandidate as JobOfferSelectCandidate,
  JobOfferDetail,
  JobOfferList,
  JobOfferSelectReviewer,
  JobOfferUpdate,
} from '@services/models/jobOffer';
import { generateQueryString } from '@utils/apiUtils';
import { generatePath } from 'react-router-dom';

export async function create(req: Partial<JobOfferCreate.Request>) {
  const res = await api.post<JobOfferCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_CREATE}`,
    req
  );
  return res.data;
}

export async function update(req: Partial<JobOfferUpdate.Request>) {
  const res = await api.put<JobOfferUpdate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_UPDATE}`,
    req
  );
  return res.data;
}

export async function list(req: Partial<JobOfferList.Request>) {
  const res = await api.post<JobOfferList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_LIST}`,
    req
  );
  return res.data;
}

export async function listSummary(req: Partial<JobOfferList.Request>) {
  const res = await api.post<JobOfferList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_LIST_SUMMARY}`,
    req
  );
  return res.data;
}

//TODO remove
export async function detail(id: number) {
  const res = await api.get<JobOfferDetail.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_OFFERS_DEATAIL_BY_ID, {
      id,
    })}`
  );
  return res.data;
}

export async function selectCandidate(req: JobOfferSelectCandidate.Request) {
  const res = await api.post<JobOfferSelectCandidate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_SELECT_CANDIDATE}`,
    req
  );
  return res.data;
}

export async function selectReviewer(req: JobOfferSelectReviewer.Request) {
  const res = await api.post<JobOfferSelectReviewer.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_SELECT_REVIEWER}`,
    req
  );
  return res.data;
}

export async function updateTrackingNo(req: JobOfferUpdateTrackingNo.Request) {
  const res = await api.post<JobOfferSelectReviewer.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_UPDATE_TRACKING_NO}`,
    req
  );
  return res.data;
}

export async function boAllUploadPass(req: JobOfferBoAllUploaPass.Request) {
  const res = await api.post<JobOfferBoAllUploaPass.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_BO_ALL_UPLOAD_PASS}`,
    req
  );
  return res.data;
}

export async function complete(req: JobOfferComplete.Request) {
  const res = await api.post<JobOfferComplete.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_COMPLETE}`,
    req
  );
  return res.data;
}

export async function announcement(id: number) {
  const res = await api.get<JobOfferAnnouncement.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_OFFERS_ANNOUNCEMENT_OF_REVIEWER_BY_ID,
      { id }
    )}`
  );
  return res.data;
}

export async function getAccessLinkById(id: number) {
  const res = await api.get<JobOfferGetAccessLink.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_OFFERS_GET_ACCESS_LINK_BY_ID,
      { id }
    )}`
  );
  return res.data;
}

export async function verifyAccessLink(req: JobOfferVerifyAccessLink.Request) {
  const res = await api.post<JobOfferVerifyAccessLink.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_VERIFY_ACCESS_LINK}`,
    req
  );
  return res.data;
}

export async function changeCanApply(req: JobOfferChangeCanApply.Request) {
  const res = await api.post<JobOfferChangeCanApply.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_CHANGE_CAN_APPLY}`,
    req
  );
  return res.data;
}

export async function isApplicationExist(
  req: JobOfferIsApplicationExist.Request
) {
  const query = generateQueryString(req);
  const res = await api.get<JobOfferIsApplicationExist.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_OFFERS_IS_APPLICATION_EXIST}?${query}`
  );
  return res.data;
}

export async function getDetailById(id: number) {
  const res = await api.get<JobOfferGetDetailById.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_OFFERS_GET_DEATAIL_BY_ID, {
      id,
    })}`
  );
  return res.data;
}
