import { message, notification } from 'antd';

export function notifyAvailableSoon() {
  return message.success(`This feature will be available soon!.`);
}

export function notifySuccess(
  message: React.ReactNode,
  description?: React.ReactNode
) {
  const key = `open${Date.now()}`;

  notification['success']({
    key: key,
    message: message,
    description: description,
  });
}

export function notifyFailed(message?: string, description?: string) {
  message ??= 'เกิดข้อผิดพลาด';
  description ??= 'ติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ';

  const key = `open${Date.now()}`;
  notification['error']({
    key: key,
    message: message,
    description: description,
  });
}

export function notifyInfo(message: string, description?: string) {
  message ??= 'ข้อความ';
  description ??= '';

  const key = `open${Date.now()}`;
  notification['info']({
    key: key,
    message: message,
    description: description,
  });
}
