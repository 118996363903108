import { APIModeType } from '@components/section/SectionComment';
import { DEFAULT_PAGE_SIZE } from '@constants/constants';
import DataView from '@pages/job/[id]/employer/select-reviewer/components/DataView';
import { jobApplicationApi } from '@services/apis';
import { JobApplicationGet } from '@services/models/jobApplication';
import { failedToFetchAPI } from '@utils/apiUtils';
import React, { useEffect, useState } from 'react';

interface State {
  loading: boolean;
  resp?: JobApplicationGet.Response;
}

type Props = {
  apiMode: APIModeType;
  jobId?: number;
  selectedIdList: number[];
  selectable?: boolean;
  searchCandidate?: boolean;
  searchReviewer?: boolean;
  adminLayout: boolean;
  onSelect: (id: number) => void;
  onDeselect: (id: number) => void;
};
export default function TabSelectApplication(props: Props) {
  const {
    apiMode,
    jobId,
    selectedIdList,
    selectable = true,
    adminLayout,
    // searchCandidate,
    // searchReviewer,
    onSelect,
    onDeselect,
  } = props;

  const [state, setState] = useState<State>({
    loading: false,
  });

  const showLoading = () => setState((prev) => ({ ...prev, loading: true }));
  const hideLoading = () => setState((prev) => ({ ...prev, loading: false }));

  function onLoadMore() {
    fetchList(
      {
        pageNumber:
          state.resp?.page.pageNumber !== undefined
            ? state.resp?.page.pageNumber + 1
            : 1,
        pageSize: state.resp?.page.pageSize ?? DEFAULT_PAGE_SIZE,
      },
      true
    );
  }

  async function fetchList(
    req: JobApplicationGet.Request,
    loadMoreMode?: boolean
  ) {
    try {
      showLoading();

      let resp: JobApplicationGet.Response;
      if (apiMode === 'admin') {
        resp = await jobApplicationApi.get({
          ...req,
          jobId,
        });
      } else if (apiMode === 'employer' && jobId) {
        resp = await jobApplicationApi.employerGet(jobId, {
          ...req,
        });
      }

      if (loadMoreMode) {
        setState((prev) => {
          resp.data = [...(prev.resp?.data ?? []), ...resp.data];
          return { ...prev, resp: resp };
        });
      } else {
        setState((prev) => ({ ...prev, resp: resp }));
      }
    } catch (error) {
      failedToFetchAPI(error);
    } finally {
      hideLoading();
    }
  }

  useEffect(() => {
    fetchList({ pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE }, false);
  }, []);

  return (
    <DataView
      loading={state.loading}
      datasource={state.resp?.data}
      pagination={state.resp?.page}
      onLoadMore={onLoadMore}
      selectedIdList={selectedIdList}
      selectable={selectable}
      onSelect={onSelect}
      onDeselect={onDeselect}
      adminLayout={adminLayout}
    />
  );
}
