import { api } from '@core/api/api-client';
import * as ENDPOINT from '@services/endpoint';
import {
  JobApplicationApproveUpload,
  JobApplicationDelete,
  JobApplicationEmployerGetTabs,
  JobApplicationEmployerGetTabsSelectCandidate,
  JobApplicationEmployerSelectReviewers,
  JobApplicationEmployerSubmit,
  JobApplicationGeCandidates,
  JobApplicationGet,
  JobApplicationGetComments,
  JobApplicationGetInfo,
  JobApplicationGetTabByJobId,
  JobApplicationIsReviewer,
  JobApplicationSelectCandidates,
} from '@services/models/jobApplication';
import {
  JobApplicationBatchUpdate,
  JobApplicationCreate,
  JobApplicationDetail,
  JobApplicationFindByPhoneNo,
  JobApplicationList,
  JobApplicationQcReviewFail,
  JobApplicationQcReviewPass,
  JobApplicationReviewFail,
  JobApplicationReviewPass,
  JobApplicationUpdateLink,
  JobApplicationUploadReview,
} from '@services/models/jobApplication';
import {
  generateQueryString,
  getEmployerTokenHeader,
  getReviewerTokenHeader,
} from '@utils/apiUtils';
import { generatePath } from 'react-router-dom';

export async function create(req: JobApplicationCreate.Request) {
  const res = await api.post<JobApplicationCreate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_CREATE}`,
    req
  );
  return res.data;
}

export async function delApplication(req: JobApplicationDelete.Request) {
  const res = await api.delete<JobApplicationDelete.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_DELETE}`,
    {
      data: {
        jobApplicationSerial: req.jobApplicationSerial,
      },
    }
  );
  return res.data;
}

export async function list(req: Partial<JobApplicationList.Request>) {
  const res = await api.post<JobApplicationList.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_LIST_SUMMARY}`,
    req
  );
  return res.data;
}

export async function detail(id: number) {
  const res = await api.get<JobApplicationDetail.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_APPLICATION_DETAIL_BY_ID, {
      id,
    })}`
  );
  return res.data;
}

export async function batchUpdate(
  req: Partial<JobApplicationBatchUpdate.Request>
) {
  const res = await api.post<JobApplicationBatchUpdate.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_BATCH_UPDATE}`,
    req
  );
  return res.data;
}

export async function findByPhoneNo(req: JobApplicationFindByPhoneNo.Request) {
  const res = await api.post<JobApplicationFindByPhoneNo.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_FIND_BY_PHONE_NO}`,
    req
  );
  return res.data;
}

export async function uploadRevivew(
  req: Partial<JobApplicationUploadReview.Request>
) {
  const res = await api.post<JobApplicationUploadReview.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_UPLOAD_REVIEW}`,
    req
  );
  return res.data;
}

export async function qcReviewPass(req: JobApplicationQcReviewPass.Request) {
  const res = await api.post<JobApplicationQcReviewPass.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_QC_REVIEW_PASS}`,
    req
  );
  return res.data;
}

export async function qcReviewFail(req: JobApplicationQcReviewFail.Request) {
  const res = await api.post<JobApplicationQcReviewFail.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_BASE_URL}`,
    req
  );
  return res.data;
}

export async function reviewPass(req: JobApplicationReviewPass.Request) {
  const res = await api.post<JobApplicationReviewPass.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_REVIEW_PASS}`,
    req
  );
  return res.data;
}

export async function reviewFail(req: JobApplicationReviewFail.Request) {
  const res = await api.post<JobApplicationReviewFail.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_REVIEW_FAIL}`,
    req
  );
  return res.data;
}

export async function updateLink(req: JobApplicationUpdateLink.Request) {
  const res = await api.post<JobApplicationUpdateLink.Response>(
    `${ENDPOINT.BASE_URL}${ENDPOINT.JOB_APPLICATION_UPLOAD_LINK}`,
    req
  );
  return res.data;
}
// export async function update(req: Partial<JobApplicationUpdate.Request>) {
//     const response = await axios.put<JobApplicationUpdate.Response>(
//         `${ENDPOINT.JOB_APPLICATIONS_BASE_URL}/update`,
//         req
//     )
//     return response.data
// }

export async function employerGetTabsSelectCandidate(jobId: number) {
  const config = getEmployerTokenHeader();
  const res =
    await api.get<JobApplicationEmployerGetTabsSelectCandidate.Response>(
      `${ENDPOINT.BASE_URL}${generatePath(
        ENDPOINT.JOB_APPLICATION_EMPLOYER_GET_TABS_SELECT_CANDIDATE,
        {
          jobId,
        }
      )}`,
      config
    );
  return res.data;
}

export async function employerGetTabsReviewUpload(jobId: number) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobApplicationEmployerGetTabs.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_EMPLOYER_GET_TABS_REVIER_UPLOAD,
      {
        jobId,
      }
    )}`,
    config
  );
  return res.data;
}

export async function employerGetCandidates(
  jobId: number,
  req: JobApplicationGet.Request
) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobApplicationGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_APPLICATION_EMPLOYER_GET, {
      jobId,
    })}?${generateQueryString(req)}`,
    config
  );
  return res.data;
}

export async function employerGet(
  jobId: number,
  req: JobApplicationGet.Request
) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobApplicationGet.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_APPLICATION_EMPLOYER_GET, {
      jobId,
    })}?${generateQueryString(req)}`,
    config
  );
  return res.data;
}

export async function employerSelectReviewers(
  jobId: number,
  req: JobApplicationEmployerSelectReviewers.Request
) {
  const config = getEmployerTokenHeader();
  const res = await api.post<JobApplicationEmployerSelectReviewers.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_EMPLOYER_GET_SELECT_REVIEWERS,
      {
        jobId,
      }
    )}`,
    req,
    config
  );
  return res.data;
}

export async function employerSubmit(
  jobId: number,
  req: JobApplicationEmployerSubmit.Request
) {
  const config = getEmployerTokenHeader();
  const res = await api.post<JobApplicationEmployerSubmit.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_EMPLOYER_SUBMIT,
      {
        jobId,
      }
    )}`,
    req,
    config
  );
  return res.data;
}

export async function get(req: JobApplicationGet.Request) {
  const res = await api.get<JobApplicationGet.Response>(
    `${ENDPOINT.BASE_URL}${
      ENDPOINT.JOB_APPLICATION_BASE_URL
    }?${generateQueryString(req)}`
  );
  return res.data;
}

export async function GetTabsByJobID(jobId: number) {
  const res = await api.get<JobApplicationGetTabByJobId.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_GET_TABS_BY_JOB_ID,
      {
        jobId,
      }
    )}`
  );
  return res.data;
}

export async function selectCandidates(
  jobId: number,
  req: JobApplicationSelectCandidates.Request
) {
  const res = await api.post<JobApplicationSelectCandidates.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_SELECT_CANDIDATES,
      {
        jobId,
      }
    )}`,
    req
  );
  return res.data;
}

export async function selectAllToBeCandidate(jobId: number) {
  const res = await api.post<JobApplicationSelectCandidates.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_SELECT_ALL_TO_BE_CANDIDATE,
      {
        jobId,
      }
    )}`
  );
  return res.data;
}

export async function getCandidates(jobId: number) {
  const res = await api.get<JobApplicationGeCandidates.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_GET_CANDIDATES,
      {
        jobId,
      }
    )}`
  );
  return res.data;
}

export async function isReviewer(
  jobId: number,
  req: JobApplicationIsReviewer.Request
) {
  const res = await api.get<JobApplicationIsReviewer.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_APPLICATION_IS_REVIEWER, {
      jobId,
    })}?${generateQueryString(req)}`
  );
  return res.data;
}

export async function reviewerGetComments(jobId: number) {
  const config = getReviewerTokenHeader();
  const res = await api.get<JobApplicationGetComments.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_REVIEWER_GET_COMMENTS,
      {
        jobId,
      }
    )}`,
    config
  );
  return res.data;
}

export async function reviewerGetInfo(jobId: number) {
  const config = getReviewerTokenHeader();
  const res = await api.get<JobApplicationGetInfo.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_REVIEWER_GET_INFO,
      {
        jobId,
      }
    )}`,
    config
  );
  return res.data;
}

export async function employerGetComments(jobId: number, id: number) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobApplicationGetComments.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_EMPLOYER_GET_COMMENTS,
      {
        jobId,
        id,
      }
    )}`,
    config
  );
  return res.data;
}
export async function getComments(jobId: number, id: number) {
  const config = getEmployerTokenHeader();
  const res = await api.get<JobApplicationGetComments.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(ENDPOINT.JOB_APPLICATION_GET_COMMENTS, {
      jobId,
      id,
    })}`,
    config
  );
  return res.data;
}

export async function approveUpload(
  jobId: number,
  req: JobApplicationApproveUpload.Request
) {
  const res = await api.post<JobApplicationApproveUpload.Response>(
    `${ENDPOINT.BASE_URL}${generatePath(
      ENDPOINT.JOB_APPLICATION_APPROVE_UPLOAD,
      {
        jobId,
      }
    )}`,
    req
  );
  return res.data;
}
